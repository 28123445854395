import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  isHidden: boolean;
}

export default function VendorTabPanel({ children, isHidden }: TabPanelProps) {
  useEffect(() => {}, []);

  return (
    <Box
      role="tabpanel"
      hidden={isHidden}
      sx={{
        maxHeight: 465,
        overflowY: 'auto',
        scrollbarColor: '#d9e2ec #fff',
        // '::-webkit-scrollbar ': {
        //   width: '10px',
        //   background: '#fff',
        // },
        // '::-webkit-scrollbar-thumb': {
        //   background: '#d9e2ec',
        //   '-webkit-border-radius': '1ex',
        //   '-webkit-box-shadow': '0px 1px 2px #d9e2ec',
        // },
      }}
    >
      {!isHidden && <Box>{children}</Box>}
    </Box>
  );
}
