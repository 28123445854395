import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import VendorTabPanel from './VendorTabPanel';
import api from 'src/api';
import { SessionContext } from 'src/context/SessionContext';
import VendoAccordion from '../Accordion';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  isHidden: boolean;
  vendorData: any;
  setVendorData: (data: any) => void;
  isLoading?: boolean;
  setIsLoading: (loading: boolean) => void;
  files: Array<any>;
  bgCheck: any;
  setBgStatus: (status: string) => void;
  handleUploadOpen: (isOpen: boolean) => void;
  refreshData: () => void;
};

export default function BusinessDocTab({
  isHidden,
  vendorData,
  setVendorData,
  isLoading,
  setIsLoading,
  files,
  bgCheck,
  setBgStatus,
  handleUploadOpen,
  refreshData,
}: Props) {
  const { session } = useContext(SessionContext);
  const isAdmin = session.role === 'IDCORE_ADMIN';

  const [tinCheckStatus, setTinCheckStatus] = useState<string | null>(vendorData.tinCheck ?? 'N/A');
  const tinCheckUpdateHandler = (value: string) => {
    api.vendors
      .updateTinCheckStatus(vendorData._id, value, session.details.name)
      .then(value1 => {
        setTinCheckStatus(value);
        refreshData();
      })
      .catch(reason => console.error('Tin check status update not successful', reason));
  };

  useEffect(() => {
    if (!isHidden) {
    }
  }, [isHidden]);

  return (
    <VendorTabPanel isHidden={isHidden}>
      <Box>
        <VendoAccordion
          title="W-9"
          status={vendorData.w9 ? 'ON-FILE' : 'NON-COMPLIANT'}
          id={vendorData._id}
          fileUrls={files.filter(x => x.type == 'w9')}
          isDelete={false}
        ></VendoAccordion>
        <VendoAccordion
          title="MSA"
          id={vendorData._id}
          status={vendorData.msaResprop ? 'ON-FILE' : 'N/A'}
          isEven
          fileUrls={[
            {
              id: vendorData._id,
              name: 'msa.pdf',
              lastModified: vendorData.msaResprop,
            },
          ]}
          isDelete={false}
        ></VendoAccordion>
        <VendoAccordion
          title="TIN Check"
          status={tinCheckStatus ? tinCheckStatus : ''}
          chipItems={['COMPLIANT', 'NON-COMPLIANT', 'PENDING']}
          dataOnChange={(type, value) => tinCheckUpdateHandler(value)}
          isDelete={false}
        ></VendoAccordion>
        <VendoAccordion
          title="Background Check"
          status={bgCheck?.status || 'PENDING'}
          chipItems={['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'MISSING']}
          dataOnChange={(type, value) => setBgStatus(value)}
          isEven
          isDelete={false}
        ></VendoAccordion>
      </Box>
      <Box
        sx={{
          borderTop: '1px solid #BCCCDC',
          p: 2,
          color: '#035388',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer', maxWidth: 150 }}
          onClick={() => handleUploadOpen(false)}
        >
          <AddIcon></AddIcon>
          <Typography color={'#035388'} fontWeight={700}>
            Upload W9 File
          </Typography>
        </Box>
      </Box>
    </VendorTabPanel>
  );
}
