import { useState, useEffect, useContext } from 'react';
import { Typography, Link, Box, Grid, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from 'src/context/SessionContext';
import { ReactComponent as GoogleLogo } from '../../svg/google-logo.svg';
import CircularProgress from '@mui/material/CircularProgress';
import MainLayout from 'src/layouts/MainLayout';
import coreApi from '../../api';

interface ISession {
  userId: string;
  role: string;
  details: any;
}

export interface ILoginProps {
  session: ISession;
}

const Login = ({ session }: ILoginProps) => {
  const [error, setError] = useState<string | null>(null);
  const [socialLoginUrl, setSocialLoginUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  const { refreshSession } = useContext(SessionContext);
  const [submitSocial, setSubmitSocial] = useState(false);

  const getSocialLoginUrl = () => {
    const redirectUri = window.location.toString();
    coreApi.auth
      .login(redirectUri)
      .then(res => {
        if (res.status === 200) {
          setSocialLoginUrl(res.data);
        } else {
          setError('Unable to login with Google');
        }
      })
      .catch(err => {
        if (err.response) {
          setError(err.response.data || 'Something went wrong.');
        } else {
          setError('Something went wrong.');
        }
      });
  };

  useEffect(() => {
    if (window.location.hash) {
      let location = window.location.toString();
      location = location.replace('#', '?');
      let accessToken = new URL(location).searchParams.get('access_token');
      if (accessToken) {
        setSubmitSocial(true);
        coreApi.auth
          .handleCallback(accessToken)
          .then(res => {
            refreshSession();
            const { userId } = res.data;
            if (userId) {
              navigate('/');
            }
          })
          .catch(err => {
            if (err.response) {
              setError(err.response.data || 'Something went wrong.');
            } else {
              setError('Something went wrong.');
            }
            setSubmitSocial(false);
          });
      } else getSocialLoginUrl();
    } else {
      getSocialLoginUrl();
    }
  }, []);

  useEffect(() => {
    if (session.userId) {
      navigate(`/`);
    }
  }, [session]);

  return (
    <MainLayout>
      <Grid container spacing={2} sx={{ maxWidth: 400, margin: '0 auto' }}>
        <Grid item xs={12}>
          <Typography variant="h1" fontSize={30} fontWeight={400} pb={3}>
            Lets Get Started
          </Typography>
        </Grid>

        <Grid item xs={12} textAlign={'center'}>
          <Link
            href={socialLoginUrl ? socialLoginUrl : '/'}
            sx={{ textDecoration: 'auto' }}
            onClick={() => setSubmitSocial(true)}
          >
            {submitSocial === false ? (
              <Box
                sx={{
                  width: '312px',
                  height: '48px',
                  borderRadius: '8px',
                  border: '1px solid #829AB1',
                  bgcolor: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <GoogleLogo></GoogleLogo>
                <Typography>Continue with Google</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '312px',
                  height: '48px',
                  borderRadius: '8px',
                  border: '1px solid #829AB1',
                  bgcolor: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <CircularProgress sx={{ color: 'black', height: '25px !important', width: '25px !important' }} />
              </Box>
            )}
          </Link>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          {error && (
            <Box pt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Login;
