import { apiClient } from './client';
import { IVendorQueryParams, Vendor } from '../customTypes/vendor';

const searchVendors = async (params: IVendorQueryParams) => {
  const filters = JSON.stringify(params.filters);
  const sorting = JSON.stringify(params.sort);
  try {
    const queryParams = {
      filters,
      sorting,
      offset: params.paging?.offset,
      limit: params.paging?.limit,
    };
    const res = await apiClient.get('/vendors/filter', { params: queryParams });
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const downloadVendors = async (params: IVendorQueryParams) => {
  const filters = JSON.stringify(params.filters);
  const sorting = JSON.stringify(params.sort);
  try {
    const queryParams = {
      filters,
      sorting,
    };
    const res = await apiClient.get('/vendors/download', { params: queryParams });
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getInsurancePolicies = (userId: string) => {
  return apiClient.get(`/vendors/${userId}/insurance-policies`);
};

const updateTinCheckStatus = (vendorId: string, status: string, changeBy: string) => {
  return apiClient.patch(`/vendors/${vendorId}/tin-check`, { tinCheck: status, changeBy });
};

const getEnrollmentDetails = async (userId: string): Promise<Vendor | null> => {
  try {
    const vendorResponse = await apiClient.get(`/vendors/${userId}`);
    return vendorResponse.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getVendorsCurrentBgCheck = (vendorId: string, userId: string) => {
  // TODO Eventually the vendorId needs to be attached to the BG Check
  // and not the User ID but until then we have to provide the userId as a
  // query string parameter.

  return apiClient
    .get(`/vendors/${vendorId}/bg-checks`, { params: { userId } })
    .then(res => {
      if (res.data.length > 0) {
        return res.data[0];
      }
      return;
    })
    .catch(err => {
      console.error(err);
      return;
    });
};

const addUpdateW9info = async (vendorId: string, w9Info: unknown) => {
  return apiClient.post(`/vendors/${vendorId}/w9`, w9Info);
};

const updateVendorBizInfo = async (vendorId: string, payload: any) => {
  return apiClient.post(`/vendors/${vendorId}/business-info`, payload);
};

const listVendorsUploadedDocs = async (vendorId: string) => {
  try {
    const res = await apiClient.get(`/vendors/${vendorId}/uploaded-docs`);
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getFileUploadUrl = async (vendorId: string, filename: string, uploadBy: string) => {
  try {
    const res = await apiClient.post(`/vendors/${vendorId}/uploaded-docs/upload-url`, { filename, uploadBy });
    return res.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const getW9UploadUrl = async (vendorId: string, filename: string, uploadBy: string) => {
  const res = await apiClient.post(`/vendors/${vendorId}/uploaded-docs/w9-upload-url`, { filename, uploadBy });
  return res.data;
};

const getOtherUploadUrl = async (vendorId: string, filename: string, uploadBy: string) => {
  const res = await apiClient.post(`/vendors/${vendorId}/uploaded-docs/other-upload-url`, { filename, uploadBy });
  return res.data;
};

const getFileUrl = async (vendorId: string, filename: string) => {
  try {
    const res = await apiClient.post(`/vendors/${vendorId}/uploaded-docs/download-url`, { filename });
    return res.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const removeFile = async (vendorId: string, filename: string) => {
  try {
    const res = await apiClient.post(`/vendors/${vendorId}/uploaded-docs/remove-doc`, { filename });
    return res.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const syncIntercom = async (vendorId: string) => {
  try {
    const res = await apiClient.post(`/vendors/${vendorId}/create-intercom`);
    return res.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const triggerBgCheck = async (vendorId: string) => {
  try {
    const res = await apiClient.post(`/vendors/${vendorId}/trigger-bg-check`);
    return res.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const getCardStats = async (date: string): Promise<any> => {
  try {
    const query = date ? `toDate=${date}` : '';
    const vendorResponse = await apiClient.get(`/vendors/stats/card?${query}`);
    return vendorResponse.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getChartStats = async (date: string): Promise<any> => {
  try {
    const query = date ? `toDate=${date}` : '';
    const vendorResponse = await apiClient.get(`/vendors/stats/chart?${query}`);
    return vendorResponse.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const inviteFromHub = async(id: any) => {
  const resp = await apiClient.post('/vendors/invite', {id});
  return resp.data;
}

export default {
  searchVendors,
  getInsurancePolicies,
  getEnrollmentDetails,
  updateTinCheckStatus,
  getVendorsCurrentBgCheck,
  addUpdateW9info,
  updateVendorBizInfo,
  listVendorsUploadedDocs,
  getFileUploadUrl,
  getW9UploadUrl,
  getFileUrl,
  removeFile,
  syncIntercom,
  triggerBgCheck,
  downloadVendors,
  getOtherUploadUrl,
  getCardStats,
  getChartStats,
  inviteFromHub,
};
