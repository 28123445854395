import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import VendorTabPanel from './VendorTabPanel';
import api from 'src/api';
import VendoAccordion from '../Accordion';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';

const LoadingBar = ({}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
      <CircularProgress />
    </Box>
  );
};

type Props = {
  vendorId: string;
  isHidden: boolean;
  isLoading?: boolean;
  handleActivityOpen: () => void;
};

export default function ActivityTab({ vendorId, isHidden, handleActivityOpen }: Props) {
  const [activities, setActivities] = useState<any[]>();

  function getTitleNode(activity: any) {
    var data = JSON.parse(activity.data);
    switch (data.activity) {
      case 'insurance_missing_endorsements':
        return (
          <Box sx={{ minWidth: 100, display: 'flex', justifyContent: 'space-between', alignContent: 'center', pt: 0.5 }}>
            <Typography variant="body2">Update:</Typography>
            <Typography variant="body2" pl={0.5} fontWeight={700}>
              {data.insurance}
            </Typography>
            <Typography variant="body2" pl={0.5}>
              file Is missing necessary endorsements
            </Typography>
          </Box>
        );

      case 'insurance_expired':
        return (
          <Box sx={{ minWidth: 100, display: 'flex', justifyContent: 'space-between', alignContent: 'center', pt: 0.5 }}>
            <Typography variant="body2">Notice:</Typography>
            <Typography variant="body2" pl={0.5} fontWeight={700}>
              {data.insurance}
            </Typography>
            <Typography variant="body2" pl={0.5}>
              insurance policy has
            </Typography>
            <Typography variant="body2" pl={0.5} color={'#E12D39'}>
              expired
            </Typography>
          </Box>
        );
      case 'manual':
        return (
          <Box sx={{ minWidth: 100, display: 'flex', justifyContent: 'space-between', alignContent: 'center', pt: 0.5 }}>
            <Typography variant="body2">{data.type}:</Typography>
            <Typography variant="body2" pl={0.5} fontWeight={700}>
              {data.insurance}
            </Typography>
            <Typography variant="body2" pl={0.5}>
              {activity.message}
            </Typography>
          </Box>
        );
      case 'file_upload':
        return (
          <Box sx={{ minWidth: 100, display: 'flex', justifyContent: 'space-between', alignContent: 'center', pt: 0.5 }}>
            <Typography variant="body2">{data.type}:</Typography>
            <Typography variant="body2" pl={0.5}>
              {activity.message}
            </Typography>
            <Typography variant="body2" pl={0.5} fontWeight={700}>
              {data.insurance}:
            </Typography>
            <Box
              sx={{
                width: '150px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body2"
                pl={0.5}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {data.fileName}
              </Typography>
            </Box>
          </Box>
        );
      default:
        return <Typography sx={{ minWidth: 100 }}>{activity.message}</Typography>;
    }
  }

  function loadData() {
    api.services
      .getActivities(vendorId, '0', '100')
      .then((res: any) => {
        var data = res.results;
        data.sort((a: any, b: any) => {
          return new Date(b.datetime).valueOf() - new Date(a.datetime).valueOf();
        });
        setActivities(data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  const scroller = useRef(null);
  useEffect(() => {
    loadData();
  }, [isHidden]);
  return (
    <VendorTabPanel isHidden={isHidden}>
      <Box
        ref={scroller}
        hidden={isHidden}
        sx={{
          maxHeight: 405,
          overflowY: 'auto',
          scrollbarColor: '#d9e2ec #fff',
        }}
        onScroll={loadData}
      >
        {activities?.map((activity, key) => {
          return (
            <VendoAccordion
              key={key}
              titleNode={getTitleNode(activity)}
              subTitle={DateTime.fromISO(activity.datetime).toFormat('MMM.dd.yyyy')}
              isEven={key % 2 !== 0}
              isDelete={false}
            />
          );
        })}
        {!activities ||
          (activities?.length == 0 && <Box sx={{ display: 'flex', justifyContent: 'center', p: 10 }}>No Activities</Box>)}
      </Box>

      <Box
        sx={{
          borderTop: '1px solid #BCCCDC',
          p: 2,
          color: '#035388',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer', maxWidth: 200 }}
          onClick={() => handleActivityOpen()}
        >
          <AddIcon></AddIcon>
          <Typography color={'#035388'} fontWeight={700}>
            Enter New Activity
          </Typography>
        </Box>
      </Box>
    </VendorTabPanel>
  );
}
