import z from 'zod';

//Vendor schema model, can be also used in React hook-forms
export const vendorSchema = z.object({
  _id: z.string(),
  referenceId: z.string().optional(),
  userId: z.string(),
  overallComplianceStatus: z.string(),
  name: z.string(),
  phone: z.string(),
  address: z.string(),
  email: z.string().email(),
  website: z.string().url(),
  tinCheck: z.string(),
  msa: z.string(),
  backgroundChecks: z.array(
    z.object({
      status: z.string(),
    })
  ),
  w9: z
    .object({
      businessName: z.string(),
      address: z.string(),
      dba: z.string(),
      federalTaxClassification: z.string(),
      tinType: z.string(),
      tin: z.string(),
      certificationAcceptedOn: z.date(),
    })
    .optional(),
  bizInfo: z.object({
    contactName: z.string(),
    email: z.string().email(),
    accountsPayableEmail: z.string().email(),
    phone: z.string(),
    numEmployees: z.string(),
    services: z.array(
      z.object({
        _id: z.string(),
        name: z.string(),
        categoryId: z.string(),
      })
    ),
    riskLevels: z.array(z.string()),
  }),
  payment: z.object({
    status: z.string(),
    paymentDate: z.date(),
  }),
  invited: z.string(),
  enrollmentStatus: z.string(),
});

export const vendorNotesSchema = z.array(
  z.object({
    _id: z.string(),
    displayName: z.string(),
    userId: z.string().optional(),
    dateAdded: z.string(),
    note: z.string(),
    vendorId: z.string(),
    type: z.string().optional()
  })
);
