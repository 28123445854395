import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PageLayout from 'src/layouts/PageLayout';
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tabClasses,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';
import { ReactComponent as ChartIncIcon } from '../../svg/chart-increase.svg';
import { ReactComponent as ChartDecIcon } from '../../svg/chart-decrease.svg';
import LineChart from 'src/components/charts/LineChart';
import PieChart from 'src/components/charts/PieChart';
import api from 'src/api';
import { DateInput, DateTime } from 'luxon';

interface ISession {
  userId: string;
  role: string;
  details: any;
}
type RouteProps = {
  session: ISession;
  sessionIsEmpty: () => Boolean;
};

const ChartTable = styled(TableContainer)(({ theme }) => {
  return {
    [`&.${tableContainerClasses.root}`]: {},
    [`&.${tableContainerClasses.root} .${tableHeadClasses.root} .${tableCellClasses.root}`]: {
      borderBottom: 'none',
      fontSize: '16px',
      color: '#272937BF',
      paddingBottom: 0,
    },
    [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}`]: {
      [`&:last-child .${tableCellClasses.root}`]: {
        borderBottom: 'none',
      },
    },
    [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableCellClasses.root}`]: {
      borderBottom: '1px solid rgba(39, 41, 55, 0.12)',
      fontSize: '18px',
      color: '#272937',
    },
  };
});
const Home = ({ session, sessionIsEmpty }: RouteProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enrollmentStats, setEnrollmentStats] = useState({ value: 0, percentage: 0 });
  const [expiredStats, setExpiredStats] = useState({ value: 0, percentage: 0 });
  const [nonCompStats, setNonCompStats] = useState({ value: 0, percentage: 0 });
  const [avgCompStats, setAvgCompStats] = useState({ value: '', percentage: 0 });
  const [enrolmentChartStats, setEnrolmentChartStats] = useState([{ labels: '', value: 0 }]);
  const [locationChartStats, setLocationChartStats] = useState([{ labels: '', value: 0, percentage: 0, color: '' }]);
  const locationColors = ['#0B69A3', '#1992D4', '#81DEFD', '#E3F8FF'];
  const [topSTypeChartStats, setTopSTypeChartStats] = useState([{ labels: '', value: 0, percentage: 0 }]);
  const [days, setDays] = useState<number>(30);

  const stats = [
    {
      Title: 'Total Enrollments',
      count: enrollmentStats.value,
      percentage: enrollmentStats.percentage,
      // chart: enrollmentStats.percentage > 0 ? <ChartIncIcon /> : <ChartDecIcon />,
    },
    {
      Title: 'Expired Vendors',
      count: expiredStats.value,
      percentage: expiredStats.percentage,
      // chart: enrollmentStats.percentage > 0 ? <ChartIncIcon /> : <ChartDecIcon />,
    },
    {
      Title: 'Non-compliant Vendors',
      count: nonCompStats.value,
      percentage: nonCompStats.percentage,
      // chart: enrollmentStats.percentage > 0 ? <ChartIncIcon /> : <ChartDecIcon />,
    },
    {
      Title: 'Avg. time to compliance',
      count: avgCompStats.value,
      percentage: avgCompStats.percentage,
      chart: <></>,
    },
  ];

  function createData(rank: number, service: string, count: number, percentage: string) {
    return { rank, service, count, percentage };
  }

  const renderTable = (rows: any) => {
    return (
      <ChartTable>
        <Table>
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left">Rank</TableCell>
              <TableCell align="left">Service</TableCell>
              <TableCell align="right">Enrolled</TableCell>
              <TableCell align="right">Percent of Pool</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, idx: number) => (
              <TableRow key={idx} sx={{}}>
                <TableCell align="left">{row.rank}</TableCell>
                <TableCell align="left">{row.service}</TableCell>
                <TableCell align="right">{row.count}</TableCell>
                <TableCell align="right">{row.percentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ChartTable>
    );
  };

  async function getCardData(dateRange: string) {
    setIsLoading(true);
    try {
      const res = await api.vendors.getCardStats(dateRange);
      if (res) {
        setEnrollmentStats(res.enrolled);
        setExpiredStats(res.expired);
        setNonCompStats(res.nonCompliant);
        const diff = DateTime.now().plus({ days: res.avgCompliantDays.value }).diffNow(['days', 'hours', 'minutes', 'seconds']);
        setAvgCompStats({
          value: `${diff.days}d ${diff.hours}h ${diff.minutes < 0 ? 0 : diff.minutes}m`,
          percentage: res.avgCompliantDays.percentage,
        });
      }

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }

  async function getChartData(dateRange: string) {
    setIsLoading(true);
    try {
      const res = await api.vendors.getChartStats(DateTime.now().toJSDate().toISOString());
      if (res) {
        setEnrolmentChartStats(res.enrollment);
        setLocationChartStats(
          res.locations.map((v: any) => {
            return { labels: v.labels, value: v.value, percentage: v.percentage, color: locationColors.reverse().pop() };
          })
        );
        setTopSTypeChartStats(
          res.serviceType.map((v: any, idx: number) => {
            return createData(idx + 1, v.labels, v.value, `${v.percentage}%`);
          })
        );
      }

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const dateRange = days === 0 ? '' : DateTime.now().minus({ days: days }).toJSDate().toISOString();

    getCardData(dateRange);
    getChartData(dateRange);
  }, [days]);

  return (
    <PageLayout pageTitle="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" fontSize={'40px'}>
            Hi {session.details.name}, Welcome back.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, pb: 4, pt: 5 }}>
            <Typography variant="h3" fontSize={'32px'} fontWeight={500}>
              At a Glance Stats
            </Typography>
            <Select
              value={days}
              onChange={e => setDays(e.target.value as number)}
              sx={{
                bgcolor: 'white',
                height: '48px',
              }}
            >
              <MenuItem value={0}>All Time</MenuItem>
              <MenuItem value={30}>Past 30 Days</MenuItem>
              <MenuItem value={60}>Past 60 Days</MenuItem>
              <MenuItem value={90}>Past 90 Days</MenuItem>
            </Select>
          </Box>
          <Grid container spacing={2}>
            {stats.map((s, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={6} lg={3}>
                <Card
                  sx={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '16px',
                    width: '100%',
                    maxHeight: 144,
                    minHeight: 144,
                  }}
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
                >
                  <CardContent sx={{ py: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h6">{s.Title}</Typography>
                      {days !== 0 && <Box>
                        {s.percentage != 0 && s.percentage > 0 && <>+</>}
                        {s.percentage != 0 && <>{s.percentage}%</>}
                      </Box>}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2, pb: 1 }}>
                      <Typography variant="h2" fontWeight={500}>
                        {s.count}
                      </Typography>
                      {s.chart}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" fontSize={'32px'} fontWeight={500} pt={5}>
            Charts and Graphs
          </Typography>
          <Grid container spacing={6} py={4}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card
                sx={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '16px',
                  width: '100%',
                  minHeight: 369,
                  maxHeight: 369,
                }}
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
              >
                <CardContent sx={{ py: 3 }}>
                  <Typography variant="h4" fontWeight={500}>
                    Top Service Types
                  </Typography>
                  <Box
                    sx={{
                      pt: 2,
                      minHeight: '18rem',
                      maxHeight: '18rem',
                    }}
                  >
                    {renderTable(topSTypeChartStats)}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card
                sx={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '16px',
                  width: '100%',
                  minHeight: 369,
                  maxHeight: 369,
                }}
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
              >
                <CardContent sx={{ py: 3 }}>
                  <Typography variant="h4" fontWeight={500}>
                    Enrollment Report
                  </Typography>
                  <Box
                    sx={{
                      pt: 2,
                      minHeight: '18rem',
                      maxHeight: '18rem',
                    }}
                  >
                    <LineChart data={enrolmentChartStats} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card
                sx={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '16px',
                  width: '100%',
                  minHeight: 369,
                  maxHeight: 369,
                }}
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
              >
                <CardContent sx={{ py: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <Typography variant="h4" fontWeight={500}>
                      Top 4 Vendors by Location
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={500} color={'rgba(39, 41, 55, 0.75)'}>
                      (State)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      pt: 2,
                      minHeight: '18rem',
                      maxHeight: '18rem',
                    }}
                  >
                    <PieChart data={locationChartStats} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Home;
