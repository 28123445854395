import { apiClient } from './client';

const getNotes = (vendorId: string) => {
  return apiClient.get(`/notes?vendorId=${vendorId}`);
};

const create = (note: any) => {
  return apiClient.post(`/notes`, note);
};

const deleteNote = (noteId: any) => {
  return apiClient.delete(`/notes?noteId=${noteId}`);
};

export default { getNotes, create, deleteNote };
