import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from 'src/context/SessionContext';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import api from 'src/api';
import { vendorNotesSchema } from 'src/schemas/vendor';
import { VendorNotes } from 'src/customTypes/vendor';
import CircleIcon from '@mui/icons-material/Circle';

const LoadingBar = ({}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
      <CircularProgress />
    </Box>
  );
};

interface VendorChatsProps {
  vendorId: string;
  type: string;
}

export default function VendorChats({ vendorId, type }: VendorChatsProps) {
  const { session } = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState<VendorNotes>();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const onAdd = () => {
    if (note.length > 0) {
      const newNote = {
        note: note,
        type,
        vendorId: vendorId,
        userId: session.userId,
        displayName:
          session.role === 'IDCORE_ADMIN'
            ? 'Id Core Support'
            : session.role === 'PROPERTY_MANAGER'
            ? 'ResProp Property Manager'
            : '???',
      };
      api.notes.create(newNote).then(res => {
        if (res.status === 201) {
          const copy = notes?.filter(() => true);
          copy?.unshift(
            ...[
              {
                _id: res.data._id,
                note: res.data.note,
                displayName: res.data.displayName,
                dateAdded: res.data.dateAdded,
                vendorId: res.data.vendorId,
                type: type,
              },
            ]
          );
          setNotesOrder(copy);
          setNote('');
        }
      });
    }
  };

  function getTime(dateTime: string) {
    const dateNow = DateTime.now().toLocal();
    const chatDate = DateTime.fromISO(dateTime).toLocal();
    const duration = dateNow.diff(chatDate, ['years', 'months', 'days', 'hours', 'minutes']);
    if (dateNow.year == chatDate.year && dateNow.month == chatDate.month && duration.days > 0 && duration.days <= 7) {
      return duration.days + ' days ago';
    }
    if (
      dateNow.year == chatDate.year &&
      dateNow.month == chatDate.month &&
      duration.days == 0 &&
      duration.hours > 0 &&
      duration.hours <= 23
    ) {
      return duration.hours + ' hours ago';
    }
    if (dateNow.year == chatDate.year && dateNow.month == chatDate.month && duration.days == 0 && duration.minutes <= 59) {
      return duration.minutes.toFixed(0) + ' minutes ago';
    }
    return dateNow.year == chatDate.year ? chatDate.toFormat('LLL dd') : chatDate.toFormat('LLL dd YYYY');
  }

  const onDelete = (id: string) => {
    if (id) {
      api.notes.deleteNote(id).then(res => {
        const other = notes?.filter(n => n._id != id);
        setNotesOrder(other);
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setNotes(undefined);
    api.notes
      .getNotes(vendorId)
      .then(value => {
        var data = value.data.filter((x: any) => x.type === type);
        setNotesOrder(data);
      })
      .catch(reason => console.error('Error fetching notes', reason))
      .finally(() => setIsLoading(false));
  }, [vendorId]);

  function setNotesOrder(notes: any) {
    notes.sort((a: any, b: any) => {
      return new Date(a.dateAdded).valueOf() - new Date(b.dateAdded).valueOf();
    });
    setNotes(vendorNotesSchema.parse(notes));
  }

  if (isLoading) {
    return (
      <Box p={6}>
        <LoadingBar></LoadingBar>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        py={2}
        sx={{
          minHeight: '311px',
        }}
      >
        {notes?.map((n, key) => {
          return (
            <Box key={key} sx={{ px: 6, pb: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} pb={2}>
                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant="body1" textTransform={'capitalize'} fontWeight={700}>
                    {n.displayName}
                  </Typography>
                  <CircleIcon sx={{ fontSize: 6, mt: 1.2, ml: 1, color: '#6E6E6E' }}></CircleIcon>
                  <Typography variant="body2" color={'#6E6E6E'} pl={1} pt={0.5}>
                    {getTime(n.dateAdded)}
                  </Typography>
                </Box>
                {n.type == 'internal' && (
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color={'#CF1124'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onDelete(n._id)}
                  >
                    Delete
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  p: 2,
                  boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)',
                  borderRadius: '8px',
                }}
              >
                {n.note}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ px: 6, pb: 4 }}>
        <TextField
          fullWidth
          value={note}
          placeholder="Add Your Comment"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => onAdd()}>
                  <SendOutlinedIcon sx={{ color: '#035388' }}></SendOutlinedIcon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
}
