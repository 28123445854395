import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useIsMobile } from 'src/hooks/useIsMobile';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  loading?: boolean;
  data: { labels: string; value: number; percentage: number; color: string }[];
};

export default function PieChart({ data }: Props) {
  const isMobile = useIsMobile();

  const dataInfo: ChartData<'doughnut'> = {
    labels: data.map(x => x.labels),
    datasets: [
      {
        data: data.map(x => x.value),
        backgroundColor: Object.values(data.map(x => x.color)),
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    radius: 100,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const LegendItem = ({ label, color }: { label: string; color: string }) => {
    return (
      <Stack direction="row" sx={{ py: 2 }}>
        <Box>
          <Box
            sx={{
              width: '.5rem',
              height: '1rem',
              backgroundColor: color,
              borderRadius: '1.5rem',
              mr: 1,
              mt: 0.5,
            }}
          ></Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" sx={{ mb: 0.5 }}>
            {label}
          </Typography>
        </Box>
      </Stack>
    );
  };

  const legends = (
    <Grid
      container
      sx={{
        pl: isMobile ? 0 : 22 / 4,
        pt: isMobile ? 0 : 2,
      }}
    >
      {data.map((d, idx) => (
        <Grid key={idx} item xs={isMobile ? 6 : 12} sx={{}}>
          <LegendItem label={`${d.labels} (${d.percentage}%)`} color={d.color} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      {isMobile ? (
        <Grid container>
          <Grid item xs={12}>
            {legends}
          </Grid>
          <Grid item xs={12} sx={{ mt: 0 }}>
            <Stack sx={{ height: '13rem' }} alignItems="center">
              <Doughnut data={dataInfo} options={options} width={'100%'} height={'100%'} />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={5}>
            {legends}
          </Grid>
          <Grid item xs={1} sx={{ borderRight: '1px solid #2729371F', height: '280px' }}></Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Doughnut data={dataInfo} options={options} width={'100%'} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
