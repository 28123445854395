import { apiClient } from './client';

interface IInvitePayload {
  vendorId?: string;
  vendorBusinessName: string;
  vendorContactName: string;
  vendorEmail: string;
  vendorPhone: string;
  invitedTo: string;
  invitedBy: string;
}

const inviteVendor = async (payload: IInvitePayload) => {
  return apiClient.post(`/invites`, payload);
};

export default { inviteVendor };
