import { Box, Button, Grid, Typography, Collapse, Card, CardContent, Link, styled, Tabs, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from 'src/api';
import { DateTime } from 'luxon';
import { Control, UseFormGetValues, UseFormReset, UseFormWatch, useForm } from 'react-hook-form';
import InputMultiSelect from 'src/components/Input/InputMultiSelect';
import InputSelect from 'src/components/Input/InputSelect';
import InputText from 'src/components/Input/InputText';
import { STATES } from 'src/contants/staticOptions';
import { useIsMobile, useIsTab } from 'src/hooks/useIsMobile';
import { IYourSearchInput } from 'src/customTypes/vendor';

type Props = {
  loading?: boolean;
  filter: any;
  setFilter: (filter: any) => void;
  control: Control<IYourSearchInput, any>;
  watch: UseFormWatch<IYourSearchInput>;
  reset: UseFormReset<IYourSearchInput>;
  getValues: UseFormGetValues<IYourSearchInput>;
  activeTab: any;
  setActiveTab: any;
};

const CustomBox = styled(Box)(({ theme }) => ({
  [`&.MuiBox-root`]: {
    display: 'flex',
    width: '35%',
    marginRight: '1%',
    overflow: 'hidden',
  },
}));

const FindVendorSearch = ({ loading, filter, setFilter, control, watch, reset, getValues, setActiveTab, activeTab }: Props) => {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const [open, setOpen] = React.useState(true);
  const [serviceList, setServiceList] = useState<any>({});
  const [serviceFilter, setServiceFilter] = useState<any>({});
  const [isDirty, setIsDirty] = useState(false);

  const onSearch = () => {
    const newFilter: any = { ...filter };
    const name = getValues('name');
    if (name) {
      newFilter.vendorName = String(name).trim();
    } else {
      newFilter.vendorName = undefined;
    }

    const services = getValues('services');
    if (services) {
      if (services.length === 0) {
        newFilter.service = undefined;
      } else {
        newFilter.service = services.map((e: any) => e.name);
      }
    } else {
      newFilter.service = undefined;
    }

    const zip = getValues('zip');
    if (zip) {
      newFilter.zipCode = String(zip);
      newFilter.radius = undefined;
    } else {
      newFilter.zipCode = undefined;
    }

    const radius = getValues('radius');
    if (radius) {
      newFilter.radius = String(radius);
    } else {
      newFilter.radius = undefined;
    }

    const state = getValues('state');
    if (state) {
      newFilter.state = String(state);
    } else {
      newFilter.state = undefined;
    }

    newFilter.timeStamp = DateTime.now().toMillis();
    setFilter(newFilter);
  };

  const renderServices = () => {
    const s = getValues('services');
    if (s && s.length > 0) {
      const text = s?.length > 1 ? ' +' + s.length + ' more ' : '';
      return s.at(0).name + text;
    } else return '';
  };

  function resetForm() {
    setIsDirty(false);
    reset();
    onSearch();
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setIsDirty(name ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (Object.keys(serviceList).length == 0) {
      api.services
        .getServices()
        .then((res: any) => {
          setServiceList(res.data);
          setServiceFilter(res.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          py={2}
          sx={{
            display: 'flex',
            justifyContent: 'start',
          }}
        >
          <Tabs value={activeTab}
            onChange={(e, v) => setActiveTab(v)}
            TabIndicatorProps={{ style: { backgroundColor: '#035388' } }}
            itemProp=""
            variant="scrollable"
            scrollButtons="auto">
            <Tab label='Search For Vendors'/>
            <Tab label='Pending Invites'/>
          </Tabs>
          
        </Box>
        {/* <Link underline="none" onClick={() => setOpen(!open)}>
          <Box sx={{ display: 'flex', pt: 4, gap: 2 }}>
            <Typography
              pl={1}
              fontSize={20}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
              textTransform={'capitalize'}
            >
              {open && 'Collapse Search'}
              {!open && 'Expand Search'}
            </Typography>
            {open && <KeyboardArrowDownIcon />}
            {!open && <KeyboardArrowUpIcon />}
          </Box>
        </Link> */}
      </Box>
      <Collapse in={open} timeout={'auto'}>
        <Card sx={{ bgcolor: '#FFF', boxShadow: '0px 4px 8px 2px #00000026', borderRadius: '16px' }}>
          <CardContent sx={{ p: 4 }}>
            <Grid container pb={1} spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <InputText name="name" placeholder="Enter Vendor Name" label="Vendor Name" control={control} />
              </Grid>
              {/* <Grid item md={4} sm={6} xs={12}>
                <InputText name="id" placeholder="Enter Vendor Id" label="Vendor ID" control={control} />
              </Grid> */}
              <Grid item md={4} sm={6} xs={12}>
                <InputMultiSelect
                  name="services"
                  label="Vendor Services"
                  control={control}
                  defaultValues={[]}
                  options={serviceFilter}
                />
              </Grid>
              {/* {activeTab === 0 && <Grid item md={4} sm={6} xs={12}>
                <InputSelect
                  name="range"
                  label="Percent Match Range"
                  placeholder="Select Range"
                  options={[
                    { id: '80 - 100%', name: '80 - 100%' },
                    { id: '60 - 80%', name: '60 - 80%' },
                    { id: '40 - 60%', name: '40 - 60%' },
                    { id: '< 40%', name: '< 40%' },
                  ]}
                  control={control}
                  isMultiple={false}
                />
              </Grid>} */}
              {/* <Grid item md={4} sm={6} xs={12}>
                <InputDate
                  name="enrollmetnDate"
                  label="Enrollment Date"
                  placeholder="Enrollment Date"
                  control={control}
                  defaultValue={watch('enrollmetnDate')}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <InputDate
                  name="complianceDate"
                  label="Compliance Date"
                  placeholder="Compliance Date"
                  control={control}
                  defaultValue={watch('complianceDate')}
                />
              </Grid> */}
              <Grid item md={4} sm={6} xs={12}>
                <InputSelect
                  name="state"
                  label="State"
                  placeholder="Select State"
                  options={STATES.map(x => {
                    return { id: x.id, name: x.label };
                  })}
                  control={control}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <InputText name="zip" label="Zip Code" placeholder="Enter Zip Code" control={control} />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <InputSelect
                  name="radius"
                  label="Radius"
                  placeholder="Select Radius"
                  options={[
                    { id: '5', name: '5 Miles' },
                    { id: '10', name: '10 Miles' },
                    { id: '25', name: '25 Miles' },
                    { id: '50', name: '50 Miles' },
                    { id: '100', name: '100 Miles' },
                  ]}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'end', pt: 2 }}>
                  <Button color="error" onClick={() => resetForm()} sx={{ mr: 2, py: 2 }}>
                    <Typography variant="body2" fontWeight={700} color={'#243B53'} textTransform={'none'}>
                      Clear
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => onSearch()}
                    sx={{ bgcolor: '#102A43', py: 1.5, borderRadius: '8px' }}
                  >
                    <Typography variant="body2" fontWeight={700} color={'#FFF'} textTransform={'none'}>
                      Search For Vendors
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Collapse>
      <Collapse in={!open} timeout={'auto'}>
        <Card sx={{ bgcolor: '#FFF', boxShadow: '0px 4px 8px 2px #00000026', borderRadius: '16px' }}>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isTab ? 'column' : 'row',
                justifyContent: isDirty ? 'space-evenly' : 'center',
                ['& > div:not(:last-child)']: {
                  borderRight: isTab ? 'none' : '1px solid #BCCCDC',
                  pb: isTab ? 2 : 0,
                },
                pt: 1,
              }}
            >
              {!isDirty && (
                <Typography variant="h5" color={'#272937BF'}>
                  No Search criteria
                </Typography>
              )}
              {isDirty && (
                <>
                  {getValues('name') && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'Name: ' + getValues('name')}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('id') && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'Id: ' + getValues('id')}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('services') && getValues('services')?.at(0) && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'Services: ' + renderServices()}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('complianceDate') && getValues('complianceDate')?.from && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'Comp Date: ' + getValues('complianceDate')?.label}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('enrollmetnDate') && getValues('enrollmetnDate')?.from && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'Enroll Date: ' + getValues('enrollmetnDate')?.label}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('state') && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'State: ' + getValues('state')}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('zip') && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'Zip: ' + getValues('zip')}
                      </Typography>
                    </CustomBox>
                  )}
                  {getValues('radius') && (
                    <CustomBox style={{ width: isTab ? '100%' : '35%' }}>
                      <Typography variant="h5" fontWeight={500} color={'#272937BF'} lineHeight={'28px'}>
                        {'radius: ' + getValues('radius')}
                      </Typography>
                    </CustomBox>
                  )}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};

export default FindVendorSearch;
