import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import VendorTabPanel from './VendorTabPanel';
import EditBlock from '../EditBlock';
import InfoBlock from '../InfoBlock';
import api from 'src/api';
import { SessionContext } from 'src/context/SessionContext';
import VendoAccordion from '../Accordion';
import AddIcon from '@mui/icons-material/Add';
import { IAgentInfo, IPolicy, InsurancePolicyTypes } from 'src/customTypes/insurance';
import { ArrowBackIos } from '@mui/icons-material';

type Props = {
  isHidden: boolean;
  vendorData: any;
  setVendorData: (data: any) => void;
  isLoading?: boolean;
  setIsLoading: (loading: boolean) => void;
  files: Array<any>;
  setFiles: (files: Array<any>) => void;
  vendorGeneralLiability: IPolicy | null;
  setVendorGeneralLiability: (policy: IPolicy) => void;
  vendorAutoLiability: IPolicy | null;
  setVendorAutoLiability: (policy: IPolicy) => void;
  vendorExcessLiability: IPolicy | null;
  setVendorExcessLiability: (policy: IPolicy) => void;
  vendorWorkerComp: IPolicy | null;
  setVendorWorkerComp: (policy: IPolicy) => void;
  handleUploadOpen: (isOpen: boolean) => void;
};

export default function InsuranceDocTab({
  isHidden,
  vendorData,
  setVendorData,
  isLoading,
  setIsLoading,
  files,
  setFiles,
  vendorGeneralLiability,
  setVendorGeneralLiability,
  vendorAutoLiability,
  setVendorAutoLiability,
  vendorExcessLiability,
  setVendorExcessLiability,
  vendorWorkerComp,
  setVendorWorkerComp,
  handleUploadOpen,
}: Props) {
  const { session } = useContext(SessionContext);
  const isAdmin = session.role === 'IDCORE_ADMIN';

  const [policyInfo, setPolicyInfo] = useState<IPolicy | null>();
  const [insureType, setPolicyType] = useState<InsurancePolicyTypes>();

  const [insureTabvalue, setInsureTabValue] = useState(0);
  const handleInsureTabChange = (newValue: number) => {
    setInsureTabValue(newValue);
  };

  const onInsureTabDataChange = async (
    type: string,
    value: any,
    insureType?: InsurancePolicyTypes,
    policyId?: string | null | undefined
  ) => {
    if (type == 'chip' && insureType) {
      await onChangePolicyStatus(insureType, vendorData._id, value, policyId);
    }
    if (type == 'date' && insureType) {
      await onChangeInsuranceExpiration(insureType, vendorData._id, value, policyId);
    }

    if (type == 'tab' && insureType) {
      switch (insureType) {
        case InsurancePolicyTypes.GENERAL_LIABILITY:
          setPolicyInfo(vendorGeneralLiability);
          break;
        case InsurancePolicyTypes.AUTO_LIABILITY:
          setPolicyInfo(vendorAutoLiability);
          break;
        case InsurancePolicyTypes.EXCESS_LIABILITY:
          setPolicyInfo(vendorExcessLiability);
          break;
        case InsurancePolicyTypes.WORKERS_COMP:
          setPolicyInfo(vendorWorkerComp);
          break;
      }
      handleInsureTabChange(value);
      setPolicyType(insureType);
    }
  };

  const onChangePolicyStatus = async (
    type: InsurancePolicyTypes,
    vendorId: string,
    status: string,
    policyId: string | null | undefined
  ) => {
    if (!policyId) {
      const policy = await api.insurance.createPolicy(type, { vendorId, status });
      const newPolicy = {} as IPolicy;
      delete Object.assign(newPolicy, policy, { ['policyId']: policy['id'] })['id'];
      switch (type) {
        case InsurancePolicyTypes.GENERAL_LIABILITY:
          //setGeneralLiability({ ...newPolicy });
          setVendorGeneralLiability({ ...newPolicy });
          break;
        case InsurancePolicyTypes.AUTO_LIABILITY:
          //setAutoLiability({ ...newPolicy });
          setVendorAutoLiability({ ...newPolicy });
          break;
        case InsurancePolicyTypes.EXCESS_LIABILITY:
          //setExcessLiability({ ...newPolicy });
          setVendorExcessLiability({ ...newPolicy });
          break;
        case InsurancePolicyTypes.WORKERS_COMP:
          //setWorkerComp({ ...newPolicy });
          setVendorWorkerComp({ ...newPolicy });
          break;
      }
    } else {
      try {
        switch (type) {
          case InsurancePolicyTypes.GENERAL_LIABILITY:
            await api.insurance.updateGeneralLiabilityStatus(policyId!, status, session.details.name);
            if (vendorGeneralLiability) setVendorGeneralLiability({ ...vendorGeneralLiability, status });
            break;
          case InsurancePolicyTypes.AUTO_LIABILITY:
            await api.insurance.updateAutoLiabilityStatus(policyId!, status, session.details.name);
            if (vendorAutoLiability) setVendorAutoLiability({ ...vendorAutoLiability, status });
            break;
          case InsurancePolicyTypes.EXCESS_LIABILITY:
            await api.insurance.updateExcessLiabilityStatus(policyId!, status, session.details.name);
            if (vendorExcessLiability) setVendorExcessLiability({ ...vendorExcessLiability, status });
            break;
          case InsurancePolicyTypes.WORKERS_COMP:
            await api.insurance.updateWorkerComStatus(policyId!, status, session.details.name);
            if (vendorWorkerComp) setVendorWorkerComp({ ...vendorWorkerComp, status });
            break;
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onChangeInsuranceExpiration = async (
    type: InsurancePolicyTypes,
    vendorId: string,
    expiration: string,
    policyId: string | null | undefined
  ) => {
    if (!policyId) {
      const policy = await api.insurance.createPolicy(type, { vendorId, expiration });
      const newPolicy = {} as IPolicy;
      delete Object.assign(newPolicy, policy, { ['policyId']: policy['id'] })['id'];
      switch (type) {
        case InsurancePolicyTypes.GENERAL_LIABILITY:
          //setGeneralLiability({ ...newPolicy });
          setVendorGeneralLiability({ ...newPolicy });
          break;
        case InsurancePolicyTypes.AUTO_LIABILITY:
          //setAutoLiability({ ...newPolicy });
          setVendorAutoLiability({ ...newPolicy });
          break;
        case InsurancePolicyTypes.EXCESS_LIABILITY:
          //setExcessLiability({ ...newPolicy });
          setVendorExcessLiability({ ...newPolicy });
          break;
        case InsurancePolicyTypes.WORKERS_COMP:
          //setWorkerComp({ ...newPolicy });
          setVendorWorkerComp({ ...newPolicy });
          break;
      }
    } else {
      try {
        switch (type) {
          case InsurancePolicyTypes.GENERAL_LIABILITY:
            await api.insurance.updateGeneralLiabilityExpiration(policyId!, expiration);
            if (vendorGeneralLiability) setVendorGeneralLiability({ ...vendorGeneralLiability, expiration });
            break;
          case InsurancePolicyTypes.AUTO_LIABILITY:
            await api.insurance.updateAutoLiabilityExpiration(policyId!, expiration);
            if (vendorAutoLiability) setVendorAutoLiability({ ...vendorAutoLiability, expiration });
            break;
          case InsurancePolicyTypes.EXCESS_LIABILITY:
            await api.insurance.updateExcessLiabilityExpiration(policyId!, expiration);
            if (vendorExcessLiability) setVendorExcessLiability({ ...vendorExcessLiability, expiration });
            break;
          case InsurancePolicyTypes.WORKERS_COMP:
            await api.insurance.updateWorkerComExpiration(policyId!, expiration);
            if (vendorWorkerComp) setVendorWorkerComp({ ...vendorWorkerComp, expiration });
            break;
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const [isEditingInsureInfo, setIsEditingInsureInfo] = useState(false);

  const handleInsureSubmit = async (data: {}) => {
    if (insureType) {
      const agentInfo: IAgentInfo = { ...(data as IAgentInfo) };
      let res;
      let id = policyInfo?.policyId;
      if (!id) {
        const res = await api.insurance.createPolicy(insureType, {
          vendorId: vendorData._id,
          agentInfo: agentInfo,
        });
        const insureInfo = getInsureInfo(res);
        setPolicyInfo(insureInfo);
        switch (insureType) {
          case InsurancePolicyTypes.GENERAL_LIABILITY:
            //setGeneralLiability(insureInfo);
            setVendorGeneralLiability(insureInfo);
            break;
          case InsurancePolicyTypes.AUTO_LIABILITY:
            //setAutoLiability(insureInfo);
            setVendorAutoLiability(insureInfo);
            break;
          case InsurancePolicyTypes.EXCESS_LIABILITY:
            //setExcessLiability(insureInfo);
            setVendorExcessLiability(insureInfo);
            break;
          case InsurancePolicyTypes.WORKERS_COMP:
            //setWorkerComp(insureInfo);
            setVendorWorkerComp(insureInfo);
            break;
        }
      } else {
        res = await api.insurance.submitPolicy(insureType, {
          vendorId: vendorData._id,
          ...agentInfo,
          policyId: policyInfo?.policyId,
        } as any);
        const insureInfo = getInsureInfo(res);
        setPolicyInfo(insureInfo);
        switch (insureType) {
          case InsurancePolicyTypes.GENERAL_LIABILITY:
            if (vendorGeneralLiability) setVendorGeneralLiability(insureInfo);
            break;
          case InsurancePolicyTypes.AUTO_LIABILITY:
            if (vendorAutoLiability) setVendorAutoLiability(insureInfo);
            break;
          case InsurancePolicyTypes.EXCESS_LIABILITY:
            if (vendorExcessLiability) setVendorExcessLiability(insureInfo);
            break;
          case InsurancePolicyTypes.WORKERS_COMP:
            if (vendorWorkerComp) setVendorWorkerComp(insureInfo);
            break;
        }
      }
      setIsEditingInsureInfo(prevState => !prevState);
    }
  };

  function getInsureInfo(res: any) {
    return {
      brokerContact: res.agentInfo.brokerContact,
      brokerEmail: res.agentInfo.brokerEmail,
      brokerName: res.agentInfo.brokerName,
      brokerPhone: res.agentInfo.brokerPhone,
      expiration: res.expiration,
      policyId: res.id,
      policyNo: res.policyNo,
      status: res.status,
    };
  }

  function getInsuranceTitile() {
    switch (insureType) {
      case InsurancePolicyTypes.GENERAL_LIABILITY:
        return 'General Liability';
      case InsurancePolicyTypes.AUTO_LIABILITY:
        return 'Auto Liability';
      case InsurancePolicyTypes.EXCESS_LIABILITY:
        return 'Excess Liability';
      case InsurancePolicyTypes.WORKERS_COMP:
        return 'Workers Comp';
    }
  }

  useEffect(() => {
    if (!isHidden) {
    }
  }, [isHidden]);

  return (
    <VendorTabPanel isHidden={isHidden}>
      <Box>
        {/* <SwipeableViews
axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
index={value}
onChangeIndex={handleChangeIndex}
> */}
        <VendorTabPanel isHidden={insureTabvalue !== 0}>
          <Box>
            <VendoAccordion
              title="General Liability"
              status={!vendorGeneralLiability ? 'MISSING' : vendorGeneralLiability?.status === 'MISSINGEND' ? 'NON-COMPLIANT' : vendorGeneralLiability?.status || 'PENDING'}
              chipItems={['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'MISSING', 'N/A']}
              id={vendorData._id}
              policy={vendorGeneralLiability}
              showDate
              dataOnChange={(type, value) =>
                onInsureTabDataChange(type, value, InsurancePolicyTypes.GENERAL_LIABILITY, vendorGeneralLiability?.policyId)
              }
              fileUrls={files.filter(x => x.type == 'coi')}
              setFileUrls={setFiles}
              isDelete={true}
            ></VendoAccordion>
            <VendoAccordion
              title="Auto Liability"
              status={!vendorAutoLiability ? 'MISSING' : vendorAutoLiability?.status === 'MISSINGEND' ? 'NON-COMPLIANT' : vendorAutoLiability?.status || 'PENDING'}
              chipItems={['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'MISSING', 'N/A']}
              id={vendorData._id}
              policy={vendorAutoLiability}
              showDate
              dataOnChange={(type, value) =>
                onInsureTabDataChange(type, value, InsurancePolicyTypes.AUTO_LIABILITY, vendorAutoLiability?.policyId)
              }
              isEven
              fileUrls={files.filter(x => x.type == 'coi')}
              setFileUrls={setFiles}
              isDelete={true}
            ></VendoAccordion>
            <VendoAccordion
              title="Excess Liability"
              status={!vendorExcessLiability ? 'MISSING' : vendorExcessLiability?.status === 'MISSINGEND' ? 'NON-COMPLIANT' : vendorExcessLiability?.status || 'PENDING'}
              chipItems={['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'MISSING', 'N/A']}
              id={vendorData._id}
              policy={vendorExcessLiability}
              showDate
              dataOnChange={(type, value) =>
                onInsureTabDataChange(type, value, InsurancePolicyTypes.EXCESS_LIABILITY, vendorExcessLiability?.policyId)
              }
              fileUrls={files.filter(x => x.type == 'coi')}
              setFileUrls={setFiles}
              isDelete={true}
            ></VendoAccordion>
            <VendoAccordion
              title="Workers Comp"
              status={!vendorWorkerComp ? 'MISSING' : vendorWorkerComp?.status === 'MISSINGEND' ? 'NON-COMPLIANT' : vendorWorkerComp?.status || 'PENDING'}
              chipItems={['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'MISSING', 'N/A']}
              id={vendorData._id}
              policy={vendorWorkerComp}
              showDate
              dataOnChange={(type, value) =>
                onInsureTabDataChange(type, value, InsurancePolicyTypes.WORKERS_COMP, vendorWorkerComp?.policyId)
              }
              isEven
              fileUrls={files.filter(x => x.type == 'coi')}
              setFileUrls={setFiles}
              isDelete={true}
            ></VendoAccordion>
          </Box>
          <Box
            sx={{
              borderTop: '1px solid #BCCCDC',
              p: 2,
              color: '#035388',
            }}
          >
            <Box
              sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer', maxWidth: 150 }}
              onClick={() => handleUploadOpen(true)}
            >
              <AddIcon></AddIcon>
              <Typography color={'#035388'} fontWeight={700}>
                Upload a File
              </Typography>
            </Box>
          </Box>
        </VendorTabPanel>
        <VendorTabPanel isHidden={insureTabvalue !== 1}>
          <Box>
            <Box
              sx={{ pt: 4, pb: 1, px: 5, display: 'flex', justifyContent: 'start', cursor: 'pointer' }}
              onClick={() => setInsureTabValue(0)}
            >
              <ArrowBackIos></ArrowBackIos>
              <Typography variant="h5" color={'#102A43'}>
                Broker Information for {getInsuranceTitile()}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              {isEditingInsureInfo && (
                <EditBlock
                  title={''}
                  onSubmit={data => {
                    handleInsureSubmit(data);
                  }}
                  onCancel={() => setIsEditingInsureInfo(false)}
                  fields={[
                    {
                      name: 'brokerName',
                      value: policyInfo?.brokerName || '',
                      type: 'text',
                      label: 'Broker Name:',
                      rules: {
                        required: true,
                      },
                    },
                    {
                      name: 'brokerContact',
                      value: policyInfo?.brokerContact || '',
                      type: 'text',
                      label: 'Broker Contact Name:',
                      rules: {
                        required: true,
                      },
                    },
                    {
                      name: 'brokerEmail',
                      value: policyInfo?.brokerEmail || '',
                      type: 'email',
                      label: 'Broker Email:',
                      rules: {
                        required: true,
                      },
                      errorMessage: {
                        pattern: 'Enter a valid email address',
                      },
                    },
                    {
                      name: 'brokerPhone',
                      value: policyInfo?.brokerPhone || '',
                      type: 'phone',
                      label: 'Broker Contact Number:',
                      rules: {
                        required: true,
                      },
                    },
                  ]}
                />
              )}

              {!isEditingInsureInfo && (
                <Box>
                  <InfoBlock
                    title={''}
                    onEdit={isAdmin ? () => setIsEditingInsureInfo(false) : undefined}
                    info={[
                      {
                        name: 'Broker Name:',
                        value: policyInfo?.brokerName || '',
                      },
                      {
                        name: 'Broker Contact Name:',
                        value: policyInfo?.brokerContact || '',
                      },
                      {
                        name: 'Broker Email:',
                        value: policyInfo?.brokerEmail || '',
                      },
                      {
                        name: 'Broker Contact Number:',
                        value: policyInfo?.brokerPhone || '',
                      },
                    ]}
                  />
                  <Box sx={{ pt: 4, display: 'flex', justifyContent: 'end' }}>
                    <Button color="error" onClick={() => setInsureTabValue(0)} sx={{ mr: 2 }}>
                      <Typography variant="body1" fontWeight={700} color={'#1992D4'} textTransform={'none'}>
                        Return to Insurance Docs
                      </Typography>
                    </Button>
                    <Button color="primary" onClick={() => setIsEditingInsureInfo(true)}>
                      <Typography variant="body1" fontWeight={700} color={'#035388'} textTransform={'none'}>
                        Make Changes
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </VendorTabPanel>
        {/* </SwipeableViews> */}
      </Box>
    </VendorTabPanel>
  );
}
