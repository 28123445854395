import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    background: {
      default: '#F1F5F8',
      paper: '#F1F5F8',
    },
    primary: {
      light: '#627D98',
      main: '#334E68',
      dark: '#102A43',
    },
    secondary: {
      light: '#2BB0ED',
      main: '#127FBF',
      dark: '#035388',
    },
    info: {
      light: '#2BB0ED',
      main: '#127FBF',
      dark: '#035388',
    },
    warning: {
      light: '#F0B429',
      main: '#CB6E17',
      dark: '#8D2B0B',
    },
    success: {
      light: '#27AB83',
      main: '#147D64',
      dark: '#014D40',
    },
    error: {
      light: '#E12D39',
      main: '#AB091E',
      dark: '#610316',
    },
    text: {
      primary: '#0D0D0D',
      secondary: '#6E6E6E',
      disabled: '#9E9E9E',
    },
  },
  typography: {
    fontFamily: ['Nunito'].join(','),
    htmlFontSize: 14,
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 600,
    h1: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Medium',
      fontSize: '48px',
      lineHeight: '52px',
      fontWeight: 500,
    },
    h2: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Regular',
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 400,
    },
    h3: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Regular',
      fontSize: '30px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    h4: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Regular',
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 400,
    },
    h5: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-SemiBold',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    h6: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-SemiBold',
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: 600,
    },
    subtitle1: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Bold',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    subtitle2: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Bold',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
    },
    body1: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Regular',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      color: '#0D0D0D',
      fontFamily: 'Nunito-Regular',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    overline: {
      fontFamily: 'Nunito-Regular',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 700,
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: 'Nunito-Regular',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
    },
  },
});
