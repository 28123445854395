import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  accordionClasses,
  accordionDetailsClasses,
  accordionSummaryClasses,
  styled,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from 'src/context/SessionContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import api from 'src/api';
import { ToastContext } from 'src/context/ToastContext';
import { openInNewTab } from 'src/helpers/url';
import StatusDropdownMenu from '../StatusDropdownMenu';
import { IPolicy, InsurancePolicyTypes } from 'src/customTypes/insurance';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface VendoAccordionFileRowrops {
  id: string;
  isCompliance: boolean;
  isDelete: boolean;
  setIsFileReload?: (isReload: boolean) => void;
  isActivity: boolean;
  policy?: IPolicy | null;
  dataOnChange?: (value: any) => void;
  fileUrls?: any[];
  setFileUrls?: (urls: any[]) => void;
}

const VendoAccordionFileRow = ({
  id,
  isCompliance,
  isDelete,
  setIsFileReload,
  isActivity,
  policy,
  dataOnChange,
  fileUrls,
  setFileUrls,
}: VendoAccordionFileRowrops) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<any>>([]);
  const { setToast } = useContext(ToastContext);

  const downloadPolicyFile = (policyId: string, filename: string) => {
    api.insurance
      .downloadCoi(policyId, filename)
      .then(res => {
        openInNewTab(res.data.url);
      })
      .catch(err => {
        console.error(err);
      });
  };
  //
  const downloadFile = (filename: string) => {
    api.vendors
      .getFileUrl(id, filename)
      .then(res => {
        const { url } = res;
        openInNewTab(url);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const deletePolicyFile = (fileId: string, filename: string) => {
    setIsLoading(true);
    api.insurance
      .removeCoi(fileId, filename, id)
      .then(res => {
        const f = files?.filter(v => v !== filename) || [];
        setFiles([...f]);
        setIsLoading(false);
        setToast((prevState: any) => ({
          ...prevState,
          isHidden: false,
          message: 'File deleted successfully!',
          color: 'success',
          severity: 'success',
        }));
        setFileUrls && setFileUrls([...f]);
      })
      .catch(err => {
        setIsLoading(false);
        setToast((prevState: any) => ({
          ...prevState,
          isHidden: false,
          message: 'File deletion failed!',
          color: 'error',
          severity: 'error',
        }));
        console.error(err);
      });
  };

  const deleteFile = (fileId: string, filename: string) => {
    setIsLoading(true);
    api.vendors
      .removeFile(fileId, filename)
      .then(res => {
        const f = files?.filter((v: any) => v.name !== filename) || [];
        setFiles([...f]);
        setIsLoading(false);
        setToast((prevState: any) => ({
          ...prevState,
          isHidden: false,
          message: 'File deleted successfully!',
          color: 'success',
          severity: 'success',
        }));
        setFileUrls && setFileUrls([...f]);
        setIsFileReload && setIsFileReload(true);
      })
      .catch(err => {
        setIsLoading(false);
        setToast((prevState: any) => ({
          ...prevState,
          isHidden: false,
          message: 'File deletion failed!',
          color: 'error',
          severity: 'error',
        }));
        console.error(err);
      });
  };

  useEffect(() => {
    fileUrls && setFiles(fileUrls);
  }, [fileUrls]);

  return (
    <Box>
      {policy && policy.policyId && (
        <Box
          sx={{
            py: 2,
            px: 4,
            borderBottom: '1px solid #D9E2EC',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Update: Broker Information was added</Typography>
          <Typography color={'#127FBF'} onClick={() => dataOnChange && dataOnChange(1)} sx={{ cursor: 'pointer' }}>
            View Broker Info
          </Typography>
        </Box>
      )}
      {(!policy || !policy.policyId) && !isCompliance && (
        <Box
          sx={{
            py: 2,
            px: 4,
            borderBottom: '1px solid #D9E2EC',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Notice: Broker Information is missing</Typography>
          <Typography color={'#127FBF'} onClick={() => dataOnChange && dataOnChange(1)} sx={{ cursor: 'pointer' }}>
            Add Information
          </Typography>
        </Box>
      )}
      {files ? (
        files?.map((file, i) => (
          <Box
            key={i}
            sx={{
              py: 2,
              px: 4,
              borderBottom: '1px solid #D9E2EC',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {isActivity && <Typography pr={1}>Update: New File Uploaded:</Typography>}
            <Box minWidth={300} maxWidth={300}>
              <Typography
                color={'#127FBF'}
                onClick={() => (file.isPolicy ? downloadPolicyFile(file.id, file.name) : downloadFile(file.name))}
                sx={{
                  cursor: 'pointer',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {file.name.replace('_', ' ').split('/').slice(-1)}
              </Typography>
            </Box>
            {(!isCompliance || isDelete) && (
              <Typography
                pl={0.5}
                onClick={() => (file.isPolicy ? deletePolicyFile(file.id, file.name) : deleteFile(file.id, file.name))}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteOutlineIcon color="info"></DeleteOutlineIcon>
              </Typography>
            )}
            <Typography pl={1}>{DateTime.fromISO(file.lastModified).toFormat('MM/dd/yyyy')}</Typography>
            {file.uploadedBy && <Typography pl={1}>By: {file.uploadedBy}</Typography>}
          </Box>
        ))
      ) : (
        <Box sx={{}}>
          {isCompliance == false && (
            <Box sx={{ py: 2, px: 4, borderBottom: '1px solid #D9E2EC' }}>
              <Typography>Update: File Is missing necessary endorsements</Typography>
            </Box>
          )}

          <Box sx={{ p: 2, px: 4, borderBottom: '1px solid #D9E2EC' }}>
            <Typography>File: no uploaded file</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

//
const VendoAccordionItem = styled(Accordion)(({ theme }) => {
  return {
    [`&.${accordionClasses.root}`]: {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
      padding: 0,
    },
    [`&.${accordionClasses.root}:before`]: {
      backgroundColor: '#FFFFFF',
    },
    [`&.${accordionClasses.root}.even`]: {
      backgroundColor: '#F6F7FA',
    },
    [`&.${accordionClasses.expanded}`]: {
      marginBottom: 1,
      marginTop: 1,
    },
  };
});

const VendoAccordionSummaryItem = styled(AccordionSummary)(({ theme }) => {
  return {
    [`& .${accordionSummaryClasses.content}`]: {
      cursor: 'default',
    },
    [`&.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
      minHeight: '48px',
    },
    [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
      margin: '12px 0',
    },
  };
});

const VendoAccordionDetails = styled(AccordionDetails)(({ theme }) => {
  return {
    [`&.${accordionDetailsClasses.root}`]: {
      borderTop: '1px solid #D9E2EC',
      padding: 0,
    },
  };
});

interface VendoAccordionProps {
  title?: string;
  titleNode?: ReactNode;
  subTitle?: string;
  status?: string;
  isEven?: boolean;
  id?: string;
  chipItems?: string[];
  policy?: IPolicy | null;
  showDate?: boolean;
  dataOnChange?: (type: string, value: any) => void;
  fileUrls?: any[];
  setFileUrls?: (urls: any[]) => void;
  isDelete: boolean;
  setIsFileReload?: (isReload: boolean) => void;
  isExpand?: boolean;
}

export default function VendoAccordion({
  title,
  titleNode,
  subTitle,
  status,
  isEven,
  id,
  chipItems,
  policy,
  showDate,
  dataOnChange,
  fileUrls,
  setFileUrls,
  isDelete,
  setIsFileReload,
  isExpand = false,
}: VendoAccordionProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { session } = useContext(SessionContext);
  const [expanded, setExpanded] = useState<boolean>(isExpand);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const onOpenDatePicker = (event: React.MouseEvent<HTMLElement>) => {
    setIsDatePickerOpen(true);
  };

  const handleDateChange = (newValue: any) => {
    dataOnChange && dataOnChange('date', newValue.toString());
  };

  const onCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const [expirationdDate, setexpiredDate] = useState<string>('No Date Set');
  const [dropStatus, setDropStatus] = useState<string>('Pending');
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (policy && policy.expiration && status) {
      const formattedDate = DateTime.fromISO(policy.expiration);
      setexpiredDate(formattedDate.toFormat('MM/dd/yyyy'));
      const currentDate = DateTime.fromISO(DateTime.now().toString());
      const isOld = formattedDate < currentDate;
      if (isOld) {
        setIsExpired(isOld);
        setDropStatus('Expired');
      } else {
        setDropStatus(status);
      }
    } else if (status) {
      setDropStatus(status);
    }
  }, [policy?.expiration, status]);

  const [files, setFiles] = useState<Array<any>>([]);
  useEffect(() => {
    fileUrls && setFiles(fileUrls);
    // load policy base files
    if (fileUrls && policy && policy.policyId) {
      setIsLoading(true);
      api.insurance
        .getVerifiedCoiFiles(policy.policyId)
        .then((res: any) => {
          setIsLoading(false);
          if (res.files.length > 0) {
            const f = res.files.map((v: any) => {
              return {
                id: policy.policyId,
                name: v.key,
                lastModified: v.lastModified,
                uploadedBy: v.uploadedBy,
                isPolicy: true,
              };
            });
            setFiles([...fileUrls, ...f]);
          }
        })
        .catch(err => {
          setIsLoading(false);
          console.error(err);
        });
    }
  }, [fileUrls, policy]);

  const editIconRef = useRef(null);
  const isDisabled = id == null && chipItems == null;
  const hasEditPermission = session.role === 'IDCORE_ADMIN' && chipItems;
  return (
    <VendoAccordionItem expanded={expanded} className={isEven ? 'even' : 'odd'}>
      <VendoAccordionSummaryItem
        expandIcon={
          id && <ExpandMoreIcon onClick={chipItems == null || dataOnChange ? () => setExpanded(!expanded) : () => {}} />
        }
        disabled={isDisabled}
        sx={{ opacity: isDisabled ? 'unset !important' : 'inherit' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mr: id ? 1 : 4, ml: 3 }}>
          {title && <Typography sx={{ minWidth: 100 }}>{title}</Typography>}
          {titleNode && <>{titleNode}</>}

          {status && (
            <Box sx={{ minWidth: 180 }}>
              <StatusDropdownMenu
                disabled={isExpired}
                isChip={chipItems == null}
                status={dropStatus}
                items={chipItems}
                onChange={value => {
                  dataOnChange && dataOnChange('chip', value);
                }}
              ></StatusDropdownMenu>
            </Box>
          )}
          {showDate && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography pt={0.5} pr={1}>
                {expirationdDate}
              </Typography>
              <Box pr={4}>
                <IconButton ref={editIconRef} onClick={onOpenDatePicker} size="small" color="primary">
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disableMaskedInput
                    PopperProps={{
                      placement: 'bottom-end',
                      anchorEl: editIconRef.current,
                    }}
                    onClose={onCloseDatePicker}
                    open={isDatePickerOpen}
                    value={expirationdDate}
                    onChange={handleDateChange}
                    renderInput={() => <></>}
                    disabled={!hasEditPermission}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          )}
          {subTitle && (
            <Typography pt={0.5} pr={1}>
              {subTitle}
            </Typography>
          )}
        </Box>
      </VendoAccordionSummaryItem>
      <VendoAccordionDetails>
        {id && (
          <VendoAccordionFileRow
            id={id}
            policy={policy}
            dataOnChange={value => {
              dataOnChange && dataOnChange('tab', value);
            }}
            isCompliance={chipItems == null || chipItems == undefined}
            fileUrls={files}
            setFileUrls={urls => {
              setFiles(urls);
              setFileUrls && setFileUrls(urls);
            }}
            isDelete={isDelete}
            isActivity={titleNode != null}
            setIsFileReload={setIsFileReload}
          ></VendoAccordionFileRow>
        )}
      </VendoAccordionDetails>
    </VendoAccordionItem>
  );
}
