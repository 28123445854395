import { useMediaQuery, useTheme } from '@mui/material';

export const useIsMobile = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  return isMobile;
};

export const useIsTab = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  return isMobile;
};
