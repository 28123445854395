import { apiClient } from './client';
import { risklevelsSchema } from '../schemas/services';
import { serviceRiskLevels } from '../customTypes/services';

const getServices = (query?: string) => {
  return apiClient.get('/services', { params: { q: query } });
};

/**
 * Deprecated - to be removed
 */
const getServicesProvided = async () => {
  const res = await apiClient.get('/services-provided', { params: { limit: 600 } });
  return res.data;
};

/**
 * Deprecated - to be removed
 * @param ids
 * @param pmcId
 */
const getServicesByIds = async (ids: string, pmcId: string) => {
  try {
    const url = `/services-provided/by-ids?serviceIds=${ids}&pmcId=${pmcId}`;
    const res = await apiClient.get(url);
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
/**
 * Get activities
 * @param offset - number of records to skip
 * @param limit - number of records
 */
const getActivities = async (vendorId: string, offset: string, limit: string) => {
  try {
    const url = `/services-provided/activity?vendorId=${vendorId}&offset=${offset}&limit=${limit}`;
    const res = await apiClient.get(url);
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

/**
 * create activities
 * @param activity - activity payload
 */
const createActivity = async (activity: any) => {
  return await apiClient.post(`/services-provided/activity`, activity);
};

/**
 * Get a narrowed down set of risk levels
 * @param ids - interested ids of risk levels
 * @param pmcId - property management company ID
 */
const getRiskLevels = async (ids: string, pmcId?: string) => {
  try {
    const companyId = pmcId?.toString() || '61bfe94e5278deb7dedca562'; //TODO actual company ID of the vendor. For now defaults to RES PROP
    const url = `/services/risk-levels?pmcId=${companyId}`;
    const riskLevels = await apiClient.get(url);
    const flat = Object.keys(riskLevels.data)
      .map(categoryName => {
        const withCategory = riskLevels.data[categoryName].map((v: serviceRiskLevels) => {
          v.categoryName = categoryName;
          return v;
        });
        return withCategory;
      })
      .flat()
      .map(value => risklevelsSchema.parse(value));

    if (ids && ids.trim().length > 0) {
      return flat.filter(value => ids.includes(value.id));
    }
    return flat;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getServiceAreas = (payload?: { q?: string }) => {
  let params: {
    q?: string;
  } = {};
  if (payload?.q) {
    params.q = payload.q;
  }
  return apiClient.get('/services/areas', { params });
};

export default {
  getServices,
  getServicesProvided,
  getServicesByIds,
  getRiskLevels,
  getActivities,
  createActivity,
  getServiceAreas,
};
