import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  colors,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useForm } from 'react-hook-form';
import InputPhone from 'src/components/Input/InputPhone';
import InputSsn from 'src/components/Input/InputSsn';
import { DateInput, DateTime } from 'luxon';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const EditInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #BCCCDC',
    fontSize: 16,
    padding: '10px 12px',
  },
}));

const EditTextField = styled(TextField)(({ theme }) => ({
  [`& .MuiOutlinedInput-root`]: {
    padding: '6px',
  },
  [`& .MuiOutlinedInput-notchedOutline`]: {
    border: '1px solid #BCCCDC',
  },
}));

const EditDateField = styled(TextField)(({ theme }) => ({
  [`& .MuiInputBase-input`]: {
    padding: '12px',
  },
  [`& .MuiOutlinedInput-notchedOutline`]: {
    border: '1px solid #BCCCDC',
  },
}));

type IField = {
  label: string;
  name: string;
  type:
    | 'readonly'
    | 'text'
    | 'email'
    | 'phone'
    | 'ssn'
    | 'number'
    | 'single-select'
    | 'multi-select'
    | 'multi-select-ex'
    | 'date-picker';
  value?: string;
  optionValue?: { id: string; label: string; groupLabel?: string; chip?: string }[];
  options?: {
    id: string;
    label: string;
    groupLabel?: string;
    chip?: string;
  }[];
  isLoadingOptions?: boolean;
  rules?: {
    required?: boolean;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    minDate?: DateInput;
    maxDate?: DateInput;
    pattern?: RegExp;
  };
  errorMessage?: {
    [s: string]: string;
  };
};

type Props = {
  title: string;
  onCancel: () => void;
  onSubmit: (data: {}) => void;
  fields: IField[];
};

export default function EditBlock({ title, fields, onCancel, onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [inputFields, setInputFields] = useState(
    //Set initial values for select fields
    fields.map((field: any) => {
      if (field.type === 'multi-select' || field.type === 'multi-select-ex') {
        setValue(field.name, field.optionValue);
      } else if (field.type === 'single-select') {
        setValue(field.name, field.optionValue[0]);
      }
      if (field.type === 'date-picker') {
        setValue(field.name, DateTime.fromISO(field.value));
      }
      return field;
    })
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (fieldName: string, value: any) => {
    setInputFields(prevState => {
      return prevState.map(item => {
        if (item.name === fieldName) {
          item.value = value;
        }
        return item;
      });
    });

    if (value) {
      setValue(fieldName, value, { shouldValidate: true });
    }
  };

  const handleMultiSelect = (fieldName: string, val: any) => {
    setValue(fieldName, val);
  };

  const handleSingleSelect = (fieldName: string, val: unknown) => {
    setValue(fieldName, val);
  };

  useEffect(() => {
    setInputFields(fields);
  }, [fields]);

  return (
    <Grid container spacing={2} sx={{ marginBottom: 3, px: 3 }}>
      {title && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography variant="body1" fontWeight={700}>
              {title}
            </Typography>
            <IconButton sx={{ ml: 1, p: 0 }} onClick={onCancel}>
              <ModeEditIcon sx={{ color: '#127FBF' }}></ModeEditIcon>
            </IconButton>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {inputFields.map((field: IField, i) => (
            <Box key={i}>
              <FormControl variant="standard" fullWidth sx={{ pb: 2 }}>
                <Typography variant="body1" pb={1}>
                  {field.label}
                </Typography>
                {field.type === 'text' && (
                  <EditInput
                    sx={{ mt: -1 }}
                    fullWidth={true}
                    id={field.name}
                    defaultValue={field.value}
                    error={!!errors[field.name]}
                    {...register(field.name, {
                      required: !!field.rules?.required,
                      minLength: field.rules?.minLength,
                      maxLength: field.rules?.maxLength,
                      pattern: field.rules?.pattern ?? undefined,
                    })}
                  />
                )}
                {field.type === 'email' && (
                  <EditInput
                    sx={{ mt: -1 }}
                    fullWidth={true}
                    id={field.name}
                    defaultValue={field.value}
                    error={!!errors[field.name]}
                    type="email"
                    {...register(field.name, {
                      required: !!field.rules?.required,
                      pattern: /^[A-Za-z0-9.\-_+]+@[a-z0-9.\-_]+\.[a-z]{1,3}/,
                    })}
                  />
                )}
                {field.type === 'phone' && (
                  <EditInput
                    sx={{ mt: -1 }}
                    fullWidth={true}
                    id={field.name}
                    value={field.value}
                    error={!!errors[field.name]}
                    {...register(field.name, {
                      required: !!field.rules?.required,
                      pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    })}
                    onChange={event => handleChange(field.name, event.target.value)}
                    inputComponent={InputPhone as any}
                  />
                )}
                {field.type === 'ssn' && (
                  <EditInput
                    sx={{ mt: -1 }}
                    fullWidth={true}
                    id={field.name}
                    value={field.value}
                    error={!!errors[field.name]}
                    {...register(field.name, {
                      required: !!field.rules?.required,
                    })}
                    onChange={event => handleChange(field.name, event.target.value)}
                    inputComponent={InputSsn as any}
                  />
                )}
                {field.type === 'number' && (
                  <EditInput
                    sx={{ mt: -1 }}
                    fullWidth={true}
                    id={field.name}
                    defaultValue={field.value}
                    error={!!errors[field.name]}
                    type="number"
                    {...register(field.name, {
                      required: !!field.rules?.required,
                      min: field.rules?.min ?? undefined,
                      max: field.rules?.max ?? undefined,
                      minLength: field.rules?.minLength,
                      maxLength: field.rules?.maxLength,
                    })}
                  />
                )}
                {field.type === 'date-picker' && (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={field.value ? DateTime.fromISO(field.value).toFormat('MM/dd/yyyy') : ''}
                      minDate={field.rules?.minDate}
                      maxDate={field.rules?.maxDate}
                      onChange={newValue => {
                        handleChange(field.name, newValue);
                      }}
                      renderInput={params => (
                        <EditDateField
                          variant="outlined"
                          sx={{ width: '100%', mt: -1 }}
                          //inputProps={{ style: { padding: '10px' } }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
                {field.type === 'multi-select' && field.options?.length && field.optionValue && (
                  <Autocomplete
                    id={field.name}
                    fullWidth
                    multiple
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    defaultValue={[...field.optionValue]}
                    options={field.options}
                    {...register(field.name, {
                      required: !!field.rules?.required,
                    })}
                    renderInput={params => <EditTextField variant="outlined" {...params} />}
                    onChange={(event, value) => handleMultiSelect(field.name, value)}
                  />
                )}
                {field.type === 'multi-select-ex' && field.options?.length && field.optionValue && (
                  <Autocomplete
                    id={field.name}
                    defaultValue={[...field.optionValue]}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={field.options.sort((a, b) => -b.groupLabel!.localeCompare(a.groupLabel || ''))}
                    {...register(field.name, {
                      required: !!field.rules?.required,
                    })}
                    groupBy={option => option.groupLabel || ''}
                    getOptionLabel={option => option.chip?.concat(' | ').concat(option.label) || ''}
                    filterOptions={(options, { inputValue }) => {
                      return options.filter(
                        item =>
                          item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                          item.groupLabel?.toLowerCase().includes(inputValue.toLowerCase()) ||
                          field.optionValue?.map(s => s.groupLabel).includes(item.groupLabel)
                      );
                    }}
                    fullWidth
                    multiple
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        <Chip label={option.chip} sx={{ borderRadius: 0, mr: 1, width: '4rem' }} size="small" />
                        {option.label}
                      </li>
                    )}
                    disableCloseOnSelect
                    renderInput={params => (
                      <EditTextField variant="outlined" hiddenLabel {...params} placeholder="Service name" />
                    )}
                    onChange={(e, v) => handleMultiSelect(field.name, v)}
                  />
                )}
                {field.type === 'single-select' && field.options?.length && field.optionValue && (
                  <Autocomplete
                    id={field.name}
                    fullWidth
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    defaultValue={field.optionValue[0]}
                    options={field.options}
                    {...register(field.name, {
                      required: !!field.rules?.required,
                    })}
                    renderInput={params => <EditTextField variant="outlined" {...params} />}
                    onChange={(event, value) => handleSingleSelect(field.name, value)}
                  />
                )}
                {field.type === 'readonly' && (
                  <Typography sx={{ fontSize: '15px' }} variant={'body2'}>
                    {field.value}
                  </Typography>
                )}
                {errors[field.name]?.type === 'required' && (
                  <Typography variant="caption" color={colors.red[900]}>
                    This field is required
                  </Typography>
                )}
                {errors[field.name]?.type === 'pattern' && (
                  <Typography variant="caption" color={colors.red[900]}>
                    {field.errorMessage?.pattern ?? 'Input invalid'}
                  </Typography>
                )}
                {errors[field.name]?.type === 'min' && (
                  <Typography variant="caption" color={colors.red[900]}>
                    Min value cannot be less than {field.rules?.min}
                  </Typography>
                )}
                {errors[field.name]?.type === 'max' && (
                  <Typography variant="caption" color={colors.red[900]}>
                    Max value cannot be more than {field.rules?.min}
                  </Typography>
                )}
                {errors[field.name]?.type === 'minLength' && (
                  <Typography variant="caption" color={colors.red[900]}>
                    Minimum length acceptable is {field.rules?.minLength}
                  </Typography>
                )}
                {errors[field.name]?.type === 'maxLength' && (
                  <Typography variant="caption" color={colors.red[900]}>
                    Maximum length acceptable is {field.rules?.maxLength}
                  </Typography>
                )}
              </FormControl>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button color="error" onClick={onCancel} sx={{ mr: 2 }}>
              <Typography variant="body1" fontWeight={700} color={'#CF1124'} textTransform={'none'}>
                Cancel
              </Typography>
            </Button>
            <Button color="primary" type="submit" onClick={handleSubmit(onSubmit)}>
              <Typography variant="body1" fontWeight={700} color={'#035388'} textTransform={'none'}>
                Make Changes
              </Typography>
            </Button>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
}
