import { DateTime } from 'luxon';
import { Vendor, IVendorDetails } from 'src/customTypes/vendor';

export const transformVendorData = (vendorData: Vendor): IVendorDetails => {
  const { overallComplianceStatus } = vendorData;
  const w9Status = vendorData.w9 ? 'ON-FILE' : 'NON-COMPLIANT';
  const msaStatus = vendorData.payment?.status === 'succeeded' ? 'ON-FILE' : 'N/A';
  let bgCheckStatus = Object.keys(vendorData).length > 0 ? 'PENDING' : 'N/A';
  const tinCheck = vendorData.tinCheck ? vendorData.tinCheck : 'N/A';
  //If the vendor is not enrolled do not display the general info
  const paymentStatus = vendorData.payment?.status;
  //Background check status is derived from the first status
  if (vendorData.backgroundChecks && vendorData.backgroundChecks.length > 0) {
    bgCheckStatus = vendorData.backgroundChecks[0].status;
  }
  const service = vendorData.bizInfo?.services?.length > 0 ? '' : vendorData.bizInfo?.services[0]?.name || '';
  return {
    id: vendorData._id,
    userId: vendorData.userId,
    dba: vendorData.w9?.dba || null,
    services: service,
    phone: vendorData.bizInfo?.phone || null,
    email: vendorData.bizInfo?.email || null,
    address: vendorData.w9?.address || null,
    overallComplianceStatus,
    w9Status,
    msaStatus,
    tinCheck,
    paymentStatus,
    bgCheckStatus,
    invited: vendorData.invited ? new Date(vendorData.invited) : null,
    enrollmentStatus: vendorData?.enrollmentStatus || 'IN-PROGRESS',
  };
};

export const EXPIRINGDAYS = 30;
export const getExpiringStatus = (status: string, overallPolicyExpiration: string) => {
  const dateNow = DateTime.now().setLocale('en-us');
  const expiring = dateNow.plus({ days: EXPIRINGDAYS });
  const overallPolicyExpirationDate = DateTime.fromISO(overallPolicyExpiration);
  if (status === 'COMPLIANT' && overallPolicyExpirationDate >= dateNow && overallPolicyExpirationDate <= expiring) {
    status = 'EXPIRING';
  }
  return status;
};

export const getExpiringDates = () => {
  const dateNow = DateTime.now().setLocale('en-us');
  const expiringDate = dateNow.plus({ days: EXPIRINGDAYS });
  return { dateNow, expiringDate };
};
