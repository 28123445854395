import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIsMobile } from 'src/hooks/useIsMobile';

const VendorInput = styled(TextField)(({}) => ({
  [`&.MuiTextField-root`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #2729371F',
    borderRadius: '8px',
  },
  '& .MuiInputBase-input': {
    padding: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const VendorListItemButton = styled(ListItemButton)(({ theme }) => ({
  [`&.MuiButtonBase-root`]: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  [`&.MuiButtonBase-root.Mui-selected`]: {
    backgroundColor: '#F0F4F8',
  },
}));

interface IProps {
  name: string;
  control: any;
  label?: string;
  placeholder?: string;
  rules?: any;
  style?: Object;
  defaultValue?: { from: DateTime; to: DateTime; label: string } | undefined;
}

const InputDate = ({ name, label, placeholder, rules, control, style, defaultValue }: IProps) => {
  const isMobile = useIsMobile();
  const [showError, setShowError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isEndrollmentOpen, setIsEndrollmentOpen] = useState(false);

  const [editIconRef, setEditIconRef] = useState<HTMLButtonElement | null>(null);
  const [isFromDatePickerOpen, setIsFromDatePickerOpen] = useState(false);
  const [isToDatePickerOpen, setIsToDatePickerOpen] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [fromDateFilter, setFromDateFilter] = useState<string>('');
  const [toDateFilter, setToDateFilter] = useState<string>(DateTime.now().toString());

  const autoRef = useRef(null);
  const [width, setWidth] = useState(0);

  const getDateRange = () => {
    const dateNow = DateTime.now().setLocale('en-us');
    return [
      {
        range: 'Past 7 days',
        dateVal: `${dateNow
          .minus({ days: 7 })
          .toLocaleString({ day: '2-digit', month: 'short' })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}`,
        from: dateNow.minus({ days: 7 }),
        to: dateNow,
      },
      {
        range: 'Past 14 days',
        dateVal: `${dateNow
          .minus({ days: 14 })
          .toLocaleString({ day: '2-digit', month: 'short' })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}`,
        from: dateNow.minus({ days: 14 }),
        to: dateNow,
      },
      {
        range: 'Past 30 days',
        dateVal: `${dateNow
          .minus({ days: 30 })
          .toLocaleString({ day: '2-digit', month: 'short' })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}`,
        from: dateNow.minus({ days: 30 }),
        to: dateNow,
      },
      {
        range: 'Past 90 days',
        dateVal: `${dateNow
          .minus({ days: 90 })
          .toLocaleString({ day: '2-digit', month: 'short' })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}`,
        from: dateNow.minus({ days: 90 }),
        to: dateNow,
      },
      {
        range: 'Past 12 months',
        dateVal: `${dateNow
          .minus({ months: 12 })
          .toLocaleString({ day: '2-digit', month: 'short' })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}`,
        from: dateNow.minus({ months: 12 }),
        to: dateNow,
      },
    ];
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    if(selectedIndex === index) {
      setSelectedIndex(-1);
      setFromDateFilter('');
      setToDateFilter(DateTime.now().toString());
    } else {
      setSelectedIndex(index);
      const item = getDateRange().at(index);
      if (item) {
        setFromDateFilter(item?.from.toString());
        setToDateFilter(item?.to.toString());
        setIsEndrollmentOpen(!isEndrollmentOpen);
      }
    }
  };

  const getDate = () => {
    if (selectedIndex > -1) {
      return getDateRange().at(selectedIndex)?.range;
    }

    const dateNow = DateTime.now().setLocale('en-us');
    if (fromDateFilter) {
      return `${DateTime.fromISO(fromDateFilter).toLocaleString({
        day: '2-digit',
        month: 'short',
      })} - ${dateNow.toLocaleString({
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })}`;
    }
    return '';
  };

  let formOnChange: any = null;
  const onSubmit = () => {
    formOnChange({
      from: fromDateFilter,
      to: toDateFilter,
      label: getDate(),
    });
  };

  useLayoutEffect(() => {
    const current = autoRef?.current as any;
    setWidth(current?.offsetWidth);
  }, []);

  useEffect(() => {
    onSubmit();
  }, [fromDateFilter]);

  return (
    <Box>
      {!isMobile && (
        <InputLabel htmlFor={name} sx={{ pb: '8px' }}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue?.label}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          formOnChange = onChange;
          return (
            <VendorInput
              ref={autoRef}
              fullWidth
              style={style}
              placeholder={placeholder}
              error={showError && value?.length !== 0 && !!error}
              helperText={showError && value?.length !== 0 && error ? error.message : null}
              value={value?.label}
              autoComplete='off'
              onClick={(event: any) => {
                setIsEndrollmentOpen(!isEndrollmentOpen);
                setAnchorEl(event.currentTarget);
              }}
            />
          );
        }}
      />

      <Popper
        open={isEndrollmentOpen}
        anchorEl={anchorEl}
        placement={isMobile ? 'bottom' : 'bottom-start'}
        modifiers={[{ name: 'flip', enabled: false }]}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setIsEndrollmentOpen(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{ bgcolor: '#FFF' }}
                style={{
                  marginTop: 12,
                  borderRadius: '8px',
                  border: '1px solid #D9E2EC',
                  width: '382px',
                  height: '444px',
                }}
              >
                <Box>
                  <Typography variant="body2" fontSize={'12px'} color={'#627D98'} sx={{ pt: 2, px: 3, pb: 1 }}>
                    DATE RANGE
                  </Typography>
                  <List sx={{ px: 1 }}>
                    {getDateRange().map((range, key) => (
                      <VendorListItemButton
                        key={key}
                        selected={selectedIndex === key}
                        onClick={event => handleListItemClick(event, key)}
                      >
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Box>
                                <Typography>{range.range}</Typography>
                              </Box>
                              <Box>
                                <Typography>{range.dateVal}</Typography>
                              </Box>
                            </Box>
                          }
                        />
                      </VendorListItemButton>
                    ))}
                  </List>
                </Box>
                <Divider sx={{ pt: 2 }} />
                <Box>
                  <Typography variant="body2" fontSize={'12px'} color={'#627D98'} sx={{ pt: 2, px: 3 }}>
                    CUSTOM RANGE
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pt: 2 }}>
                    <Box>
                      <VendorInput
                        id="enrollmentDate"
                        placeholder={placeholder}
                        value={
                          fromDateFilter ? DateTime.fromISO(fromDateFilter).toLocal().toLocaleString(DateTime.DATE_FULL) : ''
                        }
                        fullWidth
                        onClick={(e: any) => {
                          setEditIconRef(e.currentTarget);
                          setIsFromDatePickerOpen(!isFromDatePickerOpen);
                        }}
                      />
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          disableMaskedInput
                          PopperProps={{
                            placement: 'bottom-end',
                            anchorEl: editIconRef,
                          }}
                          onClose={() => setIsFromDatePickerOpen(false)}
                          open={isFromDatePickerOpen}
                          value={fromDateFilter}
                          onChange={value => {
                            value && setFromDateFilter(value.toString());
                          }}
                          renderInput={() => <></>}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box p={1}>-</Box>
                    <Box>
                      <VendorInput
                        id="toDate"
                        placeholder="To Date"
                        value={
                          toDateFilter ? DateTime.fromISO(toDateFilter).toLocal().toLocaleString(DateTime.DATE_FULL) : ''
                        }
                        fullWidth
                        onClick={(e: any) => {
                          setEditIconRef(e.currentTarget);
                          setIsToDatePickerOpen(!isToDatePickerOpen);
                        }}
                      />
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          disableMaskedInput
                          PopperProps={{
                            placement: 'bottom-end',
                            anchorEl: editIconRef,
                          }}
                          onClose={() => setIsToDatePickerOpen(false)}
                          open={isToDatePickerOpen}
                          value={toDateFilter}
                          onChange={value => {
                            value && setToDateFilter(value.toString());
                          }}
                          renderInput={() => <></>}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

export default InputDate;
