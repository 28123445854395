import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { theme } from '../styles/theme';
import TopNavigation from 'src/components/TopNavigations';

interface IProps {
  children: ReactNode;
}

export default function MainLayout({ children }: IProps) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <TopNavigation />
      <Box sx={{ pt: 10, px: '4rem' }}>{children}</Box>
    </Box>
  );
}
