import { apiClient } from './client';

const getSession = async () => {
  // This forwards the HTTP-Only cookie to the Facade which is used
  // to look-up the session
  const res = await apiClient.get('/auth/sessions');
  return res.data;
};

const login = async (redirectUri: string) => {
  let params: {
    redirectUri?: string;
  } = {};
  if (redirectUri) {
    params.redirectUri = redirectUri;
  }
  const res = await apiClient.get('/auth/login/', { params });
  return res;
};

const logout = async () => {
  const res = await apiClient.get('/auth/logout/');
  return res;
};

const handleCallback = async (accessToken: string) => {
  let params: {
    token?: string;
  } = {};
  if (accessToken) {
    params.token = accessToken;
  }
  const res = await apiClient.get('/auth/callback/', { params });
  return res;
};

export default {
  getSession,
  login,
  logout,
  handleCallback,
};
