import React, { Context, createContext, useState, useEffect } from 'react';
import { IToast, IToastContext } from 'src/customTypes/toast';

const initialToast: IToastContext = {
  isHidden: true,
  color: 'error',
  severity: 'error',
  message: '',
  timeout: 5000,
  setToast: () => {},
};

const useToast = (): IToastContext => {
  const [toast, setToast] = useState<IToast>(initialToast);
  let timer = 0;

  useEffect(() => {
    if (!toast.isHidden) {
      setTimeout(() => {
        setToast(prevState => ({
          ...prevState,
          isHidden: true,
        }));
      }, toast.timeout);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [toast]);

  return { ...toast, setToast };
};

export const ToastContext: Context<IToastContext> = createContext<IToastContext>(initialToast);

export const ToastContextProvider = ({ children }: any) => {
  const toast = useToast();

  return <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>;
};
