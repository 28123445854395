import { useContext, useState } from 'react';
import { SessionContext } from '../../context/SessionContext';
import React from 'react';
import { Box, Button, Typography, styled, buttonClasses, MenuItem, Menu } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StatusChip from './StatusChip';

const VendoButton = styled(Button)(({ theme }) => {
  return {
    [`&.${buttonClasses.root}`]: {
      borderRadius: '24px',
    },
    [`&.${buttonClasses.root}.status_compliant`]: {
      backgroundColor: '#C6F7E2',
      color: '#014D40',
    },
    [`&.${buttonClasses.root}.status_nonCompliant`]: {
      backgroundColor: '#FFBDBD',
      color: '#610316',
    },
    [`&.${buttonClasses.root}.status_pending`]: {
      backgroundColor: '#FCE588',
      color: '#8D2B0B',
    },
    [`&.${buttonClasses.root}.status_missing`]: {
      backgroundColor: '#B3ECFF',
      color: '#035388',
    },
    [`&.${buttonClasses.root}.status_expired`]: {
      backgroundColor: '#B3ECFF',
      color: '#035388',
    },
    [`&.${buttonClasses.root}.status_na`]: {
      backgroundColor: '#9e9e9e',
      color: '#0d0d0d9e',
    },
  };
});

interface StatusDropdownMenuProps {
  status: string | null | undefined;
  isChip?: boolean;
  items?: string[];
  onChange?: (value: string) => void;
  stopPropagation?: boolean;
  disabled?: boolean;
}

export default function StatusDropdownMenu({
  status,
  items,
  onChange,
  isChip,
  stopPropagation,
  disabled,
}: StatusDropdownMenuProps) {
  const { session } = useContext(SessionContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasEditPermission = session.role === 'IDCORE_ADMIN';
  const onSelectStatus = (event: React.MouseEvent<HTMLLIElement>, v: string) => {
    if (!hasEditPermission) return;
    if (stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    onChange && onChange(v);
    setAnchorEl(null);
  };

  function getClass(value: string | null | undefined) {
    const switchStatus = value?.toUpperCase();
    switch (switchStatus) {
      case 'COMPLIANT':
        return 'status_compliant';
      case 'NON-COMPLIANT':
        return 'status_nonCompliant';
      case 'PENDING':
        return 'status_pending';
      case 'MISSING':
        return 'status_missing';
      case 'EXPIRED':
        return 'status_expired';
      case 'N/A':
        return 'status_na';
    }
  }

  if (!hasEditPermission || isChip) {
    return <StatusChip status={status} isPopup />;
  }

  return (
    <div>
      <VendoButton
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className={getClass(status)}
        disabled={disabled}
      >
        {status}
      </VendoButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items &&
          items.map(item => {
            const switchStatus = item?.toUpperCase();
            switch (switchStatus) {
              case 'COMPLIANT':
                return (
                  <MenuItem key={item} onClick={(e: any) => onSelectStatus(e, item)} disableRipple>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ width: 6, height: 6, bgcolor: '#27AB83', marginTop: '8px', marginRight: '5px' }}></Box>
                      <Typography>Compliant</Typography>
                    </Box>
                  </MenuItem>
                );
              case 'NON-COMPLIANT':
                return (
                  <MenuItem key={item} onClick={(e: any) => onSelectStatus(e, item)} disableRipple>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ width: 6, height: 6, bgcolor: '#AB091E', marginTop: '8px', marginRight: '5px' }}></Box>
                      <Typography>Non-compliant</Typography>
                    </Box>
                  </MenuItem>
                );
              case 'PENDING':
                return (
                  <MenuItem key={item} onClick={(e: any) => onSelectStatus(e, item)} disableRipple>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ width: 6, height: 6, bgcolor: '#F0B429', marginTop: '8px', marginRight: '5px' }}></Box>
                      <Typography>Pending</Typography>
                    </Box>
                  </MenuItem>
                );
              case 'MISSING':
                return (
                  <MenuItem key={item} onClick={(e: any) => onSelectStatus(e, item)} disableRipple>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ width: 6, height: 6, bgcolor: '#127FBF', marginTop: '8px', marginRight: '5px' }}></Box>
                      <Typography>Missing</Typography>
                    </Box>
                  </MenuItem>
                );
              case 'N/A':
                return (
                  <MenuItem key={item} onClick={(e: any) => onSelectStatus(e, item)} disableRipple>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ width: 6, height: 6, bgcolor: '#9e9e9e', marginTop: '8px', marginRight: '5px' }}></Box>
                      <Typography>N/A</Typography>
                    </Box>
                  </MenuItem>
                );
            }
          })}
      </Menu>
    </div>
  );
}
