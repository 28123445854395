import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  ClickAwayListener,
  Fade,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIsMobile } from 'src/hooks/useIsMobile';

interface IMenuProps {
  defaultValues: IOptionProps[];
  options: any;
  onSelected: (event: IOptionProps, selected: any) => void;
}

const SelectMenu = ({ options, defaultValues, onSelected }: IMenuProps) => {
  const isMobile = useIsMobile();
  const [dataValue, setDataValue] = useState(defaultValues);

  useEffect(() => {
    setDataValue(defaultValues);
  }, [defaultValues]);

  return (
    <>
      {Object.keys(options).map((categoryName: any, catIndex: number) => {
        const catkey = categoryName.split(' ').at(0);
        return (
          <Box key={catIndex + catkey}>
            <ListSubheader sx={{ fontWeight: 800 }}>{categoryName}</ListSubheader>
            {options[categoryName].map((opt: IOptionProps, i: number) => {
              return (
                <MenuItem key={catIndex + catkey + i} value={opt.id} onClick={e => onSelected(opt, dataValue)}>
                  <Checkbox
                    checked={defaultValues.map(e => e.id).indexOf(opt.id) > -1}
                    onClick={e => onSelected(opt, dataValue)}
                  />
                  <ListItemText primary={opt.name}></ListItemText>
                </MenuItem>
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

const TextFieldInput = styled(TextField)(({ theme }) => ({
  [`&.MuiTextField-root`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #2729371F',
    borderRadius: '8px',
  },
  '& .MuiInputBase-input': {
    padding: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const AutocompleteInput = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root} .${autocompleteClasses.inputRoot}`]: {
    padding: '8px',
    maxHeight: '48px',
  },
  [`&.added.${autocompleteClasses.root} .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}::placeholder`]: {
    color: 'transparent',
  },
}));

const useStyles = makeStyles({
  noOptions: {
    display: 'none',
  },
});

interface IOptionProps {
  id: string;
  name: string;
  categoryId: string;
}

interface IProps {
  name: string;
  defaultValues: IOptionProps[];
  options: any;
  control: any;
  rules?: any;
  label?: string;
  placeholder?: string;
}

const InputMultiSelect = ({ name, defaultValues, options, control, rules, label, placeholder }: IProps) => {
  const isMobile = useIsMobile();
  const [selectedValues, setSelectedValues] = useState<any[]>(defaultValues);
  const autoRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [optionValues, setOptionValues] = useState<IOptionProps[]>(options);
  const [width, setWidth] = useState(0);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const autoStyles = useStyles();

  useLayoutEffect(() => {
    const current = autoRef?.current as any;
    setWidth(current?.offsetWidth);
  }, []);

  let formOnChange: any = null;
  let onSelected = (value: any, selected: any[]) => {
    if (selected.map(e => e.id).indexOf(value.id) > -1) {
      const data = selected.filter(function (e: any) {
        return e.id !== value.id;
      });
      setSelectedValues(data);
      formOnChange(data);
    } else {
      const data = [...selected, value];
      setSelectedValues(data);
      formOnChange(data);
    }
  };

  useEffect(() => {
    setSelectedValues(selectedValues ? selectedValues : defaultValues ?? []);
    setOptionValues(options ?? {});
  }, [defaultValues, options]);

  return (
    <>
      {!isMobile && (
        <InputLabel htmlFor={name} sx={{ pb: '8px' }}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={selectedValues}
        render={({ field: { onChange, value } }) => {
          formOnChange = onChange;
          return (
            <AutocompleteInput
              ref={autoRef}
              multiple
              fullWidth
              options={[]}
              value={value}
              onOpen={(event: any) => {
                setOpen(!open);
                setAnchorEl(autoRef.current);
              }}
              onInputChange={(event: any) => {
                var value = event.currentTarget.value;
                if (value) {
                  let filter: any = {};
                  Object.keys(optionValues).map(categoryName => {
                    // @ts-ignore
                    var filtered = optionValues[categoryName].filter((v: any) =>
                      v.name.toLowerCase().includes(value.toLowerCase())
                    );
                    if (filtered.length > 0) filter[categoryName] = filtered;
                  });
                  setOpen(true)

                  // var filtered = optionValues.filter((v: any) => v.name.toLowerCase().includes(value.toLowerCase()));
                  setOptionValues(filter);
                } else {
                  setOptionValues(options);
                }
              }}
              onChange={(event, value, reason) => {
                if (reason === 'clear') {
                  setSelectedValues([]);
                  formOnChange([]);
                  setOptionValues(options);
                  setOpen(false);
                }
              }}
              // onFocus={event => {
              //   setFocus(true);
              // }}
              // onBlur={event => {
              //   setFocus(false);
              // }}
              renderInput={params => <TextFieldInput placeholder="Select Services" {...params} />}
              renderTags={(value: readonly any[], getTagProps) => {
                if (value.length > 0) {
                  return (
                    <Box sx={{ overflow: 'hidden', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Chip variant="outlined" label={value[0].name} {...getTagProps} sx={{ maxWidth: '90px' }} />
                      {value[1] && <Chip variant="outlined" label={value[1]?.name} {...getTagProps} sx={{ maxWidth: '90px' }} />}
                      {value.length > 2 && (
                        <Typography pl={0.5} >
                          {'+' + (value.length -2) + ' more'}
                        </Typography>
                      )}
                    </Box>
                  );
                }
                return <Typography sx={{ color: '#9E9E9E' }}></Typography>;
              }}
              inputMode="search"
              classes={{
                noOptions: autoStyles.noOptions,
              }}
              className={selectedValues.length > 0 ? 'added' : ''}
            />
          );
        }}
      />

      <Popper
        open={open}
        anchorEl={anchorEl}
        disablePortal={false}
        placement={isMobile ? 'bottom' : 'bottom-end'}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (open && !focus) setOpen(false);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{ bgcolor: '#FFF' }}
                style={{
                  marginTop: 3,
                  borderBottomRightRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  border: '1px solid #D9E2EC',
                  width: width + 200,
                  maxHeight: '444px',
                }}
              >
                <Box
                  sx={{
                    maxHeight: '380px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    pt: 2,
                  }}
                >
                  <SelectMenu defaultValues={selectedValues} options={optionValues} onSelected={onSelected} />
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default InputMultiSelect;
