import z from 'zod';

export const serviceSchema = z.object({
  vendorServices: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    })
  ),
  riskLevel: z.string(),
});

export const risklevelsSchema = z.object({
  name: z.string(),
  categoryId: z.string(),
  categoryName: z.string(),
  pmcId: z.string(),
  id: z.string(),
  riskLevel: z.string(),
});
