import { Box, Button, Grid, Typography } from '@mui/material';
import VendorGrid from 'src/components/Vendor/Grid';
import PageLayout from 'src/layouts/PageLayout';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from 'src/context/SessionContext';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import StatusChip from 'src/components/Vendor/StatusChip';
import { getExpiringStatus } from 'src/helpers/vendor';
import { DateTime } from 'luxon';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import api from 'src/api';
import YourVendorSearch from 'src/components/Vendor/Search/YourVendor';
import { useForm } from 'react-hook-form';
import { IYourSearchInput } from 'src/customTypes/vendor';

export default function YourVendorPage() {
  const { session } = useContext(SessionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  // Search
  const { control, watch, reset, getValues } = useForm<IYourSearchInput>({
    defaultValues: {
      id: '',
      name: '',
      services: [],
      status: [],
      enrollmetnDate: {
        from: undefined,
        to: DateTime.now(),
        label: '',
      },
      complianceDate: {
        from: undefined,
        to: DateTime.now(),
        label: '',
      },
      state: '',
      zip: '',
      radius: '',
    },
    mode: 'onChange',
  });

  // Grid
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    date: false,
    complianceDate: false,
  });
  const DefaultStatus =
    session.role === 'IDCORE_ADMIN'
      ? ['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'EXPIRED', 'INELIGIBLE']
      : ['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'EXPIRED'];
  const defaultSort = { column: 'subscriptionStartDate', direction: -1 };
  const [filter, setFilter] = useState({
    status: DefaultStatus,
    filters: {} as any,
    sort: defaultSort,
    paging: { offset: 10, limit: 1 },
  });
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hideable: true },
    {
      field: 'vendor',
      headerName: 'VENDOR',
      flex: 1,
      minWidth: 200,
      sortable: true,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <span
          style={{
            paddingLeft: 24,
          }}
          title={params.colDef.headerName}
        >
          {params.colDef.headerName}
        </span>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: 24,
              fontSize: '16px',
            }}
            title={params.value}
          >
            {params.value}
          </span>
          <br></br>
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: 24,
              fontWeight: 400,
              fontSize: '14px',
              color: '#6E6E6E',
            }}
          >
            ID: {params.row.referenceId}
          </span>
        </Box>
      ),
    },
    {
      field: 'service',
      headerName: 'SERVICE',
      flex: 1,
      minWidth: 220,
      sortable: false,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <span
          style={{
            paddingLeft: 24,
          }}
          title={params.colDef.headerName}
        >
          {params.colDef.headerName}
        </span>
      ),
      valueFormatter: (params: GridValueFormatterParams<any>) => `${params.value?.map((v: any) => v?.name)}`,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Box>
          {params.value && params.value.length > 0 ? (
            <Box>
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingLeft: 24,
                  fontSize: '16px',
                }}
                title={params.value[0].name}
              >
                {params.value[0].name}
              </span>
              {params.value.length > 1 && (
                <span>
                  <br></br>
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      paddingLeft: 24,
                      fontWeight: 400,
                      fontSize: '16px',
                      color: '#6E6E6E',
                    }}
                  >
                    + {params.value.length - 1} other services
                  </span>
                </span>
              )}
            </Box>
          ) : (
            ''
          )}
        </Box>
      ),
    },
    {
      field: 'location',
      headerName: 'LOCATION',
      flex: 1,
      minWidth: 100,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
          title={params.value}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'date',
      headerName: 'EXP DATE',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      minWidth: 130,
      sortable: true,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params?.value ? DateTime.fromFormat(params?.value, 'MM/dd/yyyy').toLocal().toFormat('dd, MMM, yyyy') : '';
      },
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
          title={params.value}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'complianceDate',
      headerName: 'COMP DATE',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      minWidth: 140,
      sortable: true,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params?.value ? DateTime.fromFormat(params?.value, 'MM/dd/yyyy').toLocal().toFormat('dd, MMM, yyyy') : '';
      },
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
          title={params.value}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'resmanAbb',
      headerName: 'Resman Link',
      flex: 1,
      minWidth: 50,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <a href={`https://resprop.myresman.com/#/Vendors/Detail/${params.row.other.resmanId}`}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
          title={params.value}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1,
      minWidth: 180,
      sortable: true,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        {
          let status = getExpiringStatus(params?.value, params.row.other.overallPolicyExpiration);
          return <StatusChip status={status} />;
        }
      },
    },
  ];

  function getFilter() {
    const filters = { ...filter };
    filters.filters = filter.filters;
    filters.filters.enrollmentStatus = ['COMPLETED', 'IN-PROGRESS', 'IN_PROGRESS'];

    const filtered =
      !!filter.filters.vendorName ||
      !!filter.filters.status?.length ||
      !!filter.filters.service?.length ||
      !!filter.filters.zipCode?.length;

    if (filtered || filters.filters.enrollmentStatus) {
      filters.sort = defaultSort;
      return filters;
    }
  }

  async function downloadCsv() {
    try {
      let blob: Blob;
      const filters: any = getFilter();
      const res = await api.vendors.downloadVendors(filters);
      blob = new Blob([res]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${DateTime.now().toFormat('MM/dd/yyyy')}_your-vendors_export.csv`;
      link.click();
      link.remove();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PageLayout pageTitle="Property Details">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YourVendorSearch
            filter={filter}
            setFilter={setFilter}
            control={control}
            watch={watch}
            reset={reset}
            getValues={getValues}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              py={4}
              sx={{
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              <Typography variant="h4">Results ({count})</Typography>
            </Box>

            <Button
              variant="text"
              onClick={downloadCsv}
              startIcon={<FileDownloadIcon></FileDownloadIcon>}
              sx={{ color: '#127FBF' }}
            >
              <Typography
                pl={1}
                fontSize={20}
                fontWeight={500}
                sx={{ color: '#127FBF' }}
                textTransform={'capitalize'}
              >
                Export Data
              </Typography>
            </Button>
          </Box>
          <VendorGrid columns={columns} filterApplied={filter} setCount={setCount} setVisibility={visibilityModel} />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
