import { Context, createContext, useState, Dispatch, SetStateAction } from 'react';

// Defines the interface for storing/accessing a Invited
interface IInvitedStorage {
	invited: string[];
	setInvited: Dispatch<SetStateAction<string[]>>;
}

const initialInvitedStorage: IInvitedStorage = {
	invited: [] as string[],
	setInvited: () => {}
};

// Define the Invited state and all fn's able to access the Invited storage
const useInvited = (): IInvitedStorage => {
	const [invited, setInvited] = useState([] as string[]);

	return { invited, setInvited };
};

// Creates a React Context object for storing Invited information
export const InvitedContext: Context<IInvitedStorage> = createContext(initialInvitedStorage);

// Creates the InvitedContextProvider which exposes the Invited storage to the rest of the react app
export const InvitedContextProvider = ({ children }: any) => {
	const Invited = useInvited();
  
  return <InvitedContext.Provider value={Invited}>{children}</InvitedContext.Provider>;
};
