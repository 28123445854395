import { Box, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import VendorTabPanel from './VendorTabPanel';
import VendoAccordion from '../Accordion';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  vendorId: string;
  isHidden: boolean;
  isLoading?: boolean;
  files: Array<any>;
  setFiles: (files: Array<any>) => void;
  handleUploadOpen: (isOpen: boolean, isOther?: boolean) => void;
  setIsFileReload: (isReload: boolean) => void;
};

export default function OtherTab({ vendorId, isHidden, files, setFiles, handleUploadOpen, setIsFileReload }: Props) {
  const scroller = useRef(null);

  useEffect(() => {}, [isHidden]);

  return (
    <VendorTabPanel isHidden={isHidden}>
      <Box
        ref={scroller}
        hidden={isHidden}
        sx={{
          maxHeight: 405,
          overflowY: 'auto',
          scrollbarColor: '#d9e2ec #fff',
        }}
      >
        <VendoAccordion
          title="Other Uploads"
          id={vendorId}
          fileUrls={files.filter(x => x.type == 'other')}
          setFileUrls={setFiles}
          isDelete
          setIsFileReload={setIsFileReload}
          isExpand
        ></VendoAccordion>
        <VendoAccordion
          title="Archive Uploads"
          id={vendorId}
          fileUrls={files.filter(x => x.type == 'archive')}
          setFileUrls={setFiles}
          isEven
          isDelete={false}
          setIsFileReload={setIsFileReload}
        ></VendoAccordion>
      </Box>

      <Box
        sx={{
          borderTop: '1px solid #BCCCDC',
          p: 2,
          color: '#035388',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer', maxWidth: 200 }}
          onClick={() => handleUploadOpen(false, true)}
        >
          <AddIcon></AddIcon>
          <Typography color={'#035388'} fontWeight={700}>
            Upload a File
          </Typography>
        </Box>
      </Box>
    </VendorTabPanel>
  );
}
