import { Box, Chip, CircularProgress, Icon, IconButton, Typography } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { SessionContext } from 'src/context/SessionContext';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const LoadingBar = ({}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
      <CircularProgress />
    </Box>
  );
};

type Props = {
  isLoading?: boolean;
  title: string;
  onEdit?: () => void;
  info: {
    name?: string;
    value?: string | undefined;
    tooltip?: boolean | undefined;
    hiddenValue?: string | null;
    isHidden?: boolean;
    description?: string;
    file?: {
      label: string;
      url: string;
    };
    chip?: {
      label: string;
    };
    chips?: {
      label: string;
    }[];
    object?: {
      value1: string;
      value2: string;
    }[];
  }[];
};

export default function InfoBlock({ title, info, isLoading, onEdit }: Props) {
  const [blockInfo, setBlockInfo] = useState(info);
  const [loading, setLoading] = useState<boolean>(false);
  const { session } = useContext(SessionContext);
  const isAdmin = session.role === 'IDCORE_ADMIN';

  useEffect(() => {
    setBlockInfo(info);
    setLoading(isLoading ? true : false);
  }, [info, isLoading]);

  if (loading) {
    return (
      <Box p={4}>
        <LoadingBar></LoadingBar>
      </Box>
    );
  }

  return (
    <Box sx={{ px: 2 }}>
      {title && (
        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <Typography variant="body1" fontWeight={700} pl={1}>
            {title}
          </Typography>
          <IconButton sx={{ ml: 1, p: 0 }} onClick={onEdit}>
            <ModeEditIcon sx={{ color: '#127FBF' }}></ModeEditIcon>
          </IconButton>
        </Box>
      )}
      {blockInfo.map((item, key) => (
        <Fragment key={key}>
          {!item.chips && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1.5, minWidth: '180px' }}>
                <Typography variant="body1" color={'#6E6E6E'} pl={1}>
                  {item.name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1.5 }}>
                {item.chip && item.chip.label ? (
                  <Box pt={1}>
                    <Chip
                      label={item.chip.label}
                      sx={{
                        color: '#486581',
                        bgcolor: '#D9E2EC',
                        fontSize: 14,
                        fontWeight: 700,
                        borderRadius: '4px',
                      }}
                    />
                  </Box>
                ) : (
                  <Typography variant="body1" color={'#2C2C2C'} pl={1} textAlign={'right'}>
                    {item.value}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {item.chips && (
            <Box pt={2}>
              {item.chips.map((s: any, key: number) => (
                <Chip
                  key={key}
                  label={s.label}
                  sx={{
                    color: '#334E68',
                    bgcolor: '#D9E2EC',
                    fontSize: 14,
                    fontWeight: 400,
                    borderRadius: '16px',
                    mr: 1,
                    mb: 1,
                  }}
                />
              ))}
            </Box>
          )}
        </Fragment>
      ))}
    </Box>
  );
}
