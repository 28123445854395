import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import VendorTabPanel from './VendorTabPanel';
import VendorChats from '../VendorChats';
import { VendoTabsItem } from './VendoTabsItem';
import { TabPanel } from './TabPanel';

type Props = {
  isHidden: boolean;
  vendorId: string;
  isLoading?: boolean;
};

export default function MessagesTab({ isHidden, vendorId }: Props) {
  const [messageTabValue, setMessageTabValue] = useState(0);
  const handleMessageTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMessageTabValue(newValue);
  };

  useEffect(() => {}, []);

  return (
    <VendorTabPanel isHidden={isHidden}>
      <Box pt={2}>
        <VendoTabsItem value={messageTabValue} onChange={handleMessageTabChange} centered className={'msg'}>
          <Tab label="Internal Messaging" />
          <Tab label="External Messages" />
        </VendoTabsItem>
        <TabPanel index={0} value={messageTabValue}>
          <VendorChats vendorId={vendorId} type="internal"></VendorChats>
        </TabPanel>
        <TabPanel index={1} value={messageTabValue}>
          <VendorChats vendorId={vendorId} type="external"></VendorChats>
        </TabPanel>
      </Box>
    </VendorTabPanel>
  );
}
