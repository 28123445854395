import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import VendorTabPanel from './VendorTabPanel';
import EditBlock from '../EditBlock';
import InfoBlock from '../InfoBlock';
import api from 'src/api';
import { SessionContext } from 'src/context/SessionContext';
import { ITaxClassfication } from 'src/customTypes/taxCodes';
import { getTextClassificationLabel } from 'src/helpers/taxCodes';
import { DateTime } from 'luxon';
import regex from 'src/helpers/regex';
import { STATES } from 'src/contants/staticOptions';

type Props = {
  isHidden: boolean;
  vendorData: any;
  setVendorData: (data: any) => void;
  isLoading?: boolean;
  setIsLoading: (loading: boolean) => void;
  bgStatus?: string;
  bgCheck: any;
  setBgCheck: (status: any) => void;
};

export default function DetailsTab({
  isHidden,
  vendorData,
  setVendorData,
  isLoading,
  setIsLoading,
  bgStatus,
  bgCheck,
  setBgCheck,
}: Props) {
  const { session } = useContext(SessionContext);
  const isAdmin = session.role === 'IDCORE_ADMIN';
  const [isShow, setIsShow] = useState<boolean>(!isHidden);

  const [taxClassifications, setTaxClassifications] = useState<ITaxClassfication[]>([]);
  const [isEditingBgInfo, setIsEditingBgInfo] = useState(false);
  const [isEditingW9, setIsEditingW9] = useState(false);
  //const [bgCheck, setBgCheck] = useState<any>({});

  const { streetAddress, city, state, zipCode } = bgCheck?.subjectInfo ?? {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
  };

  const handleBgSubmit = (data: any) => {
    if (bgCheck?.id) {
      setIsLoading(true);
      const transofrmedData = {
        subjectInfo: {
          ...data,
          state: data?.state?.id,
        },
      };
      api.backgroundChecks
        .updateBgCheckInfo(bgCheck?.id, transofrmedData)
        .then(res => {
          if (res) {
            if (res?.subjectInfo) {
              setBgCheck(res);
            }
            setIsEditingBgInfo(prevState => !prevState);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleW9Submit = (data: {}) => {
    setIsLoading(true);
    const d: any = data;
    const newData = {
      businessName: d.name,
      dba: d.dba,
      federalTaxClassification: d.tax.id,
      address: d.street + ', ' + d.city + ', ' + d.state + ',' + d.zip.toString(),
      tinType: d.tinType.id,
      tin: d.tin,
    };
    (async () => {
      try {
        const res = await api.vendors.addUpdateW9info(vendorData._id, {...vendorData.w9, ...newData});
        vendorData.w9 = { ...vendorData.w9, ...res.data.w9 };
        setVendorData(vendorData);
      } catch (reason) {
        console.error('Error saving w9 data', reason);
      } finally {
        setIsLoading(false);
        setIsEditingW9(prevState => !prevState);
      }
    })();
  };

  const updateBgStatusHandler = (status: string) => {
    if (!bgCheck?.id) {
      // If an admin is changing the status of the record and we don't have one we need to create a Bg Check record
      api.backgroundChecks
        .createBgCheck(vendorData._id, status)
        .then(res => {
          setBgCheck(res);
        })
        .catch(err => {
          console.log('vendorData errs', vendorData._id, status);
          alert('Failed to update status');
        });
    } else {
      api.backgroundChecks
        .updateBgCheckStatus(bgCheck.id, status, session.details.name)
        .then(res => {
          setBgCheck({ ...bgCheck, status });
        })
        .catch(err => {
          alert('Failed up update status');
          console.error(err);
        });
    }
  };

  useEffect(() => {
    setIsShow(!isHidden);
  }, [isHidden]);

  useEffect(() => {
    if (isShow) {
      if (taxClassifications.length === 0) {
        api.taxCodes.getTaxClassifications().then(list => {
          setTaxClassifications(list.data);
        });
      }
    }
  }, [isShow]);

  useEffect(() => {
    if (!isShow && bgStatus) {
      updateBgStatusHandler(bgStatus);
    }
  }, [bgStatus, isShow]);

  return (
    <VendorTabPanel isHidden={!isShow}>
      <Box p={2}>
        {isEditingW9 && (
          <EditBlock
            title={'W9 Information'}
            onSubmit={data => {
              handleW9Submit(data);
            }}
            onCancel={() => {
              setIsEditingW9(false);
            }}
            fields={[
              {
                label: 'Name:',
                name: 'name',
                value: vendorData.w9?.businessName,
                type: 'text',
              },
              {
                label: 'DBA:',
                name: 'dba',
                value: vendorData.w9?.dba,
                type: 'text',
              },
              {
                label: 'Tax Classification:',
                name: 'tax',
                type: 'single-select',
                options: taxClassifications.map(value => {
                  return { id: value.id, label: value.name };
                }),
                optionValue: [
                  {
                    id: vendorData.w9?.federalTaxClassification || '',
                    label:
                      getTextClassificationLabel(taxClassifications, vendorData.w9?.federalTaxClassification || '') || '',
                  },
                ],
              },
              {
                label: 'Street:',
                name: 'street',
                value: vendorData.w9?.address?.split(',')[0]?.trim(),
                type: 'text',
              },
              {
                label: 'City:',
                name: 'city',
                value: vendorData.w9?.address?.split(',')[1]?.trim(),
                type: 'text',
              },
              {
                label: 'State:',
                name: 'state',
                value: vendorData.w9?.address?.split(',')[2]?.trim(),
                type: 'text',
              },
              {
                label: 'Zip:',
                name: 'zip',
                value: vendorData.w9?.address?.split(',')[3]?.trim(),
                type: 'text',
              },
              {
                label: 'Tin Type:',
                name: 'tinType',
                type: 'single-select',
                optionValue: [{ id: vendorData.w9?.tinType || '', label: vendorData.w9?.tinType || '' }],
                options: [
                  { id: 'SSN', label: 'SSN' },
                  { id: 'EIN', label: 'EIN' },
                ],
              },
              {
                label: 'Tin:',
                name: 'tin',
                value: vendorData.w9?.tin,
                type: 'number',
                rules: { minLength: 9, maxLength: 9 },
              },
            ]}
          />
        )}
        {!isEditingW9 && (
          <InfoBlock
            title={'W9 Information'}
            onEdit={
              isAdmin
                ? () => {
                    setIsEditingW9(true);
                  }
                : undefined
            }
            isLoading={isLoading}
            info={[
              {
                name: 'Name:',
                value: vendorData.w9?.businessName ?? ' ',
              },
              {
                name: 'DBA:',
                value: vendorData.w9?.dba ?? ' ',
              },
              {
                name: 'Tax Classification:',
                value: vendorData.w9?.federalTaxClassification
                  ? getTextClassificationLabel(taxClassifications, vendorData.w9.federalTaxClassification)
                  : '',
              },
              {
                name: 'Tin Type:',
                value: vendorData.w9?.tinType ?? ' ',
              },
              {
                name: 'Tin:',
                value: vendorData.w9?.tin ?? ' ',
              },
            ]}
          />
        )}
        <Box pt={2}>
          {isEditingBgInfo && (
            <EditBlock
              title={'Background Check Information'}
              onSubmit={data => {
                handleBgSubmit(data);
              }}
              onCancel={() => {
                setIsEditingBgInfo(false);
              }}
              fields={[
                {
                  label: 'First Name:',
                  name: 'firstName',
                  value: bgCheck?.subjectInfo.firstName,
                  type: 'text',
                  rules: { required: true },
                },
                {
                  label: 'Middle Name:',
                  name: 'middleName',
                  value: bgCheck?.subjectInfo.middleName,
                  type: 'text',
                },
                {
                  label: 'Last Name:',
                  name: 'lastName',
                  value: bgCheck?.subjectInfo.lastName,
                  type: 'text',
                  rules: { required: true },
                },
                {
                  label: 'Date of Birth:',
                  name: 'dob',
                  value: bgCheck?.subjectInfo.dob,
                  type: 'date-picker',
                  rules: { required: true, maxDate: DateTime.now().minus({ years: 18 }) },
                },
                {
                  label: 'Street Address:',
                  name: 'streetAddress',
                  value: bgCheck?.subjectInfo.streetAddress,
                  type: 'text',
                  rules: { required: true, pattern: regex.NO_PIPES },
                },
                {
                  label: 'City:',
                  name: 'city',
                  value: bgCheck?.subjectInfo.city,
                  type: 'text',
                  rules: { required: true, pattern: regex.NO_PIPES },
                },
                {
                  label: 'State:',
                  name: 'state',
                  type: 'single-select',
                  options: STATES,
                  optionValue: [
                    {
                      id: bgCheck?.subjectInfo.state,
                      label:
                        STATES.find(item => item.id === bgCheck?.subjectInfo.state)?.label ?? bgCheck?.subjectInfo.state,
                    },
                  ],
                  rules: { required: true, pattern: regex.UPPERCASE_ONLY, minLength: 2, maxLength: 2 },
                },
                {
                  label: 'ZipCode:',
                  name: 'zipCode',
                  value: bgCheck?.subjectInfo.zipCode,
                  type: 'text',
                  rules: { required: true, pattern: regex.NO_PIPES },
                },
                {
                  label: 'SSN:',
                  name: 'ssn',
                  value: bgCheck?.subjectInfo.ssn,
                  type: 'ssn',
                  rules: { required: true },
                },
              ]}
            />
          )}
          {!isEditingBgInfo && (
            <InfoBlock
              title={'Background Check Information'}
              onEdit={
                isAdmin
                  ? () => {
                      setIsEditingBgInfo(true);
                    }
                  : undefined
              }
              isLoading={isLoading}
              info={[
                {
                  name: 'Full Name:',
                  value: `${bgCheck?.subjectInfo?.firstName ?? ''} ${bgCheck?.subjectInfo?.middleName ?? ''} ${
                    bgCheck?.subjectInfo?.lastName
                  }`,
                },
                {
                  name: 'Date of Birth:',
                  value: bgCheck?.subjectInfo?.dob
                    ? DateTime.fromISO(bgCheck?.subjectInfo?.dob).toFormat('MM/dd/yyyy')
                    : ' ',
                },
                {
                  name: 'Address:',
                  value: [streetAddress, city, state, zipCode].filter(i => i).join(', ') || ' ',
                },
                {
                  name: 'SSN:',
                  value: bgCheck?.subjectInfo?.ssn
                    ? bgCheck.subjectInfo.ssn.slice(0, -4).replace(/\d/g, 'X') + bgCheck.subjectInfo?.ssn.slice(-4)
                    : ' ',
                  hiddenValue: isAdmin && bgCheck?.subjectInfo?.ssn ? bgCheck.subjectInfo.ssn : null,
                },
              ]}
            />
          )}
        </Box>
      </Box>
    </VendorTabPanel>
  );
}
