import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { SessionContextProvider } from './context/SessionContext';
import { InvitedContextProvider } from './context/InviteContext';
import { ToastContextProvider } from './context/ToastContext';
import * as FullStory from '@fullstory/browser';

const container = document.getElementById('root');
const root = createRoot(container!);

FullStory.init(
	{
	  orgId: 'o-1CZ4ZR-na1',
	  recordCrossDomainIFrames: true,
	  devMode: window.location.hostname !== 'pm.idcore.com',
	},
	({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
  );

root.render(
  <SessionContextProvider>
    <InvitedContextProvider>
      <ToastContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ToastContextProvider>
    </InvitedContextProvider>
  </SessionContextProvider>
);
