import { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../api';
import {
  Box,
  Button,
  Grid,
  Typography,
  styled,
  linearProgressClasses,
  LinearProgress,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import { theme } from '../../../styles/theme';
import { useDropzone } from 'react-dropzone';
import { ToastContext } from 'src/context/ToastContext';
import { ReactComponent as UploadIcon } from '../../../svg/upload-icon.svg';
import { IPolicy, InsurancePolicyTypes } from 'src/customTypes/insurance';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { DateTime } from 'luxon';
import Toast from 'src/components/Toast/Toast';
import { SessionContext } from 'src/context/SessionContext';

const LoadingBar = ({}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
      <CircularProgress />
    </Box>
  );
};

const VendoSelect = styled(Select)(({ theme }) => {
  return {
    ['& .MuiOutlinedInput-notchedOutline']: {
      border: 'none',
    },
  };
});

interface DocUploadProps {
  vendorId: string;
  isPolicy: boolean;
  isOther: boolean;
  vendorGeneralLiability: IPolicy | null;
  setVendorGeneralLiability: (policy: IPolicy) => void;
  vendorAutoLiability: IPolicy | null;
  setVendorAutoLiability: (policy: IPolicy) => void;
  vendorExcessLiability: IPolicy | null;
  setVendorExcessLiability: (policy: IPolicy) => void;
  vendorWorkerComp: IPolicy | null;
  setVendorWorkerComp: (policy: IPolicy) => void;
  onSubmit: () => void;
}

export default function DocUpload({
  vendorId,
  isPolicy,
  isOther,
  vendorGeneralLiability,
  setVendorGeneralLiability,
  vendorAutoLiability,
  setVendorAutoLiability,
  vendorExcessLiability,
  setVendorExcessLiability,
  vendorWorkerComp,
  setVendorWorkerComp,
  onSubmit,
}: DocUploadProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadOptionFilter, setUploadOptionFilter] = useState<string[]>([]);
  const [files, setFiles] = useState<object[]>([]);
  const { setToast } = useContext(ToastContext);
  const [isUploadAnother, setIsUploadAnother] = useState<boolean>(false);
  const { session } = useContext(SessionContext);

  const onUploadOptionChanged = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : (value as string[]);
    setUploadOptionFilter(selected);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isPolicy) uploadPolicyFile(acceptedFiles);
      else if (isOther) uploadOtherFile(acceptedFiles);
      else uploadFile(acceptedFiles);
    },
    [uploadOptionFilter]
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    multiple: false,
  });

  const uploadPolicyFile = async (uploadedFile: File[]) => {
    setIsLoading(true);
    const file = uploadedFile[0];
    let uploaded: any = [];
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    uploaded = [...uploaded, { name: file.name, size: fileSize }];
    var insuranceInfo = await checkInsureproCOI(file);
    let isUplaoded = false;
    if (insuranceInfo && insuranceInfo.Extracted) {
      try {
        const allPoliy = await saveInsuranceInfo(insuranceInfo, vendorId);
        const acticities: any[] = [];
        await api.vendors.getFileUploadUrl(vendorId, file.name, session.details.name).then(async coiFileRes => {
          await fetch(coiFileRes, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type,
            },
          }).then(async res => {
            if (allPoliy.generalPolicy.policyId) acticities.push(getActivity('coi', file.name, 'General Liability'));
            if (allPoliy.autoPolicy.policyId) acticities.push(getActivity('coi', file.name, 'Auto Liability'));
            if (allPoliy.excessPolicy.policyId) acticities.push(getActivity('coi', file.name, 'Excess Liability'));
            if (allPoliy.wcPolicy.policyId) acticities.push(getActivity('coi', file.name, 'Workers Comp'));
            await onActivitySubmit(acticities);
            isUplaoded = true;
          });
        });
        setFiles([...files, ...uploaded]);
        setIsLoading(false);
        setIsUploadAnother(false);
        if (allPoliy.isValid) {
          setToast((prevState: any) => ({
            ...prevState,
            isHidden: false,
            message: 'File Uploaded successfully!',
            color: 'success',
            severity: 'success',
          }));
        } else {
          setToast((prevState: any) => ({
            ...prevState,
            isHidden: false,
            message: 'File Uploaded successfully with COI Extract Errors! Please enter the invalid data manually.',
            color: 'error',
            severity: 'error',
            timeout: 60000,
          }));
        }
        if (allPoliy.generalPolicy.policyId) setVendorGeneralLiability(allPoliy.generalPolicy);
        if (allPoliy.autoPolicy.policyId) setVendorAutoLiability(allPoliy.autoPolicy);
        if (allPoliy.excessPolicy.policyId) setVendorExcessLiability(allPoliy.excessPolicy);
        if (allPoliy.wcPolicy.policyId) setVendorWorkerComp(allPoliy.wcPolicy);
      } catch (err) {
        console.error(err);
        if (!isUplaoded) {
          await uploadCOI(file, uploaded);
        } else {
          setIsLoading(false);
        }
      }
    } else {
      await uploadCOI(file, uploaded);
    }
  };

  async function uploadCOI(file: File, uploaded: any[]) {
    console.error('error extract ML', uploadOptionFilter);

    for (const value of uploadOptionFilter) {
      switch (value) {
        case 'General Liability':
          await uploadPolicy(
            vendorGeneralLiability,
            setVendorGeneralLiability,
            InsurancePolicyTypes.GENERAL_LIABILITY,
            file
          );
          break;
        case 'Auto Liability':
          await uploadPolicy(vendorAutoLiability, setVendorAutoLiability, InsurancePolicyTypes.AUTO_LIABILITY, file);
          break;
        case 'Excess Liability':
          await uploadPolicy(vendorExcessLiability, setVendorExcessLiability, InsurancePolicyTypes.EXCESS_LIABILITY, file);
          break;
        case 'Workers Comp':
          await uploadPolicy(vendorWorkerComp, setVendorWorkerComp, InsurancePolicyTypes.WORKERS_COMP, file);
          break;
      }
    }

    setFiles([...files, ...uploaded]);
    setIsLoading(false);
    setIsUploadAnother(false);
    setToast((prevState: any) => ({
      ...prevState,
      isHidden: false,
      message: 'COI Extraction Failed, the File Uploaded successfully! (Please enter the Policy data manually)',
      color: 'error',
      severity: 'error',
      timeout: 60000,
    }));
  }

  const uploadPolicy = async (policy: IPolicy | null, setVendorPolicy: Function, type: InsurancePolicyTypes, file: any) => {
    let policyId = policy?.policyId ?? '';
    if (!policyId || policyId.length == 0) {
      const policy = await api.insurance.createPolicy(type, { vendorId, status: 'PENDING' });
      policyId = policy.id;
      const newPolicy = {} as IPolicy;
      delete Object.assign(newPolicy, policy, { ['policyId']: policy['id'] })['id'];
      setVendorPolicy(newPolicy);
    }

    await api.insurance
      .uploadCoi(policyId, file.name)
      .then(async res => {
        await fetch(res.data.url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        }).catch(err => {
          setIsLoading(false);
          console.error(`Failed to upload ${file.name}`);
        });
      })
      .catch(err => {
        setIsLoading(false);
        console.error(`Failed to generate upload URL for ${file.name}`);
      });
  };

  const uploadFile = async (uploadedFile: File[]) => {
    setIsLoading(true);
    const file = uploadedFile[0];
    let uploaded: any = [];
    await api.vendors.getW9UploadUrl(vendorId, file.name, session.details.name).then(async fileRes => {
      await fetch(fileRes, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      }).then(async res => {
        const acticity = getActivity('w9', file.name, 'W-9');
        await onActivitySubmit([acticity]);
      });
    });
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    uploaded = [...uploaded, { name: file.name, size: fileSize }];
    setFiles([...files, ...uploaded]);
    setIsLoading(false);
    setIsUploadAnother(false);
    setToast((prevState: any) => ({
      ...prevState,
      isHidden: false,
      message: 'File Uploaded successfully!',
      color: 'success',
      severity: 'success',
    }));
  };

  const uploadOtherFile = async (uploadedFile: File[]) => {
    setIsLoading(true);
    const file = uploadedFile[0];
    let uploaded: any = [];
    await api.vendors.getOtherUploadUrl(vendorId, file.name, session.details.name).then(async fileRes => {
      await fetch(fileRes, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      }).then(async res => {
        const acticity = getActivity('other', file.name, 'Other');
        await onActivitySubmit([acticity]);
      });
    });
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    uploaded = [...uploaded, { name: file.name, size: fileSize }];
    setFiles([...files, ...uploaded]);
    setIsLoading(false);
    setIsUploadAnother(false);
    setToast((prevState: any) => ({
      ...prevState,
      isHidden: false,
      message: 'File Uploaded successfully!',
      color: 'success',
      severity: 'success',
    }));
  };

  const stringdDateToIso = (dateString: string, isValid: boolean) => {
    try {
      if (isValid) isValid = true;
      return new Date(dateString).toISOString();
    } catch (error) {
      isValid = false;
      return new Date(null as any).toISOString();
    }
  };

  async function saveInsuranceInfo(insuranceInfo: any, vendorId: string) {
    const general_policyNo = insuranceInfo?.cgl_policyNumber;
    const auto_policyNo = insuranceInfo?.ca_policyNumber;
    const excess_policyNo = insuranceInfo?.um_policyNumber;
    const wc_policyNo = insuranceInfo?.wc_policyNumber;
    let isValid = true;

    const polictResult = {
      generalPolicy: {} as IPolicy,
      autoPolicy: {} as IPolicy,
      excessPolicy: {} as IPolicy,
      wcPolicy: {} as IPolicy,
      isValid: isValid,
    };

    let apiCalls: Promise<any>[] = [];
    const payload: any = {
      vendorId: vendorId,
      brokerName: insuranceInfo?.producer,
      brokerContact: insuranceInfo?.contact_name ?? insuranceInfo?.producer,
      brokerEmail: insuranceInfo?.contact_email,
      brokerPhone: insuranceInfo?.contact_phone,
      expiration: '',
      policyNo: '',
      certificateHolder: insuranceInfo?.certificate_holder || insuranceInfo?.certificateHolder,
      description: insuranceInfo?.description,
    };
    if (general_policyNo && general_policyNo.trim().length !== 0) {
      const policyPayload = {
        ...payload,
        policyNo: general_policyNo,
        expiration: stringdDateToIso(insuranceInfo?.cgl_endDate, isValid),
        aggregate: insuranceInfo.cgl_genAggregate,
        eachOccurence: insuranceInfo.cgl_eachOccurence,
        personalAndAdvInjury: insuranceInfo['cgl_personal&advInjury'],
        productsOrCompOrOpAgg: insuranceInfo['cgl_products/comp/opAgg'],
        startDate: stringdDateToIso(insuranceInfo.cgl_startDate, isValid),
        medicalExpense: insuranceInfo.cgl_medicalExpense,
        damageToPremises: insuranceInfo.cgl_damageToPremises,
        additionalInsured: insuranceInfo.cgl_AI,
        waiverOfSubrogation: insuranceInfo.cgl_wos,
      };

      if (vendorGeneralLiability?.policyId) {
        policyPayload.policyId = vendorGeneralLiability?.policyId;
      }
      apiCalls.push(
        api.insurance.submitPolicy(InsurancePolicyTypes.GENERAL_LIABILITY, policyPayload).then(res => {
          const newObject = {} as IPolicy;
          delete Object.assign(newObject, res, { ['policyId']: res['id'] })['id'];
          polictResult.generalPolicy = newObject;
          console.info('General liability saved');
        })
      );
    }
    if (auto_policyNo && auto_policyNo.trim().length !== 0) {
      const policyPayload = {
        ...payload,
        policyNo: auto_policyNo,
        expiration: stringdDateToIso(insuranceInfo?.ca_endDate, isValid),
        combinedSingleLimit: insuranceInfo.ca_combinedSingleLimit,
        bodilyInjuryPerAccident: insuranceInfo.ca_BIperAccident,
        bodilyInjuryperPerson: insuranceInfo.ca_BIperPerson,
        proprtyDamagePerAccident: insuranceInfo.ca_PD,
        startDate: stringdDateToIso(insuranceInfo.ca_startDate, isValid),
        additionalInsured: insuranceInfo.ca_AI,
        waiverOfSubrogation: insuranceInfo.ca_wos,
      };

      if (vendorAutoLiability?.policyId) {
        policyPayload.policyId = vendorAutoLiability?.policyId;
      }
      apiCalls.push(
        api.insurance.submitPolicy(InsurancePolicyTypes.AUTO_LIABILITY, policyPayload).then(res => {
          const newObject = {} as IPolicy;
          delete Object.assign(newObject, res, { ['policyId']: res['id'] })['id'];
          polictResult.autoPolicy = newObject;
          console.info('Auto liability saved');
        })
      );
    }
    if (excess_policyNo && excess_policyNo.trim().length !== 0) {
      const policyPayload = {
        ...payload,
        expiration: stringdDateToIso(insuranceInfo?.um_endDate, isValid),
        policyNo: excess_policyNo,
        eachOccurence: insuranceInfo.um_eachOccurence,
        aggregate: insuranceInfo.um_aggregate,
        startDate: stringdDateToIso(insuranceInfo.um_startDate, isValid),
        excessLiab: insuranceInfo.um_excessLiab,
        additionalInsured: insuranceInfo.um_AI,
        waiverOfSubrogation: insuranceInfo.um_wos,
      };

      if (vendorExcessLiability?.policyId) {
        policyPayload.policyId = vendorExcessLiability?.policyId;
      }
      apiCalls.push(
        api.insurance.submitPolicy(InsurancePolicyTypes.EXCESS_LIABILITY, policyPayload).then(res => {
          const newObject = {} as IPolicy;
          delete Object.assign(newObject, res, { ['policyId']: res['id'] })['id'];
          polictResult.excessPolicy = newObject;
          console.info('Excess liability saved');
        })
      );
    }
    if (wc_policyNo && wc_policyNo.trim().length !== 0) {
      const policyPayload = {
        ...payload,
        expiration: stringdDateToIso(insuranceInfo?.wc_endDate, isValid),
        policyNo: wc_policyNo,
        eachAccident: insuranceInfo.wc_eachAccident,
        diseaseEachEmployee: insuranceInfo.wc_diseaseEachEmployee,
        diseasePolicyLimit: insuranceInfo.wc_diseasePolicyLimit,
        startDate: stringdDateToIso(insuranceInfo.wc_startDate, isValid),
        additionalInsured: insuranceInfo.wc_AI,
        waiverOfSubrogation: insuranceInfo.wc_wos,
      };

      if (vendorWorkerComp?.policyId) {
        policyPayload.policyId = vendorWorkerComp?.policyId;
      }
      apiCalls.push(
        api.insurance.submitPolicy(InsurancePolicyTypes.WORKERS_COMP, policyPayload).then(res => {
          const newObject = {} as IPolicy;
          delete Object.assign(newObject, res, { ['policyId']: res['id'] })['id'];
          polictResult.wcPolicy = newObject;
          console.info('Workers Comp saved');
        })
      );
    }

    await Promise.all(apiCalls);
    console.info('All saved');
    return polictResult;
  }

  const checkInsureproCOI = async (file: any): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('filename', Date.now() + file.name);
      const resp = await fetch('https://coi-extractor-ml.idcore.com/coiExtract/v1', {
        method: 'post',
        body: formData,
      });
      const respData = await resp.json();

      return respData;
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const deleteFile = (vendorId: string, filename: string) => {
    setIsLoading(true);
    api.vendors
      .removeFile(vendorId, filename)
      .then(res => {
        const f = files?.filter((v: any) => v.name !== filename) || [];
        setFiles([...f]);
        setIsLoading(false);
        setToast((prevState: any) => ({
          ...prevState,
          isHidden: false,
          message: 'File deleted successfully!',
          color: 'success',
          severity: 'success',
        }));
      })
      .catch(err => {
        setIsLoading(false);
        setToast((prevState: any) => ({
          ...prevState,
          isHidden: false,
          message: 'File deletion failed!',
          color: 'error',
          severity: 'error',
        }));
        console.error(err);
      });
  };

  const getActivity = (fileType: string, fileName: string, insurance: string) => {
    return {
      referenceId: vendorId,
      referenceType: 'VENDOR_ID',
      direction: 'ACTIVITY',
      message: 'New File Uploaded For',
      data: JSON.stringify({ activity: 'file_upload', type: 'Update', insurance, fileName: fileName, fileType }),
    };
  };

  const onActivitySubmit = async (activity: any[]) => {
    let apiCalls: Promise<any>[] = [];
    activity.map(v =>
      apiCalls.push(
        api.services.createActivity(v)
      )
    );
    await Promise.all(apiCalls);
  };

  const VendoLinearProgress = styled(LinearProgress)(({ theme }) => {
    return {
      [`& .${linearProgressClasses.barColorPrimary}`]: {
        backgroundColor: '#3EBD93',
      },
    };
  });

  return (
    <>
      <Typography variant="h4">Upload a File</Typography>
      <Grid container px={4} pt={4} pb={1}>
        {isPolicy && (
          <Grid item xs={12} pb={4}>
            <Typography variant="body1" fontWeight={700}>
              What Policies Does this File Relate to?
            </Typography>
            <FormControl fullWidth sx={{ pt: 2 }}>
              <VendoSelect
                name="uploadOptions"
                value={uploadOptionFilter}
                displayEmpty
                multiple
                renderValue={(selected: any) => {
                  return selected.length > 0 ? (
                    selected.join(', ')
                  ) : (
                    <span style={{ color: '#9E9E9E' }}>Select the Policies</span>
                  );
                }}
                onChange={onUploadOptionChanged}
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.15)',
                }}
              >
                <MenuItem value={'General Liability'}>
                  <ListItemText primary={'General Liability'}></ListItemText>
                </MenuItem>
                <MenuItem value={'Auto Liability'}>
                  <ListItemText primary={'Auto Liability'}></ListItemText>
                </MenuItem>
                <MenuItem value={'Excess Liability'}>
                  <ListItemText primary={'Excess Liability'}></ListItemText>
                </MenuItem>
                <MenuItem value={'Workers Comp'}>
                  <ListItemText primary={'Workers Comp'}></ListItemText>
                </MenuItem>
              </VendoSelect>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          {!isLoading && (files.length == 0 || isUploadAnother) && (
            <Box
              sx={{
                border: '1px dashed #627D98',
                borderRadius: '8px',
                mb: 2,
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                  minHeight: 150,
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input {...getInputProps()} />
                <UploadIcon></UploadIcon>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                  <Typography variant="body1" fontWeight={700} mb={0.5}>
                    Drag and drop or
                  </Typography>
                  <Typography variant="body1" fontWeight={700} color={'#1992D4'} mb={0.5} pl={1} sx={{ cursor: 'pointer' }}>
                    browse files
                  </Typography>
                </Box>
                <Typography variant="body2" color="GrayText">
                  Max File Size is 10MB
                </Typography>
                {/* <Typography variant="body2" color="GrayText">
                          The file(s) must be in pdf, jpg or png format.
                        </Typography> */}
                {fileRejections.length > 0 && (
                  <Typography variant="body2" mt={4} fontSize="small" color="darkred">
                    File not accepted
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {isLoading && files.length == 0 && <LoadingBar></LoadingBar>}
        </Grid>
        <Grid item xs={12}>
          {!isLoading && files.length > 0 && (
            <Box sx={{}}>
              <Typography variant="body2" fontWeight={700}>
                File(s) Uploaded
              </Typography>
              <TableContainer>
                <Table sx={{ minWidth: 500 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" sx={{ pl: 0 }}>
                        Name
                      </TableCell>
                      <TableCell align="left">Size</TableCell>
                      <TableCell align="left">Upload Status</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((row: any, key: number) => (
                      <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" sx={{ pl: 0 }}>
                          <Stack direction="row">
                            <InsertDriveFileOutlinedIcon></InsertDriveFileOutlinedIcon>
                            <Typography pl={1}>{row.name}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{row.size} MB</TableCell>
                        <TableCell align="left">
                          <VendoLinearProgress variant="determinate" value={100} />
                        </TableCell>
                        <TableCell align="right" onClick={() => deleteFile(vendorId, row.name)}>
                          <DeleteOutlineIcon color="error"></DeleteOutlineIcon>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {isLoading && files.length > 0 && <LoadingBar></LoadingBar>}
        </Grid>
        <Grid item xs={12}>
          <Toast></Toast>
        </Grid>
        <Grid item xs={12}>
          {files.length > 0 && (
            <Stack spacing={2} pt={2} direction="row" justifyContent={'end'}>
              <Button
                variant="text"
                size="medium"
                sx={{ color: theme.palette.secondary.dark, fontSize: 16, textTransform: 'none' }}
                onClick={() => setIsUploadAnother(true)}
              >
                Upload Another File
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={{ bgcolor: theme.palette.primary.dark, fontSize: 16, p: '12px 32px', textTransform: 'none' }}
                onClick={() => onSubmit()}
              >
                Submit File
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}
