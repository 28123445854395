export interface IPolicy {
  brokerContact: string | null;
  brokerEmail: string | null;
  brokerName: string | null;
  brokerPhone: string | null;
  policyId: string | null;
  policyNo: string | null;
  status: string;
  expiration?: string | null;
}

export interface IAgentInfo {
  brokerContact: string | undefined;
  brokerEmail: string | undefined;
  brokerName: string | undefined;
  brokerPhone: string | undefined;
  policyId: string | undefined;
}

export interface ICreatePolicyPayload {
  vendorId: string;
  status?: string;
  expiration?: string;
  agentInfo?: IAgentInfo;
}

export enum InsurancePolicyTypes {
  GENERAL_LIABILITY = 'GENERAL-LIABILITY',
  AUTO_LIABILITY = 'AUTO-LIABILITY',
  EXCESS_LIABILITY = 'EXCESS-LIABILITY',
  WORKERS_COMP = 'WORKERS-COMP',
}
