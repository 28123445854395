import auth from './auth';
import backgroundChecks from './backgroundCheck';
import vendors from './vendors';
import notes from './notes';
import invites from './invites';
import insurance from './insurance';
import taxCodes from './taxCodes';
import services from './services';
import addresses from './addresses';

export default { auth, backgroundChecks, vendors, insurance, notes, invites, taxCodes, services, addresses };
