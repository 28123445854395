import { Box, Modal } from '@mui/material';
import { ReactNode } from 'react';

interface IVendorModelProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}

export default function VendorModel({ children, isOpen, onClose }: IVendorModelProps) {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '740px',
          maxWidth: '740px',
          bgcolor: '#FFFFFF',
          boxShadow: '0px 4px 8px rgba(255, 255, 255, 0.1)',
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
