import { Box, Grid, Typography } from '@mui/material';
import VendorGrid from 'src/components/Vendor/Grid';
import PageLayout from 'src/layouts/PageLayout';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from 'src/context/SessionContext';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import api from 'src/api';
import { useForm } from 'react-hook-form';
import { IYourSearchInput } from 'src/customTypes/vendor';
import FindVendorSearch from 'src/components/Vendor/Search/findVendor';
import EmailIcon from '@mui/icons-material/EmailRounded';
import VendorModel from 'src/components/Vendor/Model';

export default function FindVendorPage() {
  const { session } = useContext(SessionContext);
  const [count, setCount] = useState<number>(0);
  const [activeTab, setActiveTab] = useState(0);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);
  const [vendorName, setVendorName] = useState('');

  // Search
  const { control, watch, reset, getValues } = useForm<IYourSearchInput>({
    defaultValues: {
      id: '',
      name: '',
      services: [],
      status: [],
      enrollmetnDate: {
        from: undefined,
        to: DateTime.now(),
        label: '',
      },
      complianceDate: {
        from: undefined,
        to: DateTime.now(),
        label: '',
      },
      state: '',
      zip: '',
      radius: '',
    },
    mode: 'onChange',
  });

  // Grid
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    date: false,
    complianceDate: false,
  });

  useEffect(() => {
    if(activeTab === 1) {
      setVisibilityModel({ ...visibilityModel, lastInvited: true });
    } else {
      setVisibilityModel({ ...visibilityModel, lastInvited: false });
    }
  }, [activeTab]);

  const DefaultStatus =
    session.role === 'IDCORE_ADMIN'
      ? ['COMPLIANT', 'NON-COMPLIANT', 'PENDING', 'EXPIRED', 'INELIGIBLE']
      : ['COMPLIANT', 'PENDING'];
  const defaultSort = { };
  const [filter, setFilter] = useState({
    status: DefaultStatus,
    filters: {} as any,
    sort: defaultSort,
    paging: { offset: 10, limit: 1 },
  });
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hideable: true },
    {
      field: 'name',
      headerName: 'VENDOR',
      flex: 1,
      minWidth: 200,
      sortable: false,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <span
          style={{
            paddingLeft: 24,
          }}
          title={params.colDef.headerName}
        >
          {params.colDef.headerName}
        </span>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: 24,
              fontSize: '16px',
            }}
            title={params.value}
          >
            {params.value}
          </span>
        </Box>
      ),
    },
    {
      field: 'services',
      headerName: 'SERVICE',
      flex: 1,
      minWidth: 220,
      sortable: false,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <span
          style={{
            paddingLeft: 24,
          }}
          title={params.colDef.headerName}
        >
          {params.colDef.headerName}
        </span>
      ),
      valueFormatter: (params: GridValueFormatterParams<any>) => `${params.value?.map((v: any) => v?.name)}`,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Box>
          {params.value && params.value.length > 0 ? (
            <Box>
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingLeft: 24,
                  fontSize: '16px',
                }}
                title={params.value[0]}
              >
                {params.value[0]}
              </span>
              {params.value.length > 1 && (
                <span>
                  <br></br>
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      paddingLeft: 24,
                      fontWeight: 400,
                      fontSize: '16px',
                      color: '#6E6E6E',
                    }}
                  >
                    + {params.value.length - 1} other services
                  </span>
                </span>
              )}
            </Box>
          ) : (
            ''
          )}
        </Box>
      ),
    },
    {
      field: 'address',
      headerName: 'LOCATION',
      flex: 1,
      minWidth: 100,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
          title={params.value}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 180,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
          title={params.value}
        >
          {params.value}
        </span>;
      },
    },
    {
      field: 'lastInvited',
      headerName: 'Last Invited',
      flex: 1,
      minWidth: 180,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? DateTime.fromISO(params.value).toLocal().toFormat('MMM dd, yyyy') : '';
      },
    },
    {
      field: 'invite',
      headerName: 'Invite',
      flex: 1,
      minWidth: 180,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}} onClick={() => {
            if(activeTab === 0 || (DateTime.now().diff(DateTime.fromISO(params.row.lastInvited), 'minute').minutes > 10)) {
              inviteVendor(params.id, params.row.name);
            }
          }}>
          <EmailIcon sx={{color: `${(activeTab === 0 || (DateTime.now().diff(DateTime.fromISO(params.row.lastInvited), 'minute').minutes > 10)) ? '#1992D4' : '#27293759'}`, width: '24px', height: '24px'}} />
          <Typography sx={{ fontSize: '16px', 
            lineHeight: '24px', 
            color: '#243B53', 
            borderBottom: `solid 1px ${(activeTab === 0 || (DateTime.now().diff(DateTime.fromISO(params.row.lastInvited), 'minute').minutes > 10)) ? '#243B53' : '#102A4333'}`,
            fontWeight: 500 }}>
              {activeTab === 1 ? ((DateTime.now().diff(DateTime.fromISO(params.row.lastInvited), 'minute').minutes > 10) ?  'Resend Invite' : 'Invite Sent') : 'Invite'}
          </Typography>
        </Box>;
      },
    },
  ];

  const inviteVendor = async (id: any, name: string) => {
    await api.vendors.inviteFromHub(id);
    setVendorName(name);
    setShowSuccessInvite(true);

    setFilter({...filter});
  }

  function getFilter() {
    const filters = { ...filter };
    filters.filters = filter.filters;
    filters.filters.enrollmentStatus = ['COMPLETED', 'IN-PROGRESS', 'IN_PROGRESS'];

    const filtered =
      !!filter.filters.vendorName ||
      !!filter.filters.status?.length ||
      !!filter.filters.service?.length ||
      !!filter.filters.zipCode?.length;

    if (filtered || filters.filters.enrollmentStatus) {
      filters.sort = defaultSort;
      return filters;
    }
  }

  async function downloadCsv() {
    try {
      let blob: Blob;
      const filters: any = getFilter();
      const res = await api.vendors.downloadVendors(filters);
      blob = new Blob([res]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${DateTime.now().toFormat('MM/dd/yyyy')}_your-vendors_export.csv`;
      link.click();
      link.remove();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let expiredDate = false;
      let complianceDate = false;
      if (value['status']) {
        const isExpired = value['status'].filter(c => c === 'EXPIRED');
        if (isExpired && isExpired.length > 0) expiredDate = true;
      }

      if (value['complianceDate']?.from) {
        complianceDate = true;
      }

      if (!name) {
        setVisibilityModel({ ...visibilityModel, date: false, complianceDate: false });
      } else {
        setVisibilityModel({ ...visibilityModel, date: expiredDate, complianceDate: complianceDate });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <PageLayout pageTitle="Property Details">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{pt: '16px'}}>
            <Typography sx={{fontSize: '24px', lineHeight: '32px', fontWeight: 500, color: '#0D0D0D'}}>Discover Vendors</Typography>
          </Box>
          <FindVendorSearch
            filter={filter}
            setFilter={setFilter}
            control={control}
            watch={watch}
            reset={reset}
            getValues={getValues}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              py={4}
              sx={{
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              <Typography variant="h4">Results ({count})</Typography>
            </Box>
          </Box>
          <VendorGrid columns={columns} filterApplied={filter} setCount={setCount} setVisibility={visibilityModel} findVendorsPage={true} activeTab={activeTab}/>
        </Grid>
      </Grid>
      <VendorModel isOpen={showSuccessInvite} onClose={() => setShowSuccessInvite(false)}>
        <Box sx={{paddingY: '40px', display: 'flex', justifyContent: 'center'}}>
          <Typography sx={{fontSize: '24px', color: '#000'}}>Invite has been sent to {vendorName}</Typography>
        </Box>
      </VendorModel>
    </PageLayout>
  );
}
