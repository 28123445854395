import { FC, useContext } from 'react';
import { Toolbar, Box, MenuItem, AppBar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Person2Outlined as Person } from '@mui/icons-material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublicIcon from '@mui/icons-material/Public';
import InsightsIcon from '@mui/icons-material/Insights';
import { theme } from '../styles/theme';
import { SessionContext } from 'src/context/SessionContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsMobile, useIsTab } from 'src/hooks/useIsMobile';
const logo = require('../assets/Logo-lg-new-w.svg').default;

const useStyles = makeStyles({
  navItem: {
    color: theme.palette.primary.dark + ' !important',
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: '#FFFFFF33 !important',
      borderRadius: '8px',
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    '&.active': {
      backgroundColor: '#FFFFFF33 !important',
      borderRadius: '8px',
      paddingTop: '8px',
      paddingBottom: '8px'
    },
  },
});

const TopNavigation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const { session, loading, sessionIsEmpty } = useContext(SessionContext);
  const classes = useStyles();

  const handleLogout = () => {
    window.location.href = '/api/auth/logout';
  };

  return (
    <AppBar
      elevation={1}
      sx={{
        backgroundColor: 'var(--primary-primary-80, #334E68)',
        px: isMobile || isTab ? '1rem' : '5rem',
        borderBottom: '1px solid #BCCCDC',
        boxShadow: 'none',
      }}
      component="nav"
    >
      <Toolbar>
        <img src={logo} alt="" />
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', flexGrow: 1 }}>
          {!sessionIsEmpty() && (
            <>
              <MenuItem className={classes.navItem} sx={location.pathname === '/' ? {
                backgroundColor: '#FFFFFF33 !important',
                borderRadius: '8px',
                paddingTop: '8px',
                paddingBottom: '8px'
              } : {}} onClick={() => navigate(`/`)}>
                <InsightsIcon sx={{ color: '#FFFFFF' }} />
                <Typography variant="subtitle1" sx={{ pl: '8px', color: '#FFFFFF' }}>
                  Dashboard
                </Typography>
              </MenuItem>
              <MenuItem selected={location.pathname === ''} className={classes.navItem} sx={location.pathname === '/your-vendor' ? {
                backgroundColor: '#FFFFFF33 !important',
                borderRadius: '8px',
                paddingTop: '8px',
                paddingBottom: '8px'
              } : {}} onClick={() => navigate(`/your-vendor`)}>
                <PeopleAltIcon sx={{ color: '#FFFFFF' }} />
                <Typography variant="subtitle1" sx={{ pl: '8px', color: '#FFFFFF' }}>
                  My Vendor List
                </Typography>
              </MenuItem>
              <MenuItem selected={location.pathname === ''} className={classes.navItem} sx={location.pathname === '/find-vendor' ? {
                backgroundColor: '#FFFFFF33 !important',
                borderRadius: '8px',
                paddingTop: '8px',
                paddingBottom: '8px'
              } : {}} onClick={() => navigate(`/find-vendor`)}>
                <PublicIcon sx={{ color: '#FFFFFF' }} />
                <Typography variant="subtitle1" sx={{ pl: '8px', color: '#FFFFFF' }}>
                  Discover Vendors
                </Typography>
              </MenuItem>
              <MenuItem className={classes.navItem} onClick={handleLogout}>
                <Person sx={{ color: '#FFFFFF' }} />
                <Typography variant="subtitle1" sx={{ pl: '8px', color: '#FFFFFF' }}>
                  Log Out
                </Typography>
              </MenuItem>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavigation;
