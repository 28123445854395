import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { FullStory } from '@fullstory/browser';

interface ISession {
  userId: string;
  role: string;
  details: any;
}

type RouteProps = {
  children?: JSX.Element;
  session: ISession;
  loading: boolean;
};

const ProtectedRoute = ({ session, children, loading }: RouteProps) => {
  const location = useLocation();
  if (loading) {
    return null;
  }

  if (!!session.userId) {
    FullStory('setIdentity', {
      uid: session.userId,
    })
    return children ? children : <Outlet />;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default ProtectedRoute;
