import { Chip } from '@mui/material';

interface StatusChipProps {
  status: string | null | undefined;
  isPopup?: boolean;
}

export default function StatusChip({ status, isPopup }: StatusChipProps) {
  const popUpStyles = {
    borderRadius: '24px',
    fontSize: '14px',
    px: '12px',
    py: '2px',
  };
  let chipStyle: any = {
    fontWeight: 700,
    fontSize: '16px',
    borderRadius: '24px',
    px: '16px',
    py: '4px',
  };
  chipStyle = isPopup ? { ...chipStyle, ...popUpStyles } : chipStyle;

  const switchStatus = status?.toUpperCase();
  switch (switchStatus) {
    case 'COMPLIANT':
    case 'ON-FILE':
      return (
        <Chip label={'Compliant'} color="success" sx={{ backgroundColor: '#C6F7E2', color: '#014D40', ...chipStyle }} />
      );
    case 'NON-COMPLIANT':
      return (
        <Chip
          label={'Non-compliant'}
          color="success"
          sx={{
            backgroundColor: '#FFBDBD',
            color: '#610316',
            ...chipStyle,
          }}
        />
      );
    case 'PENDING':
      return (
        <Chip
          label={'Pending'}
          color="success"
          sx={{
            backgroundColor: '#FFF3C4',
            color: '#8D2B0B',
            ...chipStyle,
          }}
        />
      );
    case 'EXPIRED':
      return (
        <Chip
          label={'Expired'}
          color="success"
          sx={{
            backgroundColor: '#B3ECFF',
            color: '#035388',
            ...chipStyle,
          }}
        />
      );
    case 'EXPIRING':
      return (
        <Chip
          label={'Expiring'}
          color="success"
          sx={{
            backgroundColor: '#FFF3C4',
            color: '#8D2B0B',
            ...chipStyle,
          }}
        />
      );
    case 'INELIGIBLE':
      return (
        <Chip
          label={'Ineligible'}
          color="success"
          sx={{
            backgroundColor: '#D9E2EC',
            color: '#102A43',
            ...chipStyle,
          }}
        />
      );
    default:
      return (
        <Chip
          label={status}
          color="success"
          sx={{
            backgroundColor: '#FFF3C4',
            color: '#8D2B0B',
            ...chipStyle,
          }}
        />
      );
  }
}
