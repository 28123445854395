import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';
import RootRouter from './routers/RootRouter';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootRouter />
    </ThemeProvider>
  );
};

export default App;
