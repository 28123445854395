import { Context, createContext, useState, Dispatch, SetStateAction, useEffect } from 'react';
import coreApi from '../api';

// Defines the properties of a session
interface ISession {
  userId: string;
  role: 'IDCORE_ADMIN' | 'VENDOR' | 'PROPERTY_MANAGER' | '';
  details: any;
}

// Defines the interface for storing/accessing a session
interface ISessionStorage {
  session: ISession;
  setSession: Dispatch<SetStateAction<ISession>>;
  refreshSession: () => Promise<boolean>;
  sessionIsEmpty: () => boolean;
  loading: boolean;
}

// Until a user has authenticatd there is no session so an empty object is suitable.
const initialSession: ISession = {
  userId: '',
  role: '',
  details: {},
};

const initialSessionStorage: ISessionStorage = {
  session: initialSession,
  setSession: () => {},
  refreshSession: () => Promise.resolve(false),
  sessionIsEmpty: () => true,
  loading: true,
};

// Define the session state and all fn's able to access the session storage
const useSession = (): ISessionStorage => {
  const [session, setSession] = useState(initialSession);
  const [loading, setLoading] = useState<boolean>(true);
  const sessionIsEmpty = (): boolean => {
    return Object.values(session).filter(val => val.length > 0).length === 0;
  };

  const refreshSession = async (): Promise<boolean> => {
    try {
      setLoading(true);
      const res = await coreApi.auth.getSession();
      if (res) {
        setSession(res);
        return true;
      } else {
        if (!sessionIsEmpty()) {
          setSession(initialSession);
        }
        return false;
      }
    } catch (err) {
      if (!sessionIsEmpty()) {
        setSession(initialSession);
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshSession();
  }, []);

  return { session, setSession, refreshSession, sessionIsEmpty, loading };
};

// Creates a React Context object for storing Session information
export const SessionContext: Context<ISessionStorage> = createContext(initialSessionStorage);

// Creates the SessionContextProvider which exposes the session storage to the rest of the react app
export const SessionContextProvider = ({ children }: any) => {
  const session = useSession();

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};
