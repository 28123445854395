import { AppBar, Box, CircularProgress, Tab } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import api from 'src/api';
import { IPolicy } from 'src/customTypes/insurance';
import { SessionContext } from 'src/context/SessionContext';
import ActivityTab from './ActivityTab';
import { VendoTabsItem } from './VendoTabsItem';
import MessagesTab from './MessagesTab';
import GeneralTab from './GeneralTab';
import DetailsTab from './DetailsTab';
import BusinessDocTab from './BusinessDocTab';
import InsuranceDocTab from './InsuranceDocTab';
import OtherTab from './OtherTab';

const LoadingBar = ({}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', py: 6 }}>
      <CircularProgress />
    </Box>
  );
};

interface IVendorTabProps {
  data: any;
  activeTab: number;
  setActivityTab: (tab: number) => void;
  handleUploadOpen: (isPolicyUpload: boolean, isOther?: boolean) => void;
  handleActivityOpen: () => void;
  vendorGeneralLiability: IPolicy | null;
  setVendorGeneralLiability: (policy: IPolicy) => void;
  vendorAutoLiability: IPolicy | null;
  setVendorAutoLiability: (policy: IPolicy) => void;
  vendorExcessLiability: IPolicy | null;
  setVendorExcessLiability: (policy: IPolicy) => void;
  vendorWorkerComp: IPolicy | null;
  setVendorWorkerComp: (policy: IPolicy) => void;
  refreshData: () => void;
}

export default function VendorTabs({
  data,
  activeTab,
  setActivityTab,
  handleUploadOpen,
  handleActivityOpen,
  vendorGeneralLiability,
  setVendorGeneralLiability,
  vendorAutoLiability,
  setVendorAutoLiability,
  vendorExcessLiability,
  setVendorExcessLiability,
  vendorWorkerComp,
  setVendorWorkerComp,
  refreshData,
}: IVendorTabProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vendorData, setVendorData] = useState(data);

  const [files, setFiles] = useState<Array<any>>([]);
  const [isFileReload, setIsFileReload] = useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActivityTab(newValue);
    loadDocFiles(newValue);
  };

  const [bgCheck, setBgCheck] = useState<any>({});
  const [bgStatus, setBgStatus] = useState<string>();
  useEffect(() => {
    api.vendors
      .getVendorsCurrentBgCheck(vendorData._id, vendorData.userId)
      .then((res: any) => {
        let data = res;
        if (!data?.subjectInfo) {
          data = {
            ...data,
            subjectInfo: {
              city: '',
              dob: '',
              firstName: '',
              lastName: '',
              middleName: '',
              ssn: '',
              state: '',
              streetAddress: '',
              zipCode: '',
            },
          };
        }
        setBgCheck(data);
      })
      .catch((err: any) => {
        console.error('Error', err);
      });
  }, [vendorData]);

  function loadDocFiles(tab: number) {
    if (tab === 2 || tab === 3 || tab === 6) {
      setIsLoading(true);
      api.vendors
        .listVendorsUploadedDocs(vendorData._id)
        .then(res => {
          const filedetails: any = [];
          if (res?.coi) {
            res.coi.map((file: any) =>
              filedetails.push({
                id: vendorData._id,
                name: file.key,
                lastModified: file.lastModified,
                uploadedBy: file.uploadedBy,
                isPolicy: false,
                type: 'coi',
              })
            );
          }

          if (res?.w9) {
            filedetails.push({
              id: vendorData._id,
              name: res.w9.key,
              lastModified: res.w9.lastModified,
              uploadedBy: res.w9.uploadedBy,
              isPolicy: false,
              type: 'w9',
            });
          }
          if (res?.other) {
            res.other.map((file: any) =>
              filedetails.push({
                id: vendorData._id,
                name: file.key,
                lastModified: file.lastModified,
                uploadedBy: file.uploadedBy,
                isPolicy: false,
                type: 'other',
              })
            );
          }
          if (res?.archive) {
            res.archive.map((file: any) =>
              filedetails.push({
                id: vendorData._id,
                name: file.key,
                lastModified: file.lastModified,
                uploadedBy: file.uploadedBy,
                isPolicy: false,
                type: 'archive',
              })
            );
          }
          setFiles(filedetails);
        })
        .catch((err: any) => {
          console.error('Error', err);
        })
        .finally(() => {
          setIsLoading(false);
          setIsFileReload(false);
        });
    }
  }

  useEffect(() => {
    loadDocFiles(activeTab);
  }, [activeTab]);

  useEffect(() => {
    isFileReload && loadDocFiles(activeTab);
  }, [isFileReload]);

  useEffect(() => {
    refreshData();
  }, [bgCheck]);

  if (!vendorData) {
    return <></>;
  }

  return (
    <>
      <AppBar
        position="static"
        sx={{
          boxShadow: 'none',
          bgcolor: 'white',
          borderBottom: '1px solid #BCCCDC',
          fontSize: '16px',
          color: 'black',
        }}
      >
        <VendoTabsItem
          value={activeTab}
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: '#035388' } }}
          itemProp=""
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="General" tabIndex={0} />
          <Tab label="Details" tabIndex={1} />
          <Tab label="Business Docs" tabIndex={1} />
          <Tab label="Insurance Docs" tabIndex={3} />
          <Tab label="Messages" tabIndex={4} />
          <Tab label="Activity" tabIndex={5} />
          <Tab label="Other Docs" tabIndex={6} />
        </VendoTabsItem>
      </AppBar>
      {isLoading && <LoadingBar></LoadingBar>}
      {!isLoading && (
        <Box>
          <GeneralTab
            isHidden={activeTab !== 0}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            vendorData={vendorData}
            setVendorData={setVendorData}
            refreshData={refreshData}
          />
          <DetailsTab
            isHidden={activeTab !== 1}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            vendorData={vendorData}
            setVendorData={setVendorData}
            bgStatus={bgStatus}
            bgCheck={bgCheck}
            setBgCheck={setBgCheck}
          />
          <BusinessDocTab
            isHidden={activeTab !== 2}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            vendorData={vendorData}
            setVendorData={setVendorData}
            files={files}
            bgCheck={bgCheck}
            setBgStatus={setBgStatus}
            handleUploadOpen={handleUploadOpen}
            refreshData={refreshData}
          />
          <InsuranceDocTab
            isHidden={activeTab !== 3}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            vendorData={vendorData}
            setVendorData={setVendorData}
            files={files}
            setFiles={setFiles}
            handleUploadOpen={handleUploadOpen}
            vendorGeneralLiability={vendorGeneralLiability}
            setVendorGeneralLiability={setVendorGeneralLiability}
            vendorAutoLiability={vendorAutoLiability}
            setVendorAutoLiability={setVendorAutoLiability}
            vendorExcessLiability={vendorExcessLiability}
            setVendorExcessLiability={setVendorExcessLiability}
            vendorWorkerComp={vendorWorkerComp}
            setVendorWorkerComp={setVendorWorkerComp}
          />
          <MessagesTab isHidden={activeTab !== 4} vendorId={vendorData._id} />
          <ActivityTab isHidden={activeTab !== 5} vendorId={vendorData._id} handleActivityOpen={handleActivityOpen} />
          <OtherTab
            isHidden={activeTab !== 6}
            vendorId={vendorData._id}
            files={files}
            setFiles={setFiles}
            handleUploadOpen={handleUploadOpen}
            setIsFileReload={setIsFileReload}
          />
        </Box>
      )}
    </>
  );
}
