import { apiClient } from "./client";


const searchVendors = async (query?: string) => {
    try {
        const queryParams = {
            query
        };
        const res = await apiClient.get('/addresses', { params: queryParams });
        return res.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export default {
    searchVendors
}
