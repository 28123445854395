import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InputSsn = forwardRef((props: CustomProps, ref: any) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="xxx-xx-xxxx"
      definitions={{
        x: /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default InputSsn;
