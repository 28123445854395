import { ICreatePolicyPayload, InsurancePolicyTypes, IAgentInfo } from 'src/customTypes/insurance';
import { apiClient } from './client';
import { type } from 'os';

const createPolicy = async (policyType: InsurancePolicyTypes, payload: ICreatePolicyPayload) => {
  let res;
  switch (policyType) {
    case InsurancePolicyTypes.GENERAL_LIABILITY:
      res = await apiClient.post('/insurance-policies/general-liability', payload);
      return res.data;
    case InsurancePolicyTypes.AUTO_LIABILITY:
      res = await apiClient.post('/insurance-policies/auto-liability', payload);
      return res.data;
    case InsurancePolicyTypes.EXCESS_LIABILITY:
      res = await apiClient.post('/insurance-policies/excess-liability', payload);
      return res.data;
    case InsurancePolicyTypes.WORKERS_COMP:
      res = await apiClient.post('/insurance-policies/workers-comp', payload);
      return res.data;
    default:
      // If it is an unsupported policy type then it's OK to do nothing with this request.
      return;
  }
};

const updatePolicy = async (policyType: InsurancePolicyTypes, payload: IAgentInfo) => {
  const { policyId, ...others } = payload;
  let res;
  switch (policyType) {
    case InsurancePolicyTypes.GENERAL_LIABILITY:
      res = await apiClient.put(`/insurance-policies/general-liability/${policyId}`, { agentInfo: others });
      return res.data.agentInfo;
    case InsurancePolicyTypes.AUTO_LIABILITY:
      res = await apiClient.put(`/insurance-policies/auto-liability/${policyId}`, { agentInfo: others });
      return res.data.agentInfo;
    case InsurancePolicyTypes.EXCESS_LIABILITY:
      res = await apiClient.put(`/insurance-policies/excess-liability/${policyId}`, { agentInfo: others });
      return res.data.agentInfo;
    case InsurancePolicyTypes.WORKERS_COMP:
      res = await apiClient.put(`/insurance-policies/workers-comp/${policyId}`, { agentInfo: others });
      return res.data.agentInfo;
    default:
      // If it is an unsupported policy type then it's OK to do nothing with this request.
      return;
  }
};

const submitPolicy = async (policyType: InsurancePolicyTypes, payload: ICreatePolicyPayload) => {
  let res;
  switch (policyType) {
    case InsurancePolicyTypes.GENERAL_LIABILITY:
      res = await apiClient.post(`/insurance-policies/general-liability/submit`, payload);
      return res.data;
    case InsurancePolicyTypes.AUTO_LIABILITY:
      res = await apiClient.post(`/insurance-policies/auto-liability/submit`, payload);
      return res.data;
    case InsurancePolicyTypes.EXCESS_LIABILITY:
      res = await apiClient.post(`/insurance-policies/excess-liability/submit`, payload);
      return res.data;
    case InsurancePolicyTypes.WORKERS_COMP:
      res = await apiClient.post(`/insurance-policies/workers-comp/submit`, payload);
      return res.data;
    default:
      // If it is an unsupported policy type then it's OK to do nothing with this request.
      return;
  }
};

const updateGeneralLiabilityStatus = (policyId: string, status: string, changeBy: string) => {
  return apiClient.patch(`/insurance-policies/general-liability/${policyId}`, { status, changeBy });
};

const updateAutoLiabilityStatus = (policyId: string, status: string, changeBy: string) => {
  return apiClient.patch(`/insurance-policies/auto-liability/${policyId}`, { status, changeBy });
};

const updateExcessLiabilityStatus = (policyId: string, status: string, changeBy: string) => {
  return apiClient.patch(`/insurance-policies/excess-liability/${policyId}`, { status, changeBy });
};

const updateWorkerComStatus = (policyId: string, status: string, changeBy: string) => {
  return apiClient.patch(`/insurance-policies/workers-comp/${policyId}`, { status, changeBy });
};

const updateGeneralLiabilityExpiration = (policyId: string, expiration: string) => {
  return apiClient.patch(`/insurance-policies/general-liability/${policyId}/update-expiration`, { expiration });
};

const updateAutoLiabilityExpiration = (policyId: string, expiration: string) => {
  return apiClient.patch(`/insurance-policies/auto-liability/${policyId}/update-expiration`, { expiration });
};

const updateExcessLiabilityExpiration = (policyId: string, expiration: string) => {
  return apiClient.patch(`/insurance-policies/excess-liability/${policyId}/update-expiration`, { expiration });
};

const updateWorkerComExpiration = (policyId: string, expiration: string) => {
  return apiClient.patch(`/insurance-policies/workers-comp/${policyId}/update-expiration`, { expiration });
};

const getVerifiedCoiFiles = async (policyId: string) => {
  const res = await apiClient.get(`/insurance-policies/${policyId}/verified-coi-docs`);
  return res.data;
};

const downloadCoi = (policyId: string, filename: string) => {
  return apiClient.post(`/insurance-policies/${policyId}/download-coi`, { filename: filename });
};

const uploadCoi = (policyId: string, filename: string) => {
  return apiClient.post(`/insurance-policies/${policyId}/upload-coi`, { filename: filename });
};

const removeCoi = (policyId: string, filename: string, vendorId: string) => {
  return apiClient.post(`/insurance-policies/${policyId}/remove-coi`, { filename: filename, vendorId });
};

export default {
  createPolicy,
  updatePolicy,
  submitPolicy,
  updateGeneralLiabilityStatus,
  updateAutoLiabilityStatus,
  updateExcessLiabilityStatus,
  updateWorkerComStatus,
  updateGeneralLiabilityExpiration,
  updateAutoLiabilityExpiration,
  updateExcessLiabilityExpiration,
  updateWorkerComExpiration,
  getVerifiedCoiFiles,
  downloadCoi,
  uploadCoi,
  removeCoi,
};
