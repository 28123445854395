import { Tabs, tabClasses } from '@mui/material';
import { styled } from '@mui/styles';

export const VendoTabsItem = styled(Tabs)(({ theme }) => {
  return {
    [`& .${tabClasses.root}`]: {
      backgroundColor: '#FFFFFF',
      fontSize: '16px',
      color: '#9E9E9E',
      textTransform: 'none',
    },
    [`&.msg .${tabClasses.selected} `]: {
      color: '#035388 !important',
      backgroundColor: '#F0F4F8',
      borderRadius: '4px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'white',
    },
    '&.msg .MuiButtonBase-root': {
      padding: '4px 7px',
      minHeight: '32px',
      marginRight: '16px',
    },
  };
});
