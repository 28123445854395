import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, IconButton } from '@mui/material';
import { ToastContext } from 'src/context/ToastContext';
import CloseIcon from '@mui/icons-material/Close';

export default function Toast() {
  const [isOpen, setIsOpen] = useState(false);
  const { isHidden, severity, color, message } = useContext(ToastContext);

  useEffect(() => {
    setIsOpen(!isHidden);
  }, [isHidden]);

  return (
    <>
      {isOpen && (
        <Box>
          <Alert
            severity={severity}
            color={color}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Box>
      )}
    </>
  );
}
