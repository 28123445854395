import { Box } from '@mui/material';
import { theme } from '../styles/theme';
import { ReactNode } from 'react';
import TopNavigation from 'src/components/TopNavigations';
import { useIsMobile, useIsTab } from 'src/hooks/useIsMobile';

interface IProps {
  pageTitle: string;
  children: ReactNode;
}

export default function PageLayout({ pageTitle, children }: IProps) {
  const isMobile = useIsMobile();
  const isTab = useIsTab();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        px: isMobile || isTab ? 4 : '6rem',
      }}
    >
      <TopNavigation />
      <Box sx={{ pt: 10, pb: 4 }}>{children}</Box>
    </Box>
  );
}
