import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  gridClasses,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import api from '../../../api';
import { IVendorQueryParams } from '../../../customTypes/vendor';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Grid,
  ListItemText,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import CallIcon from '@mui/icons-material/CallOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import { IPolicy } from 'src/customTypes/insurance';
import VendorModel from '../Model';
import VendorTabs from '../Tabs';
import DocUpload from '../Model/DocUpload';
import StatusChip from '../StatusChip';
import { ReactComponent as NextTextIcon } from '../../../svg/next-text-icon.svg';
import { ReactComponent as PrevTextIcon } from '../../../svg/prev-text-icon.svg';
import { theme } from '../../../styles/theme';
import { getExpiringStatus } from 'src/helpers/vendor';
import { ServiceAreaOption } from 'src/customTypes/services';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { external } from 'src/api/client';

const VendoSelect = styled(Select)(({ theme }) => {
  return {
    ['& .MuiOutlinedInput-notchedOutline']: {
      border: 'none',
    },
  };
});
const VendorInput = styled(TextField)(({ theme }) => ({
  [`&.MuiTextField-root`]: {
    borderRadius: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #BCCCDC',
  },
}));

// Grid
type Props = {
  columns: GridColDef[];
  loading?: boolean;
  filterApplied: any;
  setCount: (count: number) => void;
  setVisibility: GridColumnVisibilityModel;
  findVendorsPage?: boolean;
  activeTab?: any;
};

function createData(
  id: string,
  vendor: string,
  service: string[],
  location: string,
  date: string,
  complianceDate: string,
  status: string,
  referenceId: string,
  resmanAbb: string,
  other: object
) {
  return {
    id,
    vendor,
    service,
    location,
    date,
    complianceDate,
    status,
    referenceId,
    resmanAbb,
    other,
  };
}

const ODD_OPACITY = 0.2;
const VendorDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    [`&.${gridClasses.root}`]: {
      borderRadius: '16px',
      boxShadow: '0px 4px 8px 2px #00000026',
      zIndex: '0',
    },
    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor: '#F6F7FA',
      fontSize: '16px',
    },
    [`& .${gridClasses.row}`]: {
      cursor: 'pointer',
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: '#FFFFFF',
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#F6F7FA',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
          },
        },
      },
    },
    [`& .${gridClasses.iconButtonContainer}`]: {
      marginBottom: '5px',
      visibility: 'visible !important',
      width: 'auto !important',
      [`& .${gridClasses.sortIcon}`]: {
        opacity: '0.5 !important',
      },
    },
  };
});

const VendorGrid = ({ columns, loading, filterApplied, setCount, setVisibility, findVendorsPage, activeTab }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Grid
  const defaultSort = { column: 'subscriptionStartDate', direction: -1 };
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(setVisibility);
  const [total, setTotal] = useState(0);
  const [isStarted, setIsStarted] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [filter, setFilter] = useState<IVendorQueryParams>({
    filters: filterApplied,
    sort: defaultSort,
    paging: { offset: paginationModel.pageSize * paginationModel.page, limit: paginationModel.pageSize },
  });
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'submitDate', sort: 'desc' }]);
  const [overallStatus, setOverallStatus] = useState<string>('');

  const [selectedServiceAreas, setSelectedServiceAreas] = useState<ServiceAreaOption[]>([]);
  const [position, setPosition] = useState({} as any);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);

  // Models
  const [activityTab, setActivityTab] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [gridRowData, setGridRowData] = useState<any>();
  const handleClose = () => {
    setOpen(false);
    setIsLoading(true);
    getGridData();
  };

  const handleFVOpen = (params: GridRowParams) => {
    setGridRowData(params.row);
    setOpen(true);
    setIsLoading(false);
  }

  const onCellClicked = (params: any, e: any) => {
    if(params.field === 'invite')
      e.stopPropagation();
  }

  const handleOpen = (params: GridRowParams) => {
    api.vendors
      .getInsurancePolicies(params.row.id)
      .then((response: any) => {
        setGeneralLiability(response.data?.generalLiability);
        setAutoLiability(response.data?.autoLiability);
        setExcessLiability(response.data?.excessLiability);
        setWorkerComp(response.data?.workerComp);
      })
      .catch((err: any) => {
        console.error('Error', err);
      })
      .finally(() => {
        setActivityTab(0);
        const newStatus = getExpiringStatus(params.row.status, params.row.other.overallPolicyExpiration);
        setOverallStatus(newStatus);
        setGridRowData(params.row);
        setOpen(true);
        setIsLoading(false);
      });
  };

  const [isPolicyUpload, setIsPolicyUpload] = useState<boolean>(false);
  const [isOtherUpload, setIsOtherUpload] = useState<boolean>(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const handleUploadClose = () => {
    setOpenUpload(false);
    setOpen(true);
  };
  const handleUploadOpen = (isPolicy: boolean, isOther?: boolean) => {
    setOpenUpload(true);
    setOpen(false);
    setIsPolicyUpload(isPolicy);
    setIsOtherUpload(isOther ?? false);
  };

  const [activityType, setActivityType] = useState<string>('');
  const [activityTo, setActivityTo] = useState<string>('');
  const [activityMessage, setActivityMessage] = useState<string>();
  const [openActivity, setOpenActivity] = useState<boolean>(false);
  const handleActivityClose = () => {
    setOpenActivity(false);
    setOpen(true);
  };
  const handleActivityOpen = () => {
    setActivityType('');
    setActivityTo('');
    setActivityMessage(undefined);
    setOpenActivity(true);
    setOpen(false);
  };
  const onActivitySubmit = () => {
    var activity = {
      referenceId: gridRowData.id,
      referenceType: 'VENDOR_ID',
      direction: 'ACTIVITY',
      message: activityMessage,
      data: JSON.stringify({ activity: 'manual', type: activityType, insurance: activityTo }),
    };
    api.services
      .createActivity(activity)
      .then((res: any) => {
        setActivityType('');
        setActivityTo('');
        setActivityMessage('');
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => handleActivityClose());
  };

  // Tabs
  const [generalLiability, setGeneralLiability] = useState<IPolicy | null>(null);
  const [autoLiability, setAutoLiability] = useState<IPolicy | null>(null);
  const [excessLiability, setExcessLiability] = useState<IPolicy | null>(null);
  const [workerComp, setWorkerComp] = useState<IPolicy | null>(null);

  const apiRef = useGridApiRef();
  useEffect(() => {
    if (!isStarted) getGridData();
  }, [paginationModel, sortModel, filter, activeTab]);

  useEffect(() => {
    setPaginationModel({
      pageSize: 25,
      page: 0,
    });
    var newFilter = { ...filter.filters, ...filterApplied };
    setFilter({
      filters: newFilter,
      sort: defaultSort,
      paging: { offset: paginationModel.pageSize * paginationModel.page, limit: paginationModel.pageSize },
    });
    if (filterApplied.timeStamp) {
      setIsStarted(false);
    }

    // Update the column visibility using apiRef
    if (apiRef.current) apiRef.current.setColumnVisibilityModel(setVisibility);
  }, [filterApplied, setVisibility]);

  useEffect(() => {
    if (!isStarted && open) getGridData();
  }, [generalLiability, autoLiability, excessLiability, workerComp]);

  function getSortFieldMap(field: string): string {
    switch (field) {
      case 'vendor':
        return 'w9.dba';
      case 'date':
        return 'subscriptionStartDate';
      case 'status':
        return 'overallComplianceStatus';
      case 'complianceDate':
        return 'complianceDate';
      default:
        return 'subscriptionStartDate';
    }
  }

  function getFilter() {
    const filters = { ...filter };

    if(findVendorsPage) {
      filters.sort = {};
      filters.paging = { offset: paginationModel.pageSize * paginationModel.page, limit: paginationModel.pageSize };
      filters.filters.status = activeTab === 0 ? ['NEW'] : ['INVITED'];
      
      return filters;
    } 
    else {
      filters.filters.enrollmentStatus = ['COMPLETED', 'IN-PROGRESS', 'IN_PROGRESS'];
      filters.paging = { offset: paginationModel.pageSize * paginationModel.page, limit: paginationModel.pageSize };

      const filtered =
        !!filter.filters.vendorName ||
        !!filter.filters.status?.length ||
        !!filter.filters.service?.length ||
        !!filter.filters.zipCode?.length;

      if (filtered || filters.filters.enrollmentStatus) {
        if (sortModel.length > 0) {
          const sortedColumn = sortModel[0];
          filters.sort = { column: getSortFieldMap(sortedColumn.field), direction: sortedColumn.sort == 'asc' ? 0 : -1 };
        } else {
          filters.sort = defaultSort;
        }

        return filters;
      }
    }
    
  }

  async function getGridData() {
    const filters: any = getFilter();

    setIsLoading(true);
    try {
      const res = await api.vendors.searchVendors(filters);
      // if(findVendorsPage && activeTab === 1) {
        // setTotal(1);
        // setCount(1);
      // } else {

      const exactMatchVendors = filters.filters.vendorName ? 
          res.results.filter((v: any) => v.w9.businessName.toLowerCase() === filters.filters.vendorName.toLowerCase() || v.w9.dba.toLowerCase() === filters.filters.vendorName.toLowerCase())
        : [];

      setTotal(exactMatchVendors.length ? exactMatchVendors.length : (res.pagination?.total || 0));
      setCount(exactMatchVendors.length ? exactMatchVendors.length : (res.pagination?.total || 0));
      // }
      if (res.results) {
        let resData = exactMatchVendors.length ? exactMatchVendors : res.results;
        let rowData: any[] = [];
        if(findVendorsPage) {
          rowData = resData.map((r: any) => {
            let location = '';
            const [city, state, zip] = r.location.split(',');
            if (city && state) {
              location = `${city}, ${state}`;
            }
            return {...r, id: r._id, address: location}
          });
        } else {
          rowData = resData.map((row: any) => {
            const { _id, w9, bizInfo, overallComplianceStatus, referenceId, complianceDate, subscriptionEndDate, resmanAbb } = row;
            let location = '';
            if (w9 && w9.address) {
              const [street, city, state, zip] = w9.address.split(',');
              if (city && state) {
                location = `${city}, ${state}`;
              }
            }
            let subDate = subscriptionEndDate ? DateTime.fromISO(subscriptionEndDate.toString()).toFormat('MM/dd/yyyy') : '';
            let name = '';
            if ((w9 && w9.dba) || (bizInfo && bizInfo.email)) {
              name = w9?.dba || bizInfo?.email;
            }
            const complianceDateFormatted = complianceDate
              ? DateTime.fromISO(complianceDate.toString()).toFormat('MM/dd/yyyy')
              : '';
            return createData(
              _id,
              name,
              bizInfo.services,
              location,
              subDate,
              complianceDateFormatted,
              overallComplianceStatus,
              referenceId,
              resmanAbb,
              row
            );
          });
        }
        
        if (gridRowData && gridRowData.id) {
          const selectedRow = rowData.find(x => x.id == gridRowData.id);
          if (selectedRow) {
            const newStatus = getExpiringStatus(selectedRow.status, selectedRow.other.overallPolicyExpiration);
            setOverallStatus(newStatus);
            setGridRowData(selectedRow);
          } else if (gridRowData.id) {
            setOpen(false);
          }
        }
        setRows(rowData);
      }

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    external.get('https://maps.googleapis.com/maps/api/geocode/json', {
			params: {
				address: gridRowData?.location,
				key: 'AIzaSyD0d8xORPXJ5X3qh_Er9YYlBC-6x1vs7P8',
				components: 'country:US',
			},
		}).then((res) => {
      if(res.data.results && res.data.results.length)
        setPosition(res.data.results[0].geometry.location);
    })
  }, [gridRowData?.location]);

  const inviteVendor = async (id: any) => {
    await api.vendors.inviteFromHub(id);
    setShowSuccessInvite(true);
  }

  return (
    <Box>
      <VendorDataGrid
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel: visibilityModel,
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Enter in some search criteria to populate results!
            </Stack>
          ),
        }}
        sx={{ mb: 4 }}
        autoHeight
        rowHeight={80}
        disableColumnFilter={true}
        disableColumnMenu={true}
        loading={isLoading}
        rowCount={total}
        rows={rows}
        columns={columns}
        paginationMode="server"
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        hideFooter
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={newModel => setSortModel(newModel)}
        disableRowSelectionOnClick
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        onRowClick={params => findVendorsPage ? handleFVOpen(params) : handleOpen(params)}
        onCellClick={onCellClicked}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography pr={2} pt={1}>
            Vendors Per Page
          </Typography>
          <TextField
            select
            label=""
            defaultValue="25"
            size="small"
            onChange={e => {
              setPaginationModel({ page: 0, pageSize: parseInt(e.target.value, 10) });
            }}
            sx={{ bgcolor: '#E8EBEE' }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </TextField>
        </Box>
        <Pagination
          page={paginationModel.page + 1}
          count={Math.ceil(total / paginationModel.pageSize)}
          siblingCount={0}
          shape="rounded"
          onChange={(e, page) => setPaginationModel({ page: page - 1, pageSize: paginationModel.pageSize })}
          renderItem={item => <PaginationItem components={{ previous: PrevTextIcon, next: NextTextIcon }} {...item} />}
        />
      </Box>
      <VendorModel isOpen={open} onClose={handleClose}>
        {gridRowData && (
          <Grid container sx={{}}>
            <Grid item xs={12} px={5.5} py={3}>
              <Box sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }} onClick={handleClose}>
                <ArrowBackIos></ArrowBackIos>
                <Typography variant="body1" fontWeight={700} color={'#102A43'}>
                  Back to Vendor List
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} px={5.5} pb={2}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h4">{gridRowData.vendor ?? gridRowData.name}</Typography>
                  {!findVendorsPage && <Typography variant="body1" color={'#035388'} pt={1.5}>
                    ID: {gridRowData.referenceId}
                  </Typography>}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                  }}
                >
                  {findVendorsPage && <Button
                    variant="contained"
                    size="medium"
                    sx={{ bgcolor: theme.palette.primary.dark, fontSize: 16, p: '12px 32px', textTransform: 'none' }}
                    onClick={() => inviteVendor(gridRowData.id)}
                  >
                    {gridRowData.status === 'NEW' ? 'Invite Vendor' : 'Resend Invite'}
                  </Button>}
                  {!findVendorsPage && <StatusChip status={overallStatus} />}
                  {!findVendorsPage && gridRowData.complianceDate && (
                    <Typography variant="body1" color={'#035388'} pt={1.5}>
                      Compliant on:
                      {' ' + DateTime.fromISO(gridRowData.other.complianceDate).toFormat('LLL. d, yyyy')}
                    </Typography>
                  )}
                  {!findVendorsPage && gridRowData.other.overallComplianceStatusChangeBy && (
                    <Typography variant="body1" color={'#035388'} pt={1.5}>
                      Compliant By:
                      {' ' + gridRowData.other.overallComplianceStatusChangeBy}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            {!findVendorsPage && <Grid item xs={12} sx={{}}>
              <VendorTabs
                data={gridRowData.other}
                activeTab={activityTab}
                setActivityTab={setActivityTab}
                handleUploadOpen={handleUploadOpen}
                vendorGeneralLiability={generalLiability}
                setVendorGeneralLiability={setGeneralLiability}
                vendorAutoLiability={autoLiability}
                setVendorAutoLiability={setAutoLiability}
                vendorExcessLiability={excessLiability}
                setVendorExcessLiability={setExcessLiability}
                vendorWorkerComp={workerComp}
                setVendorWorkerComp={setWorkerComp}
                handleActivityOpen={handleActivityOpen}
                refreshData={getGridData}
              />
            </Grid>}
            {findVendorsPage && <Grid item xs={12} sx={{overflow: 'hidden'}}>
              <Box px={5.5} sx={{overflowY: 'scroll', maxHeight: '600px'}}>
                <Box sx={{marginTop: '46px', paddingBottom: '40px', borderBottom: '1px solid rgba(39, 41, 55, 0.14)', gap: '32px', display: 'flex', flexDirection: 'column'}}>
                  <Typography sx={{fontSize: '20px', lineHeight: '28px', fontWeight: 500}}>About</Typography>
                  <Box sx={{gap: '20px', display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', gap: '40px'}}>
                      <Box sx={{display: 'flex', gap: '16px'}}><CallIcon sx={{width: '24px', height: '24px'}} /><Typography>{gridRowData.phone}</Typography></Box>
                      {gridRowData.email && <Box sx={{display: 'flex', gap: '16px'}}><EmailIcon sx={{width: '24px', height: '24px'}} /><Typography>{gridRowData.email}</Typography></Box>}
                    </Box>
                    <Box sx={{display: 'flex', gap: '16px'}}>
                      <LocationIcon sx={{width: '24px', height: '24px'}}/><Typography>{gridRowData.location}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{marginTop: '40px', paddingBottom: '40px', borderBottom: '1px solid rgba(39, 41, 55, 0.14)', gap: '32px', display: 'flex', flexDirection: 'column'}}>
                  <Typography sx={{fontSize: '20px', lineHeight: '28px', fontWeight: 500}}>Services</Typography>
                  <Box sx={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
                    {gridRowData.services.map((s: any) => <>
                      <Typography width={'50%'}>{s}</Typography>
                    </>)}
                  </Box>
                </Box>
                <Box sx={{marginTop: '40px', paddingBottom: '40px', borderBottom: '1px solid rgba(39, 41, 55, 0.14)', gap: '32px', display: 'flex', flexDirection: 'column'}}>
                  <Typography sx={{fontSize: '20px', lineHeight: '28px', fontWeight: 500}}>Head Quarters</Typography>
                  <Box sx={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                    <Typography>{gridRowData.location}</Typography>
                    {/* <GoogleMap /> */}
                    <Box sx={{height: '300px'}}>
                      <APIProvider apiKey={'AIzaSyD0d8xORPXJ5X3qh_Er9YYlBC-6x1vs7P8'}>
                        <Map center={position} defaultZoom={15}>
                          <Marker position={position} />
                        </Map>
                      </APIProvider>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>}
          </Grid>
        )}
      </VendorModel>
      <VendorModel isOpen={openUpload} onClose={handleUploadClose}>
        <Grid container px={4} pt={4}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }} onClick={handleUploadClose}>
              <ArrowBackIos></ArrowBackIos>
              <Typography variant="body1" fontWeight={700} color={'#102A43'}>
                Vendor Profile
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} p={2}>
            {gridRowData && (
              <DocUpload
                vendorId={gridRowData.id}
                isPolicy={isPolicyUpload}
                isOther={isOtherUpload}
                vendorGeneralLiability={generalLiability}
                setVendorGeneralLiability={setGeneralLiability}
                vendorAutoLiability={autoLiability}
                setVendorAutoLiability={setAutoLiability}
                vendorExcessLiability={excessLiability}
                setVendorExcessLiability={setExcessLiability}
                vendorWorkerComp={workerComp}
                setVendorWorkerComp={setWorkerComp}
                onSubmit={handleUploadClose}
              ></DocUpload>
            )}
          </Grid>
        </Grid>
      </VendorModel>
      <VendorModel isOpen={openActivity} onClose={handleActivityClose}>
        <Grid container px={4} pt={4}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }} onClick={handleActivityClose}>
              <ArrowBackIos></ArrowBackIos>
              <Typography variant="body1" fontWeight={700} color={'#102A43'}>
                Back to Vendor Activity
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography py={1} variant="h4">
                  Enter New Activity
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <VendoSelect
                  fullWidth
                  name="activityType"
                  value={activityType}
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (selected) {
                      return selected;
                    }

                    return <span style={{ color: '#9E9E9E' }}>Select Type of Activity</span>;
                  }}
                  onChange={(event: SelectChangeEvent<any>) => setActivityType(event.target.value)}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <MenuItem value={'Update'}>
                    <ListItemText primary={'Update'}></ListItemText>
                  </MenuItem>
                  <MenuItem value={'Notice'}>
                    <ListItemText primary={'Notice'}></ListItemText>
                  </MenuItem>
                  <MenuItem value={'Warning'}>
                    <ListItemText primary={'Warning'}></ListItemText>
                  </MenuItem>
                  <MenuItem value={'In review'}>
                    <ListItemText primary={'In review'}></ListItemText>
                  </MenuItem>
                </VendoSelect>
              </Grid>
              <Grid item xs={6}>
                <VendoSelect
                  fullWidth
                  name="activityTo"
                  value={activityTo}
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (selected) {
                      return selected;
                    }

                    return <span style={{ color: '#9E9E9E' }}>Select Policy it Applies to</span>;
                  }}
                  onChange={(event: SelectChangeEvent<any>) => setActivityTo(event.target.value)}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <MenuItem value={'General Liability'}>
                    <ListItemText primary={'General Liability'}></ListItemText>
                  </MenuItem>
                  <MenuItem value={'Auto Liability'}>
                    <ListItemText primary={'Auto Liability'}></ListItemText>
                  </MenuItem>
                  <MenuItem value={'Excess Liability'}>
                    <ListItemText primary={'Excess Liability'}></ListItemText>
                  </MenuItem>
                  <MenuItem value={'Workers Comp'}>
                    <ListItemText primary={'Workers Comp'}></ListItemText>
                  </MenuItem>
                </VendoSelect>
              </Grid>
              <Grid item xs={12}>
                <VendorInput
                  placeholder="Enter your comment here"
                  fullWidth
                  value={activityMessage}
                  multiline
                  rows={4}
                  onChange={e => setActivityMessage(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent={'end'}>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ bgcolor: theme.palette.primary.dark, fontSize: 16, p: '12px 32px', textTransform: 'none' }}
                    onClick={() => onActivitySubmit()}
                    disabled={
                      activityTo.length == 0 ||
                      activityType.length == 0 ||
                      activityMessage == null ||
                      activityMessage.length == 0
                    }
                  >
                    Submit Entry
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </VendorModel>
      <VendorModel isOpen={showSuccessInvite} onClose={() => setShowSuccessInvite(false)}>
        <Box sx={{paddingY: '40px', display: 'flex', justifyContent: 'center'}}>
          <Typography sx={{fontSize: '24px', color: '#000'}}>Invite has been sent to {gridRowData?.name}</Typography>
        </Box>
      </VendorModel>
    </Box>
  );
};

export default VendorGrid;
