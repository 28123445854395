import { ReactNode, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SessionContext } from 'src/context/SessionContext';
import ProtectedRoute from 'src/middleware/protectedRoute';
import FindVendorPage from 'src/pages/findVendor';
import YourVendor from 'src/pages/yourVendor';
import Login from 'src/pages/login';
import Home from 'src/pages/home';

interface IProps {
  redirectPath: string;
  isAllowed: boolean;
  children: ReactNode;
}

export default function Router() {
  const { session, loading, sessionIsEmpty } = useContext(SessionContext);

  return (
    <Routes>
      <Route path="/login" element={<Login session={session} />} />
      <Route element={<ProtectedRoute loading={loading} session={session} />}>
        <Route path="/" element={<Home session={session} sessionIsEmpty={sessionIsEmpty} />} />
        <Route path="/your-vendor" element={<YourVendor />} />
        <Route path="/find-vendor" element={<FindVendorPage />} />
      </Route>
      <Route path="/logged-out" element={<p>You have been logged out</p>} />
    </Routes>
  );
}
