import { InputLabel, TextField, styled } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

const VendorInput = styled(TextField)(({}) => ({
  [`&.MuiTextField-root`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #2729371F',
    borderRadius: '8px',
  },
  '& .MuiInputBase-input': {
    padding: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

interface IProps {
  name: string;
  control: any;
  label?: string;
  placeholder?: string;
  rules?: any;
  startAdornment?: React.ReactNode;
  isSubmitFocus?: boolean;
  style?: Object;
  labelSx?: any;
  disabled?: boolean;
  onSearch?: any
}

const InputText = ({ name, label, placeholder, rules, control, startAdornment, style, labelSx, disabled, onSearch }: IProps) => {
  const [showError, setShowError] = useState(false);

  return (
    <>
      <InputLabel htmlFor={name} sx={{ pb: '8px', ...labelSx }}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={''}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <VendorInput
            disabled={disabled}
            fullWidth
            style={style}
            placeholder={placeholder}
            error={showError && value?.length !== 0 && !!error}
            helperText={showError && value?.length !== 0 && error ? error.message : null}
            InputProps={{
              startAdornment: startAdornment,
            }}
            value={value}
            onChange={onChange}
            onKeyDown={(e) => {if(e.keyCode === 13) {onSearch && onSearch()}}}
          />
        )}
      />
    </>
  );
};

export default InputText;
