import { ITaxClassfication } from './../customTypes/taxCodes/index';
import { apiClient } from './client';

const getTaxClassifications = (): Promise<{ data: ITaxClassfication[] }> => {
    return apiClient.get('/tax-codes/classifications');
}

export default {
    getTaxClassifications
}
