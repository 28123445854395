import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, ChartOptions } from 'chart.js';
import { Box } from '@mui/material';
import { useIsMobile } from 'src/hooks/useIsMobile';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

type Props = {
  loading?: boolean;
  data: { labels: string; value: number }[];
};

export default function LineChart({ data }: Props) {
  const isMobile = useIsMobile();
  const dataInfo = {
    labels: data.map(x => x.labels),
    datasets: [
      {
        data: data.map(x => x.value),
        fill: true,
        borderColor: '#0B69A3',
        tension: 0.1,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          color: '#30343624',
          lineWidth: 1,
        },
        border: {
          display: false,
          dash: [4, 4],
          dashOffset: 10,
        },
        max: Math.max(...data.map(o => o.value)) + 15,
      },
    },
  };

  return (
    <>
      {isMobile && <Line data={dataInfo} options={options} width={'100%'} height={'100%'} />}
      {!isMobile && <Line data={dataInfo} options={options} style={{}} height={'100%'} />}
    </>
  );
}
