import { apiClient } from './client';

const createBgCheck = async (vendorId: string, status: string) => {
  const payload = { vendorId, status };
  const res = await apiClient.post('/background-checks', payload);
  return res.data;
};

const updateBgCheckStatus = async (bgCheckId: string, status: string, changeBy: string) => {
  const res = await apiClient.post(`background-checks/${bgCheckId}/status`, { status, changeBy });
  return res.data;
};

interface IBgCheckInfo {
  subjectInfo: {
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    ssn: string;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

const updateBgCheckInfo = async (bgCheckId: string, payload: IBgCheckInfo) => {
  const res = await apiClient.post(`/background-checks/${bgCheckId}`, payload);
  return res.data;
};

export default { createBgCheck, updateBgCheckStatus, updateBgCheckInfo };
