import { InputLabel, MenuItem, Select, styled } from '@mui/material';
import { Controller } from 'react-hook-form';

const VendorSelect = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #2729371F',
    borderRadius: '8px',
    padding: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

interface IOptionProps {
  id: string;
  name: string;
}

interface IProps {
  name: string;
  options: IOptionProps[];
  placeholder: string;
  control: any;
  isMultiple?: boolean;
  defaultValue?: IOptionProps;
  label?: string;
  rules?: any;
}

const InputSelect = ({ name, defaultValue, options, label, placeholder, rules, control, isMultiple }: IProps, ref: any) => {
  return (
    <>
      <InputLabel htmlFor={name} sx={{ pb: '8px' }}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={isMultiple ? [] : ''}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <VendorSelect
              fullWidth
              value={value}
              displayEmpty
              onChange={onChange}
              sx={{ backgroundColor: '#FFFFFF' }}
              inputProps={{
                size: 'small',
              }}
              placeholder={placeholder}
              multiple={isMultiple}
              renderValue={(selected: any) => {
                if (isMultiple) {
                  if (selected.length === 0) {
                    return <span style={{ color: '#9E9E9E' }}>{placeholder}</span>;
                  }

                  return selected.join(', ');
                }

                if (!selected) {
                  return <span style={{ color: '#9E9E9E' }}>{placeholder}</span>;
                }
                return options.find(x => x.id === selected)?.name;
              }}
            >
              {/* <MenuItem value="">
                <span style={{ color: '#9E9E9E' }}>{placeholder}</span>
              </MenuItem> */}
              {options.map((opt, idx) => (
                <MenuItem key={idx} value={opt.id}>
                  {opt.name}
                </MenuItem>
              ))}
            </VendorSelect>
          );
        }}
      />
    </>
  );
};

export default InputSelect;
