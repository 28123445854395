import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import VendorTabPanel from './VendorTabPanel';
import EditBlock from '../EditBlock';
import InfoBlock from '../InfoBlock';
import api from 'src/api';
import { SessionContext } from 'src/context/SessionContext';
import { ServiceAreaOption, serviceRiskLevels } from 'src/customTypes/services';
import { DateTime } from 'luxon';

type Props = {
  isHidden: boolean;
  vendorData: any;
  setVendorData: (data: any) => void;
  isLoading?: boolean;
  setIsLoading: (loading: boolean) => void;
  refreshData: () => void;
};

export default function GeneralTab({ isHidden, vendorData, setVendorData, isLoading, setIsLoading, refreshData }: Props) {
  const { session } = useContext(SessionContext);
  const isAdmin = session.role === 'IDCORE_ADMIN';

  const [isEditingGInfo, setIsEditingGInfo] = useState(false);
  const [isEditingServices, setIsEditingServices] = useState(false);
  const [vendorServices, setVendorServices] = useState<serviceRiskLevels[]>([]);
  const [isLoadingService, setIsLoadingService] = useState<boolean>(false);
  const [providedServices, setProvidedServices] = useState<
    { id: string; label: string; groupLabel: string; chip: string }[]
  >([]);

  const [isEditingServiceAreas, setIsEditingServiceAreas] = useState(false);
  const [serviceAreas, setServiceAreas] = useState<{ id: string; label: string }[]>([]);
  const [selectedServiceAreas, setSelectedServiceAreas] = useState<ServiceAreaOption[]>([]);
  const [isLoadingServiceAreas, setIsLoadingServiceAreas] = useState<boolean>(false);

  const handleBizSubmit = async (data: any) => {
    setIsLoading(true);
    let submitData = {
      ...data,
      services: vendorData.bizInfo?.services.map((item: any) => item.id),
      riskLevels: vendorData.bizInfo?.riskLevels,
      serviceareas: vendorData.bizInfo?.serviceareas,
      locationOfHeadQuarters: vendorData.bizInfo?.locationOfHeadQuarters,
      isOutsideLocations: vendorData.bizInfo?.isOutsideLocations,
      changeBy: session.details.name,
    };
    if (data.riskLevels) {
      submitData = {
        ...submitData,
        accountsPayableEmail: vendorData.bizInfo?.accountsPayableEmail,
        email: vendorData.bizInfo?.email,
        numEmployees: vendorData.bizInfo?.numEmployees,
        phone: vendorData.bizInfo?.phone,
        riskLevels: data.riskLevels.map((item: any) => item.id),
      };
    }

    if (data.serviceareas) {
      submitData = {
        ...submitData,
        accountsPayableEmail: vendorData.bizInfo?.accountsPayableEmail,
        email: vendorData.bizInfo?.email,
        numEmployees: vendorData.bizInfo?.numEmployees,
        phone: vendorData.bizInfo?.phone,
        serviceareas: data.serviceareas.map((item: any) => item.id),
      };
    }

    api.vendors
      .updateVendorBizInfo(vendorData._id, submitData)
      .then((res: any) => {
        if (res.status === 200) {
          let info: any = {
            accountsPayableEmail: res.data.bizInfo?.accountsPayableEmail,
            email: res.data.bizInfo?.email,
            numEmployees: res.data.bizInfo?.numEmployees,
            phone: res.data.bizInfo?.phone,
          };
          if (data.riskLevels) info = { ...info, riskLevels: res.data.bizInfo.riskLevels };
          if (data.serviceareas) info = { ...info, serviceareas: res.data.bizInfo.serviceareas };
          vendorData.bizInfo = { ...vendorData.bizInfo, ...info };
          setVendorData(vendorData);
          refreshData();
        }
      })
      .finally(() => {
        if (data.riskLevels) setIsEditingServices(false);
        if (!data.riskLevels) setIsEditingGInfo(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const serviceIds = vendorData.bizInfo?.riskLevels?.join(',') || '';
    if (!isHidden && serviceIds) {
      setIsLoadingService(true);
      api.services
        .getRiskLevels(serviceIds)
        .then(value => {
          setVendorServices(value);
        })
        .catch(reason => console.error('Error fetching vendor services', reason))
        .finally(() => {
          setIsLoadingService(false);
        });
    }
  }, [vendorData.bizInfo?.riskLevels, isHidden]);

  useEffect(() => {
    if (!isHidden) {
      setIsLoadingServiceAreas(true);
      api.services
        .getServiceAreas()
        .then((ar: any) => {
          const data = ar.data.map((item: any) => ({
            label: item.name,
            id: item.id,
          }));
          setServiceAreas(data);
          if (vendorData.bizInfo?.serviceareas) {
            const selected: any[] = ar.data.filter((item: any) =>
              vendorData.bizInfo?.serviceareas.find((sub: any) => sub === item.id)
            );
            setSelectedServiceAreas(selected);
          }
        })
        .catch(reason => console.error('Error fetching vendor service areas', reason))
        .finally(() => {
          setIsLoadingServiceAreas(false);
        });
    }
  }, [vendorData.bizInfo?.serviceareas, isHidden]);

  const onServiceEdit = () => {
    if (providedServices.length === 0) {
      setIsLoadingService(true);
      api.services
        .getRiskLevels('') //All services provided
        .then(value => {
          const data = value.map(item => ({
            label: item.name,
            id: item.id,
            groupLabel: item.categoryName,
            chip: item.riskLevel,
          }));
          setProvidedServices(data);
        })
        .finally(() => {
          setIsLoadingService(false);
          setIsEditingServices(true);
        });
    } else {
      setIsEditingServices(true);
    }
  };

  return (
    <VendorTabPanel isHidden={isHidden}>
      <Box p={2}>
        {isEditingGInfo && (
          <EditBlock
            title={'General Info'}
            onSubmit={data => {
              handleBizSubmit(data);
            }}
            onCancel={() => {
              setIsEditingGInfo(false);
            }}
            fields={[
              {
                label: 'Company Email:',
                name: 'email',
                value: vendorData.bizInfo?.email,
                type: 'email',
                rules: { required: true },
                errorMessage: {
                  pattern: 'Enter a valid email address',
                },
              },
              {
                label: 'AP Email:',
                name: 'accountsPayableEmail',
                value: vendorData.bizInfo?.accountsPayableEmail,
                type: 'email',
                errorMessage: {
                  pattern: 'Enter a valid email address',
                },
              },
              {
                label: 'Phone:',
                name: 'phone',
                value: vendorData.bizInfo?.phone,
                type: 'phone',
                errorMessage: {
                  pattern: 'Enter a valid phone number',
                },
              },
              {
                label: 'Number of Employees:',
                name: 'numEmployees',
                value: vendorData.bizInfo?.numEmployees,
                type: 'text',
              },
              {
                label: 'Overall Risk Level:',
                name: 'riskLevel',
                value:
                  vendorServices && vendorServices.length > 0 && vendorServices.some((r: any) => r.riskLevel === 'HIGH')
                  ? 'HIGH RISK'
                  : vendorServices && vendorServices.length > 0 && vendorServices.some((r: any) => r.riskLevel === 'LOW')
                  ? 'LOW RISK'
                  : 'NO RISK',
                type: 'readonly',
              },
            ]}
          />
        )}
        {!isEditingGInfo && (
          <InfoBlock
            title={'General Info'}
            onEdit={
              isAdmin
                ? () => {
                    setIsEditingGInfo(true);
                  }
                : undefined
            }
            isLoading={isLoading}
            info={[
              {
                name: 'Company Email:',
                value: vendorData.bizInfo?.email ?? ' ',
              },
              {
                name: 'Accounts Payable Email:',
                value: vendorData.bizInfo?.accountsPayableEmail ?? ' ',
              },
              {
                name: 'Phone Number:',
                value: vendorData.bizInfo?.phone ?? ' ',
              },
              {
                name: 'Number of Employees:',
                value: vendorData.bizInfo?.numEmployees ?? ' ',
              },
              {
                name: 'Business Address:',
                value: vendorData.w9?.address ?? ' ',
              },
              {
                name: 'Service Risk Level:',
                chip: {
                  label:
                    vendorServices && vendorServices.length > 0 && vendorServices.some((r: any) => r.riskLevel === 'HIGH')
                      ? 'HIGH RISK'
                      : vendorServices && vendorServices.length > 0 && vendorServices.some((r: any) => r.riskLevel === 'LOW')
                      ? 'LOW RISK'
                      : 'NO RISK',
                },
              },
              {
                name: 'Payup Registered:',
                value: vendorData.isPayupRegistered ? 'Yes' : 'No',
              },
              {
                name: 'Enrollment Date:',
                value: vendorData.subscriptionStartDate ? DateTime.fromISO(vendorData.subscriptionStartDate).toFormat('MM/dd/yyyy') :
                vendorData.payment?.paymentDate ? DateTime.fromISO(vendorData.payment?.paymentDate).toFormat('MM/dd/yyyy') : '',
              }
            ]}
          />
        )}

        <Box py={2}>
          {isEditingServiceAreas && (
            <EditBlock
              title={'Service Areas'}
              onSubmit={data => {
                handleBizSubmit(data);
              }}
              onCancel={() => {
                setIsEditingServiceAreas(false);
              }}
              fields={[
                {
                  label: '',
                  name: 'serviceareas',
                  optionValue:
                    selectedServiceAreas?.map(value => ({
                      label: value.name,
                      id: value.id,
                    })) ?? [],
                  type: 'multi-select',
                  options: serviceAreas,
                  isLoadingOptions: isLoading,
                },
              ]}
            />
          )}
          {!isEditingServiceAreas && selectedServiceAreas && (
            <InfoBlock
              title={'Service Areas'}
              onEdit={
                isAdmin
                  ? () => {
                      setIsEditingServiceAreas(true);
                    }
                  : undefined
              }
              isLoading={isLoadingServiceAreas}
              info={[
                {
                  name: 'Service Areas:',
                  chips: selectedServiceAreas.map((s: any, key: number) => ({
                    label: s.name,
                  })),
                },
              ]}
            />
          )}
        </Box>
        <Box pb={2}>
          {isEditingServices && (
            <EditBlock
              title={'Services Provided'}
              onSubmit={data => {
                handleBizSubmit(data);
              }}
              onCancel={() => {
                setIsEditingServices(false);
              }}
              fields={[
                {
                  label: '',
                  name: 'riskLevels',
                  optionValue:
                    vendorServices?.map(value => ({
                      label: value.name,
                      id: value.id,
                      groupLabel: value.categoryName,
                      chip: value.riskLevel,
                    })) ?? [],
                  type: 'multi-select-ex',
                  options: providedServices,
                  isLoadingOptions: isLoading,
                },
              ]}
            />
          )}
          {!isEditingServices && vendorServices && (
            <InfoBlock
              title={'Services Provided'}
              onEdit={
                isAdmin
                  ? () => {
                      onServiceEdit();
                    }
                  : undefined
              }
              isLoading={isLoadingService}
              info={[
                {
                  name: 'Service Risk Level:',
                  chips: vendorServices.map((s: any, key: number) => ({
                    label: s.name,
                  })),
                },
              ]}
            />
          )}
        </Box>
      </Box>
    </VendorTabPanel>
  );
}
